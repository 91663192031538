<template>
  <f7-page name="detailResponse">
    <navbar
      slot="fixed"
      nav-title="Detalle Formulario"
      back-btn-enable
      :back-callback="() => $f7.views.main.router.navigate('/home/',{reloadAll: true, history: false, ignoreCache: true})"
      :img-btn-enable="canViewImages"
      :img-btn-callback="() => $refs.popup.open()"
      :doc-btn-enable="canViewDocs"
      :doc-btn-callback="() => $refs.popupDocs.open()"
      :save-btn-enable="showAutoSave && currentResponseEditable && canEdit"
      :save-btn-callback="autoSave"
      :save-top-btn-enable="showTopSave && currentResponseEditable && canEdit"
      :save-top-btn-callback="save"
    /><!--:back-callback="() => $f7.views.main.router.navigate('/home/',{reloadAll: true, history: false, ignoreCache: true})"-->

    <f7-row
      v-if="!((currentResponseEditable && canEdit) == false)"
      class="padding-left padding-top justify-content-start align-items-center"
    >
      <div class="padding-right">
        Fecha de Registro:
      </div>
      <f7-input
        input-style="padding: 0 !important; text-align: center"
        class="padding-half"
        style="max-width: 150px"
        outline
        type="datepicker"
        :value="[registerDateInput]"
        :calendar-params="{
          openIn: 'customModal',
          closeOnSelect: true,
          dateFormat: 'dd-mm-yyyy HH::mm',
          timePicker: true,
        }"
        @calendar:change="onChangeDate"
      />
    </f7-row>


    <f7-photo-browser
      ref="popup"
      :photos="photos_browser"
      type="popup"
      :thumbs="thumbs"
      theme="dark"
      popup-close-link-text="Cerrar"
      :routable-modals="false"
    />

    <f7-popup
      ref="popupDocs"
      :tablet-fullscreen="true"
    >
      <f7-page>
        <f7-navbar title="Documentos">
          <f7-nav-right>
            <f7-link
              popup-close
            >
              Cerrar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <f7-list
            media-list
            dividers-ios
            strong-ios
            outline-ios
          >
            <f7-list-item
              v-for="(option, index) in documents"
              :key="index"
              link="#"
              :title="'Documento ' + (index+1)"
              @click="setContentPDF(option)"
            >
              <p>{{ option.name }}</p>
            </f7-list-item>
          </f7-list>

          <div>
            <p-d-f-viewer
              v-if="contentPDF"
              :source="contentPDF"
              style="height: 100vh; width: 100%; min-width: 100%"
              @download="handleDownload"
            />
          </div>
        </f7-block>
      </f7-page>
    </f7-popup>

    <f7-block
      v-if="(currentResponseEditable && canEdit) == false"
      strong
      class="text-color-orange"
    >
      Este informe está en modo de solo lectura. Los cambios que se realicen no se podrán guardar.
    </f7-block>



    <hi-forms-visualizer
      id="form_visualizer"
      ref="form_visualizer"
      :template="JSON.parse(template.data)"
      :template-data="JSON.parse(currentResponse.entities)"
      :template-extra-data="JSON.parse(currentResponse.extra_data)"
      :show-button="false"
      :service-url="urlService"
      :service-token="token.data.access"
      :template-external-data="Object.assign(getJsonInfoEntity(currentResponse.info_entity)?getJsonInfoEntity(currentResponse.info_entity):{}, token )"
      :groups-close="getCloseAccordionForm"
      @form-data="getData"
    />
    <f7-block v-if="currentResponseEditable && canEdit">
      <f7-button
        fill
        class="margin-vertical"
        @click="save"
      >
        Guardar
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import store from '../js/store/index';
import Navbar from '../components/Navbar.vue';
// import HiFormsVisualizer from 'vue-component-library/components/dynamicforms/HiFormsVisualizer.vue';
import HiFormsVisualizer from '../components/dynamicforms/HiFormsVisualizer.vue';
import * as Config from '../js/constants';
import { mapState, mapGetters } from 'vuex';
import TemplatesVersionsRepository from '../js/repositories/TemplatesVersionsRepository';
import StringUtils from '../js/utilities/StringUtils';
import EventBus from '../js/EventBus';
import { checkRequiredInputs } from '../js/lib/shared';
import Api from '../js/services/Api';
import ResponseRepository from '../js/repositories/ResponseRepository';
// import html2pdf from 'html2pdf.js';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer';

export default {
  name: 'DetailResponse',
  components: { HiFormsVisualizer, Navbar, PDFViewer },
  data() {
    return {
      template: null,
      response: null,
      resultado: null,
      resultadoExtra: null,
      idOriginal: null,
      canEdit: false,
      requiredInputs: [],
      areRequiredInputsDefined: false,
      modified: false,
      registerDate: null,
      registerDateInput: new Date(),
      photos: [],
      photos_browser:[],
      info_entity_temp: null,
      pdf: null,
      thumbs: [],
      popupPhotosOpened: false,
      canViewImages: false,
      showAutoSave: false,
      minutsAutoSave: -1,
      originalResponse: null,
      documents: [],
      canViewDocs: false,
      contentPDF: '',
      showTopSave: false
    };
  },
  computed: {
    urlService() {
      return Config.API_BASE_URL;
    },
    ...mapState([
      'currentTemplate',
      'currentResponse',
      'confDatatypes',
      'token',
      'currentResponseEditable',
    ]),
    ...mapGetters(['getQuestionShare', 'getAutoSave', 'getCloseAccordionForm', 'getTopSave'])
  },
  async created() {
    this.response = this.currentResponse;
    this.template = this.currentTemplate;
    this.defineRequiredInputs();

    // Gestion del auto-guardado
    const tmpAuto = this.getAutoSave;
    if(tmpAuto !== false){
      if(tmpAuto === 'MANUAL') {
        this.showAutoSave = true;
      } else {
        this.minutsAutoSave = parseInt(tmpAuto, 10);
        this.originalResponse = JSON.parse(JSON.stringify(this.response));
      }
    }
    if(!this.showAutoSave) {
      const topsave = this.getTopSave;
      if(topsave) {
        this.showTopSave = true;
      }
    }

    if (this.template.current_version !== this.response.version) {
      //Debemos obtener el data correcto de la version de la respuesta
      const ver = await TemplatesVersionsRepository.getByTemplateAndVersion(this.template.id, this.response.version);
      if(ver.length === 1)
      {
        this.template.data = ver[0].data;
      }
    }

    this.idOriginal = this.response.id;

    this.canEdit = this.token.data.edit;

    this.info_entity_temp = JSON.parse(this.currentResponse.info_entity);
    // this.info_entity_temp = JSON.parse(this.response.info_entity);
    if(this.info_entity_temp !== null && this.info_entity_temp.id !== null && this.template.entity_type_images) {
      this.photos = await this.getPhotos();

      if(this.photos && this.photos.length > 0) {
        this.photos.forEach(photo=>{
          let extension = '';
          if(photo.FileName) {
            extension = photo.FileName.split('.').pop();
            console.log('extension:' + extension)
          }

          let fileName = photo.Name;
            if(!fileName) {
              try{
                fileName = photo.FileName.split('\\')[photo.FileName.split('\\').length-1]
              }catch(err) {
                fileName = 'Sin nombre';
              }
            }

          let tmp = {};
          if(extension === 'pdf') {
            this.canViewDocs = true;
            tmp = {
              'name': fileName,
              'url':'data:application/pdf;base64,'+photo.Base64File+''
            }
            this.documents.push(tmp)
          } else {
            this.canViewImages = true;
            tmp = {
              'url':'data:image/png;base64,'+photo.Base64File,
              'caption': photo.Name
            }
            this.photos_browser.push(tmp);
            this.thumbs.push('data:image/png;base64,'+photo.Base64File);
          }
        })
      }
    }
  },
  async mounted() {
    let dateTmp = this.response.created_at;
    if(dateTmp.includes('+')) {
      dateTmp = dateTmp.split('+')[0]
    }
    this.registerDateInput = new Date(dateTmp);
  },
  beforeDestroy() {
    console.log('detailResponse - beforeDestroy');
    store.commit('setCurrentResponse', null);
    store.commit('setCurrentTemplate', null);
  },
  destroyed() {
    console.log('detailResponse - destroyed')
    store.commit('setCurrentResponse', null);
    store.commit('setCurrentTemplate', null);
  },
  methods: {
    async handleDownload({src, filename}) {
      if(!this.$f7.device.cordova) {
        const pdfTmp = new File([src], 'calidad.pdf', { type: 'application/pdf' });
          navigator.share({
            title: this.token.data.full_name,
            text: 'PDF Calidad',
            files: [pdfTmp]
          })
      } else {
        window.plugins.socialsharing.share(this.token.data.full_name, 'PDF Calidad', src, null);
      }
    },
    setContentPDF(obj) {
      this.contentPDF = obj.url;
    },
    async autoSave() {
      console.log('==> autosave');

      this.$f7.toast.create({
        text:'Guardando respuesta en el servidor',
      }).open();
      const entities = this.resultado ? JSON.stringify(this.resultado) : this.currentResponse.entities;
      // Se actualiza en local en primer lugar, el entities
      await ResponseRepository.updateByIdAutoSave(this.response.id, entities);

      // Se comprueban los campos requeridos
      this.areRequiredInputsDefined = checkRequiredInputs(this.resultado, this.requiredInputs);

      if (this.areRequiredInputsDefined === false) {
        this.$f7.dialog.alert('Revise los campos requeridos, quedan algunos sin cumplimentar');
        return;
      }

      let data = {
        entities: this.resultado ? JSON.stringify(this.resultado) : this.currentResponse.entities,
        // entities: this.resultado ? JSON.stringify(this.resultado) : this.response.entities,
        extra_data: JSON.stringify(this.resultadoExtra),
        created_at: StringUtils.dateToFullString(this.registerDate, 'T'),
        updated_at: StringUtils.dateToFullString(new Date(), 'T'),
        user_updated: this.token.data.id,
        nameUser: this.token.data.user,
        sent: 0,
        idServer: this.currentResponse.idServer
      };

      if((this.response.entities_id === 0 || this.response.entities_id === '0') && this.currentTemplate.fieldsVisualizer) {
        let newInfoEntity = {'name':''}
        const fieldsVisualizer = this.currentTemplate.fieldsVisualizer.split(',');
        let allValues = '';
        fieldsVisualizer.forEach(field => {
          if(field) {
            allValues = allValues + ' - ' + this.resultado[field];
          }
        })
        newInfoEntity['name'] = allValues;
        data['info_entity'] = JSON.stringify(newInfoEntity)
      }

      const id = this.response.id;
      // Se actualiza la respuesta total
      await ResponseRepository.edit(id, data);
      // Se vuelve a obtener la respuesta recien guardada
      const responseLocal = await ResponseRepository.getByUid(this.response.response_uid);

      const idLocal = id;
      // Se envia la respuesta en el servidor
      const response = await Api.saveTemplateResponse(responseLocal[0])
      // Se actualiza la respuesta obtenida en local (por ejemplo las imagenes ya vendran con url)
      if(response.idServer) {
        await ResponseRepository.updateById(idLocal, response);
      }

      console.log('proceso de autosave finalizado');

      this.$f7.toast.close();

      try {
          window.FirebasePlugin.logEvent('SaveResponse', { content_type: 'Events', item_id: 'SaveResponse' });
      } catch (err) {
        console.log(err);
      }

    },
    async getPhotos() {
      const data_service = {
          code_service: 'CAL-IMAGES',
          params: JSON.stringify([{'entityType':this.currentTemplate.entity_type_images}, {'entityId':this.info_entity_temp.id}]),
        };
      const photos = await Api.executeService(data_service);
      return photos;
    },
    onChangeDate(e) {
      if (e && e[0]) this.registerDate = e[0];
    },
     defineRequiredInputs() {
      const parsedCurrentTemplate = JSON.parse(this.currentTemplate.data);
      const { detail } = parsedCurrentTemplate;
      let requiredInputs = [];
      detail.forEach(group => {
        if (group.type === 'LIST_TEMPLATE') {
          group.children.forEach(groupLayout => {
            requiredInputs = [...requiredInputs, ...this.getRequiredInputsForGroupLayoutFrom(groupLayout.children, groupLayout.refGroup)]
          })
        } else {
          requiredInputs = [...requiredInputs, ...this.getRequiredInputsForGroupLayoutFrom(group.children)];
        }
      })
      this.requiredInputs = requiredInputs;
    },

    getRequiredInputsForGroupLayoutFrom(inputs, groupLayoutReference) {
      return inputs
        .filter((input) => input.options.required)
        .map(input => {
          return {
            reference: input.ref,
            type: input.dataType,
            groupRef: groupLayoutReference,
          }
        });
    },

    getData({ data, extraData }) {
      console.log(this.$f7.views.main.router.currentRoute.path)
      this.modified = true;
      this.resultado = data;
      this.resultadoExtra = extraData;
    },

    async save() {
      // console.log('==> save()');
      if (!this.modified) {
        this.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
        return;
      }

      this.areRequiredInputsDefined = checkRequiredInputs(this.resultado, this.requiredInputs);

      if (this.areRequiredInputsDefined === false) {
        this.$f7.dialog.alert('Revise los campos requeridos, quedan algunos sin cumplimentar');
        return;
      }

      let data = {
        entities: this.resultado ? JSON.stringify(this.resultado) : this.currentResponse.entities,
        // entities: this.resultado ? JSON.stringify(this.resultado) : this.response.entities,
        extra_data: JSON.stringify(this.resultadoExtra),
        created_at: StringUtils.dateToFullString(this.registerDate, 'T'),
        updated_at: StringUtils.dateToFullString(new Date(), 'T'),
        user_updated: this.token.data.id,
        nameUser: this.token.data.user,
        sent: 0,
        idServer: this.currentResponse.idServer
      };

      if((this.response.entities_id === 0 || this.response.entities_id === '0') && this.currentTemplate.fieldsVisualizer) {
        let newInfoEntity = {'name':''}
        const fieldsVisualizer = this.currentTemplate.fieldsVisualizer.split(',');
        let allValues = '';
        fieldsVisualizer.forEach(field => {
          if(field) {
            allValues = allValues + ' - ' + this.resultado[field];
          }
        })
        newInfoEntity['name'] = allValues;
        data['info_entity'] = JSON.stringify(newInfoEntity)
      }

      // console.log('data: ', data);

      const self = this;
      // Control si se quiere compartir la edicion si corresponde
      if(this.getQuestionShare && this.template.code_print) {
        this.$f7.dialog.create({
          title: '¿Quieres compartir el formulario?',
          buttons: [
            {
              text: 'Si',
            },
            {
              text: 'No',
            },
          ],
          onClick: async function (dialog, index) {
            if(index === 0) {
              self.updateServer(self.idOriginal, data)
            } else if(index === 1) {
              EventBus.$emit('responses.update', { id: self.idOriginal, response: data });
              self.checkRoute();
              self.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
            }
          },
        verticalButtons: true,
        })
        .open();

      } else {
        console.log('No se comparte. Funcionamiento normal')
        EventBus.$emit('responses.update', { id: this.idOriginal, response: data });
        console.log('pasa, se redirige a la home', this.$f7.views.main.router)
        this.checkRoute();
        this.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
        //this.$f7.views.main.router.navigate('/home/');//,{reloadAll: true, history: false}
      }
    },
    checkRoute() {
      if(this.$f7.views.main.router.currentRoute.path !== '/detailresponse/' || this.$f7.views.main.router.currentRoute.url !== '/detailresponse/') {
        this.$f7.views.main.router.currentRoute.path = '/detailresponse/';
        this.$f7.views.main.router.currentRoute.url = '/detailresponse/';
        }
    },
    getJsonInfoEntity(txt) {
      return JSON.parse(txt);
    },
    async updateServer(id, data) {
      this.$f7.dialog.preloader('Guardando la respuesta en el servidor...');

      try {
          window.FirebasePlugin.logEvent('UpdateResponse', { content_type: 'Events', item_id: 'UpdateResponse' });
      } catch (err) {
        console.log(err);
      }

      await ResponseRepository.edit(id, data);
      const responseLocal = await ResponseRepository.getByUid(this.response.response_uid);

      const idLocal = id;

      const response = await Api.saveTemplateResponse(responseLocal[0])

      await ResponseRepository.updateById(idLocal, response);

      let namePDF = 'calidad.pdf';
      try {
        if(response.info_entity && response.info_entity !== 'null') {
          namePDF = JSON.parse(response.info_entity).name+ '.pdf';
          namePDF = namePDF.replace(/[/\\?%*:|"<>]/g, '-');
        } else {
          namePDF = response.nameUser + '-' + response.created_at + '.pdf';
          namePDF = namePDF.replace(/[/\\?%*:|"<>]/g, '-');
        }
      } catch (error) {
        namePDF = 'calidad.pdf';
      }
      console.log('namePDF', namePDF);

      const self = this;
      this.$f7.dialog.close();

      self.$f7.dialog.preloader('Obteniendo la sincronización del formulario...');
      setTimeout(async() => {
        const respCodeExt = await Api.getExternalCode(response.idServer)

        if(respCodeExt.code_external && respCodeExt.code_external !== '') {
          if(respCodeExt.code_external.includes('/')) {
            const serie = respCodeExt.code_external.split('/')[0];
            const numero = respCodeExt.code_external.split('/')[1];

            const register = await Api.getRegisterId(serie,numero);


            self.$f7.dialog.close();

            if(register && register.length > 0) {
              const paramTemp = [{
                'ParameterName': 'idcontrolcalidad',
                'ParameterValue': register[0].id + ''
              }]
              self.$f7.dialog.preloader('Obteniendo el pdf...');
              const pdf = await Api.getPDF(paramTemp, self.currentTemplate.code_print);

              self.$f7.dialog.close();
              if(!pdf.PDF && pdf.Error) {
                self.$f7.dialog.alert('Error obteniendo el pdf: ' + pdf.Error);
                self.checkRoute();
                self.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
              } else {
                self.socialSharing(pdf.PDF, namePDF);
                // self.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
              }
            }
            else {
              self.$f7.dialog.close();
              self.$f7.dialog.alert('No se ha podido obtener el registro de calidad para obtener su pdf. Pruebe a compartirlo desde el listado');
              self.checkRoute();
              self.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
            }
          }
        } else {
          self.$f7.dialog.close();
          self.$f7.dialog.alert('Aún no se ha sincronizado el formulario. Pruebe a compartirlo desde el listado');
          self.checkRoute();
          self.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
        }

      }, 5000);
    },
    socialSharing(pdf, namePDF) {
      let name = 'calidad.pdf';
      if(namePDF) {
        name = namePDF;
      }
      const pdfTmp = new File([pdf], name, { type: 'application/pdf' });
      if(!this.$f7.device.cordova) {
        const self = this;
        this.$f7.dialog.confirm('¿Seguro que desea compartir?', async () => {
          navigator.share({
            title: this.token.data.full_name,
            text: name,
            files: [pdfTmp]
          })
          self.checkRoute();
          self.$f7.views.main.router.navigate('/home/', {reloadAll: true, history: false});
        });
      } else {
        window.plugins.socialsharing.share(this.token.data.full_name, name, 'data:application/pdf;base64,'+ pdf, null);
        this.checkRoute();
        this.$f7.views.main.router.navigate('/home/',{reloadAll: true, history: false});
      }
    },
  },
};
</script>

