<template>
  <div>
    <div
      v-if="title"
      class="item-label smart-title text-align-left"
    >
      {{ title }}
    </div>
    <f7-list-item
      :class="getClass"
      :title="selectedTitle"
      smart-select
      :smart-select-params="{pageTitle: title, ...smartSelectParams}"
    >
      <select
        :name="title"
        @change="selectOption"
      >
        <option
          value=""
          selected
        >
          Seleccionar opcion
        </option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.id"
          :selected="option.id === parseInt(defaultOptionCode, 10)"
        >
          {{ print in option ? option[print] : option.name }}
        </option>
      </select>
    </f7-list-item>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    print: { type: String, default: 'name' },
    defaultOptionCode: { type: [String, Number], default: null },
    options: { type: Array, default: () => [] },
    disabled: {type: Boolean, default: false},
    componentId: { type: String, default: '' },
  },
  data() {
    return {
      selected: null,
      selectValueString: 'Seleccionar opcion',
      smartSelectParams: {
        openIn: 'page',
        setValueText: false,
        closeOnSelect: true,
        scrollToSelectedItem: true,
        searchbar: true,
        searchbarPlaceholder: 'Buscar',
        routableModals: false
      },
    };
  },

  computed: {
    selectedTitle() {
      return this.getItemName(this.selected, this.options);
    },
    getClass() {
      let customClass = this.componentId
      customClass += this.disabled ? 'smart-border disabled' : '';
      return customClass
    }
  },

  watch: {
    defaultOptionCode() {
      this.selected = this.defaultOptionCode;
      this.$emit('change', this.selected);
    },
  },

  mounted() {
    this.selected = this.defaultOptionCode;
  },

  methods: {
    getItemName(selectedItemId, itemsGroup) {
      if (!selectedItemId) return this.selectValueString;

      const itemData = itemsGroup.find(
        (item) => parseInt(item.id, 10) === parseInt(selectedItemId, 10),
      );

      if (!itemData) return this.selectValueString;
      return this.print in itemData ? itemData[this.print] : itemData.name;
    },

    selectOption(e) {
      if (!(this.componentId === 'template' && !e.target.value)) {
        this.selected = e.target.value;
        this.$emit('change', this.selected);
      }
    },
  },

};
</script>
<style lang="scss">
.list-group-title > span {
  width: 100%;
}
select:after {
  background-color: transparent !important;
}
</style>
