import Dexie from 'dexie';
import relationships from 'dexie-relationships';
import { DATABASE_NAME } from '../constants';

const db = new Dexie(DATABASE_NAME, { addons: [relationships] });

db.version(1).stores({
    templates: 'id, rol, entityId' ,
    responses: 'id++, template -> templates.id, sent, idServer',
    datatypes: 'id, type, options',
    services: 'id',
    responseDeletes: 'id++, sent, idServer',
    allservices: 'id, category',
    versionsTemplates: 'id, version' ,
    // Lack of a better name
    entityCache: 'id, __code__, Codigo',
    token: 'id',
});

db.version(2).stores({
    templates: 'id, rol, entityId' ,
    responses: 'id++, template -> templates.id, sent, idServer, response_uid',
    datatypes: 'id, type, options',
    services: 'id',
    responseDeletes: 'id++, sent, idServer',
    allservices: 'id, category',
    versionsTemplates: 'id, version' ,
    // Lack of a better name
    entityCache: 'id, __code__, Codigo',
    token: 'id',
});

db.open();

export default db;