import Vue from 'vue';
import Vuex from 'vuex';
import TaskLoader from './modules/TaskLoader';
import Responses from './modules/Responses';
import EntityCacheSearch from './modules/EntityCacheSearch';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import TokenRepository from '../repositories/TokenRepository';

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    responsesStatus: false,
    currentWorkingDate: new Date(),
    dayCountToEdit: 0,
    loggedUser: null,
    signUpUser: null,
    wantToRemember: false,
    currentPage: 0,
    token: null,
    currentTemplate: 0,
    confDatatypes: [],
    infoCurrentTemplate: null,
    infoEntity: null,
    currentResponse: 0,
    currentResponseEditable: false,
    latitude: 0,
    longitude: 0,
    template: {
      headerTopTitle: 'headerTopTitle',
      headerTopSubtitle: 'headerTopSubtitle',
      items: [],
    },
    itemsMenuTop: {
      type: 'groupList',
      options: {
        id: 4,
        title: 'Inicio',
        items: [
          {
            id: 1,
            groupId: 4,
            title: 'Inicio',
            favorite: true,
            showIcon: true,
            path: '/home/',
          },
        ],
      },
    },
    itemsMenuBottom: {
      type: 'groupList',
      options: {
        id: 4,
        title: 'Información',
        items: [
          {
            id: 1,
            groupId: 4,
            title: 'Ajustes',
            favorite: true,
            showIcon: true,
            path: '/settings/',
          },
          {
            id: 2,
            groupId: 4,
            title: 'Desconectar',
            favorite: true,
            showIcon: true,
            path: '/exit/',
          },
        ],
      },
    },
    currentRol: null,
    canDelete: false,
    canCreate: false,
    canEdit: false,
    changePass: false,
    offlineMode: false,
  },
  actions: {
    async setLogoutUser({ commit }) {
      localStorage.removeItem('lastUpdate');
      localStorage.removeItem('username');
      localStorage.removeItem('password');
      await TokenRepository.deleteToken();
      commit('setLogoutUser');
      commit('setToken', null);
    },
    setCurrentPage({ commit }, value) {
      commit('setCurrentPage', value);
    },
    setCurrentWorkingDate({ commit }, value) {
      commit('SET_CURRENT_WORKING_DATE', value);
    },
    async login({ commit }, { user, password }) {
      const tokenData = await TokenRepository.login(user, password);
      commit('setToken', { data: tokenData });
      return tokenData;
    },
    async autoLoginWeb({ commit }, urlToken) {
      const tokenData = await TokenRepository.autoLoginWeb(urlToken);
      commit('setToken', { data: tokenData });
      return tokenData;
    },
    async offlineLogin({ commit }) {
      const tokenData = await TokenRepository.getToken();
      if (tokenData) commit('setToken', { data: tokenData });
      return tokenData;
    },
    offlineModeOn({ commit }) {
      commit('setOfflineMode', true);
    },
    offlineModeOff({ commit }) {
      commit('setOfflineMode', false);
    },
    async hasNetworkConnexion({ commit }, credentials) {
      return await TokenRepository.hasNetworkConnexion(credentials);
    },
  },
  mutations: {
    setResponseStatus(state, value) {
      state.responsesStatus = value;
    },
    setDayCountToEdit(state, num) {
      state.dayCountToEdit = num;
    },
    setToken(state, user) {
      state.token = user;
    },
    setLoginUser(state, user) {
      state.loggedUser = user;
    },
    setLogoutUser(state) {
      state.loggedUser = null;
    },
    setSignUpUser(state, data) {
      state.signUpUser = data;
    },
    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    setCurrentTemplate(state, value) {
      state.currentTemplate = value;
    },
    setConfDatatypes(state, value) {
      state.confDatatypes = value;
    },
    setInfoCurrentTemplate(state, value) {
      state.infoCurrentTemplate = value;
    },
    setInfoEntity(state, value) {
      state.infoEntity = value;
    },
    setCurrentResponse(state, value) {
      state.currentResponse = value;
    },
    setCurrentResponseEditable(state, value) {
      state.currentResponseEditable = value;
    },
    setWantToRemember(state, value) {
      state.wantToRemember = value;
    },
    setLatitude(state, value) {
      state.latitude = value;
    },
    setLongitude(state, value) {
      state.longitude = value;
    },
    setTemplate(state, menu) {
      menu.items = menu.items.filter((item) => {
        item.options.items = item.options.items.filter((subitem) => {
          const mode = subitem.mode || '';

          // No mode, no restriction
          if (mode === '') {
            return true;
          }
          // Menu item just for web
          if (mode === 'web' && !Device.cordova) {
            return true;
          }
          // Menu item just for app
          if (mode === 'app' && Device.cordova) {
            return true;
          }

          return false;
        });

        return item.options.items.length !== 0;
      });

      state.template = menu;
    },
    setCurrentRol(state, value) {
      state.currentRol = value;
    },
    SET_CURRENT_WORKING_DATE(state, value) {
      state.currentWorkingDate = value;
    },
    SET_CHANGE_PASSWORD(state, value) {
      state.changePass = value;
    },
    setOfflineMode(state, value) {
      state.offlineMode = value;
    },
  },
  getters: {
    getResponsesStatus: (state) => state.responsesStatus,
    getWantToRemember: (state) => state.wantToRemember,
    getUser: (state) => state.loggedUser,
    getSignUp: (state) => state.signUpUser,
    productsName: (state) =>
      state.loggedUser.company.products.map(({ name }) => name),
    getToken: (state) => state.token,
    getCurrentTemplate: (state) => state.currentTemplate,
    getInfoCurrentTemplate: (state) => state.infoCurrentTemplate,
    getInfoEntity: (state) => state.infoEntity,
    getCurrentResponse: (state) => state.currentResponse,
    getLatitude: (state) => state.latitude,
    getLongitude: (state) => state.longitude,
    menuItems: (state) => state.template,
    getCurrentRol: (state) => state.currentRol,
    getItemsMenuBottom: (state) => state.itemsMenuBottom,
    getItemsMenuTop: (state) => state.itemsMenuTop,
    getSyncRespLast: (state) => {
      const param = state.token.data.params.filter(
        (param) => param.name === 'SYNC_RESP_LAST'
      );
      return param.length ? Number(param[0].value) : 'no-limit';
    },
    getSearchDataRange: (state) => {
      const param = state.token.data.params.filter(
        (param) => param.name === 'SEARCH_DATE_RANGE'
      );
      return param.length && param[0].value === 'true' ? true : false;
    },
    getForceEntitySearch: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'FORCE_ENTITY_SEARCH'
      );
      return param && param.value === 'true' ? true : false;
    },
    getQuestionShare: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'SHARE_PDF_QUESTION'
      );
      return param && (param.value === 'true' || param.value === 'True') ? true : false;
    },
    getCreateNew: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'CREATE_NEW'
      );
      return param && (param.value === 'true' || param.value === 'True') ? true : false;
    },
    getAutoSave: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'AUTO_SAVE'
      );
      if(param) {
        if(param.value === 'MANUAL') {
          return 'MANUAL'
        }
        return param.value
      } return false;
    },
    getTopSave: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'TOP_SAVE'
      );
      return param && (param.value === 'true' || param.value === 'True') ? true : false;
    },
    getTopSaveRedirect: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'TOP_SAVE_REDIRECT'
      );
      return param && (param.value === 'true' || param.value === 'True') ? true : false;
    },
    getCloseAccordion: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'CLOSE_ACCORDION'
      );
      return param && (param.value === 'true' || param.value === 'True') ? true : false;
    },
    getCloseAccordionForm: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'CLOSE_ACCORDION_FORM'
      );
      return param && (param.value === 'true' || param.value === 'True') ? true : false;
    },
    getBorradorTemplate: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'BORRADOR_TEMPLATE'
      );
      return param && (param.value === 'true' || param.value === 'True') ? true : false;
    },
    getFilterEntity: (state) => {
      const param = state.token.data.params.find(
        (param) => param.name === 'FILTER_ENTITY'
      );
      return param && (param.value === 'true' || param.value === 'True') ? true : false;
    },
  },
  modules: {
    TaskLoader,
    Responses,
    EntityCacheSearch,
  },
});
export default store;