<template>
  <f7-page
    class="main-login"
  >
    <div
      class="login-screen-page"
    >
      <f7-block style="margin-top: 33%;">
        <!-- Login -->
        <f7-block-header class="text-align-center">
          <img
            width="220"
            height="auto"
            :src="logoHispatec"
            :alt="appName"
          >
        </f7-block-header>
        <f7-row>
          <f7-col
            class="padding label-text text-align-center"
            width="100"
          >
            <strong>
              {{ changePass ? 'Cambiar contraseña' : 'Configuremos su primer login' }}
            </strong>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <f7-list
              id="list-form"
              form
            >
              <f7-list-input
                outline
                label="Contraseña actual"
                item-label=""
                type="password"
                placeholder="Contraseña actual"
                clear-button
                max="255"
                required
                :value="oldPassword"
                @input="oldPassword = $event.target.value"
              />
              <f7-list-input
                outline
                label="Nueva contraseña"
                item-label=""
                type="password"
                placeholder="Nueva contraseña"
                clear-button
                max="255"
                required
                :value="password"
                @input="password = $event.target.value"
              />
              <f7-list-input
                outline
                label="Repetir contraseña"
                class="margin-top-half"
                item-label=""
                type="password"
                placeholder="Repetir contraseña"
                clear-button
                max="255"
                required
                :value="repeatPassword"
                @input="repeatPassword = $event.target.value"
              />
            </f7-list>
          </f7-col>
          <f7-col
            class="margin-top-half padding"
            width="100"
          >
            <f7-list>
              <f7-button
                col
                button
                button-large
                button-raised
                fill
                :disabled="canSubmit"
                @click="submitNewPasswords"
              >
                Guardar y continuar
              </f7-button>
            </f7-list>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import * as Config from '../js/constants';
import EventBus from '../js/EventBus';
import Api from '../js/services/Api';
import store from '../js/store/index';
import logoHispatec from '../static/img/logo-hispatec.svg';

export default {
  name: 'FirstLogin',
  data() {
    return {
      oldPassword: '',
      password: '',
      repeatPassword: '',
      appVersion: Config.APP_VERSION,
      appName: Config.APPLICATION_NAME,
      appLogo: Config.APPLICATION_LOGO,
      logoHispatec
    };
  },
  computed: {
    ...mapState(['changePass']),
    canSubmit() {
      return !(this.password && this.repeatPassword);
    },
  },
  destroyed() {
    this.SET_CHANGE_PASSWORD(false)
  },
  methods: {
    async submitNewPasswords() {
      if (this.password !== this.repeatPassword) {
        this.$f7.dialog.alert('Las contraseñas no coincinen');
        return;
      }

      const response = await Api.changeCurrentPassword(this.password, this.oldPassword);

      if (response.rdo !== 'ok') {
        this.$f7.dialog.alert(response.message);
        return;
      }

      if(this.changePass) {
        this.$f7.dialog.alert('Contraseña modificada con éxito', () => {
          this.setLogoutUser();
          this.$f7.views.main.router.navigate('/login/');
          location.reload();
        });
      } else {
        EventBus.$emit('full.reload');
        this.$f7.views.main.router.navigate('/home/');
      }
    },
    ...mapMutations(['SET_CHANGE_PASSWORD', 'setLogoutUser']),
  }
};
</script>