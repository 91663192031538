/* eslint-disable import/prefer-default-export */
function findObjectInNestedArray(valueToFind, keyToSearch, childrenKey, parents) {
  for (let index = 0; index < parents.length; index += 1) {
    const parent = parents[index];
    if (parent[keyToSearch] === valueToFind) {
      return parent;
    }
    if (parent[childrenKey] && parent[childrenKey].length) {
      if (findObjectInNestedArray(valueToFind, keyToSearch, childrenKey, parent[childrenKey])) {
        return findObjectInNestedArray(valueToFind, keyToSearch, childrenKey, parent[childrenKey]);
      }
    }
  }
  return null;
}

export {
  findObjectInNestedArray,
};
