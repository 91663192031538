<template>
  <div
    class="main-formsVisualizer"
    :style="customStyles"
  >
    <f7-block-title
      v-if="showHeader"
      medium
    >
      {{ parsedTemplate.name }}
    </f7-block-title>
    <f7-block-title
      v-if="showHeader"
    >
      {{ parsedTemplate.description }}
    </f7-block-title>
    <div
      v-for="(item, index) in parsedTemplate.detail"
      :key="index"
    >
      <hi-forms-container
        v-if="item.type !== 'LIST_TEMPLATE'"
        :layout="parsedLyoutName(item.type)"
        :view-type="viewType(item.viewType)"
        :group-title="item.name"
        :visibility="visibilitys[item.ref]"
        :close="groupsClose"
      >
        <div
          :slot="parsedLyoutName(item.type)"
          class="list"
          :class="{
            horizontal: isHorizontal(item.type),
            vertical: isVertical(item.type),
            grid: isGrid(item.type),
          }"
        >
          <ul
            v-for="(children, index2) in item.children"
            :key="index2"
          >
            <hi-input-visualizer
              v-if="children"
              :key="inputsVisualizersKeys[children.ref]"
              :ref="children.ref"
              :row-input="children"
              :btn-round="btnRound"
              :primary="primary"
              :secondary="secondary"
              :font-color-primary="fontColorPrimary"
              :font-color-secondary="fontColorSecondary"
              :uploaded-files="filesToUpload"
              :edit-value="finalData[children.ref]"
              :extra-data="extraData[children.ref]"
              :visibility="visibilitys[children.ref]"
              :read-only-form="disabledInputs"
              @file-event="fileEvent"
              @unlink-file-event="unlinkFileEvent"
              @gallery-event="galleryEvent"
              @unlink-image-event="unlinkImageEvent"
              @data-event="dataBuilder"
              @reset-manual-edit="resetManualEdit"
              @keypress.native.enter="inputNext(item.children[index2+1].ref)"
            />
          </ul>
        </div>
      </hi-forms-container>
      <hi-template-list-visualizer-new
        v-if="item.type === 'LIST_TEMPLATE'"
        :title="item.name"
        :reference="item.ref"
        :primary="primary"
        :visibility="visibilitys[item.ref+'_LT']"
        :sub-templates-count="item && finalData[item.ref] ? finalData[item.ref].length : null"
        @new-sublayout-data-group="newSublayoutDataGroup"
        @delete-sublayout-data-group="deleteSublayoutDataGroup"
        @open-sublayout-data-group="openItemSubLayout"
      >
        <template slot-scope="parentData">
          <hi-forms-container
            v-for="(container, index3) in item.children"
            :key="index3"
            :layout="parsedLyoutName(container.type)"
            :view-type="viewType(container.viewType)"
            :group-title="container.name"
            :close="groupsClose"
          >
            <div
              :slot="parsedLyoutName(container.type)"
              :class="{
                horizontal: isHorizontal(container.type),
                vertical: isVertical(container.type),
                grid: container.viewType === 'grid',
              }"
            >
              <div
                v-for="(children, index2) in container.children"
                :key="index2"
              >
                <hi-input-visualizer
                  :key="inputsVisualizersKeys[item.ref][parentData.index][children.ref]"
                  :ref="`${children.upperRef}.${children.ref}`"
                  :template-index="parentData.index"
                  :row-input="children"
                  :btn-round="btnRound"
                  :primary="primary"
                  :secondary="secondary"
                  :font-color-primary="fontColorPrimary"
                  :font-color-secondary="fontColorSecondary"
                  :uploaded-files="filesToUpload"
                  :read-only-form="disabledInputs"
                  :edit-value="finalData[item.ref][parentData.index][children.ref]"
                  :extra-data="extraData[item.ref] && extraData[item.ref][parentData.index] ? extraData[item.ref][parentData.index][children.ref]: null"
                  :visibility="visibilitys[item.ref] && visibilitys[item.ref][parentData.index]?visibilitys[item.ref][parentData.index][children.ref]:true"
                  :custom-items="getCustomItems(item.ref, children.ref, parentData.index)"
                  @file-event="fileEvent"
                  @unlink-file-event="unlinkFileEvent"
                  @gallery-event="galleryEvent"
                  @unlink-image-event="unlinkImageEvent"
                  @data-event="dataBuilder"
                  @reset-manual-edit="resetManualEdit"
                />
              </div>
            </div>
          </hi-forms-container>
        </template>
      </hi-template-list-visualizer-new>
    </div>
    <f7-button
      v-if="showButton"
      large
      fill
      :round="btnRound"
      class="send-btn margin-top"
    >
      {{ btnText }}
    </f7-button>
  </div>
</template>
<script>
import moment from 'moment';
import '../../css/dynamic-forms.scss';

import axios from 'axios';
import EventBus from '../../js/EventBus';

import Evaluator from '../../js/utilities/evaluator';
import { findObjectInNestedArray } from '../../js/utilities/helpers';

import HiFormsContainer from './HiFormsContainer.vue';
import HiInputVisualizer from './HiInputVisualizer.vue';
import HiTemplateListVisualizerNew from './HiTemplateListVisualizerNew.vue';


export default {
  name: 'HiFormsVisualizer',
  components: { HiFormsContainer, HiInputVisualizer, HiTemplateListVisualizerNew },
  props: {
    template: { type: Object, default: () => {} },
    templateData: { type: Object, default: () => {} },
    templateExtraData: { type: Object, default: () => null },
    // data passed from app that can be used internally for services or calcs
    templateExternalData: { type: Object, default: () => {} },
    primary: { type: String, default: 'var(--his-primary)' },
    secondary: { type: String, default: 'var(--his-secondary)' },
    fontColorPrimary: { type: String, default: 'var(--his-primary)' },
    fontColorSecondary: { type: String, default: 'var(--his-font-color-secundary)' },
    btnText: { type: String, default: 'Enviar' },
    btnRound: { type: Boolean, default: false },
    showHeader: { type: Boolean, default: true },
    showButton: { type: Boolean, default: true },
    serviceUrl: { type: String, default: '' },
    serviceToken: { type: String, default: '' },
    executeServices: { type: Boolean, default: true },
    refreshData: { type: Boolean, default: false },
    disabledInputs: { type: Boolean, default: false },
    changeGroupTypeRestrictions: { type: Boolean, default: false },
    bloques: { type: Array, default: () => null },
    groupsClose: { type: Boolean, default: false },
  },
  data() {
    return {
      parsedTemplate: {},
      customItems: {},
      filesToUpload: {},
      images: {},
      finalData: {},
      visibilitys: {},
      extraData: {},
      inputsVisualizersKeys: {},
      visualizerKey: 1,
      servicesData: {},
      servicesParams: {},
      servicesIds: [],
      initialServiceMapping: {}, // for every service, it will indicate which fields are involved
      servicesRunning: [],
      evaluator: {
        triggerFields: [],
        calcFields: {},
        mathParser: null,
        instance: null,
      },
      not_evaluate: [],
      itemsSubLayoutOpened: {},
      time_out: null
    };
  },
  computed: {
    customStyles() {
      return {
        '--custom-primary': this.primary,
        '--custom-secondary': this.secondary,
        '--custom-font-color-primary': this.fontColorPrimary,
        '--custom-font-color-secondary': this.fontColorSecondary,
      };
    },
    isFormName() {
      return this.finalData.hasOwnProperty('entity');
    },
    isRefGrupoAndNotNull() {
      return this.finalData.ref && this.finalData.ref.includes('grupo');
    },
  },
  watch: {
    template(value) {
      this.changeGroupRestrictions(value);
      this.visualizerKey += 1;
      this.initialSetUp();
    },
  },
  async mounted() {

    await this.initialSetUp();
    this.forceChanges();

    this.$nextTick(function () {
      console.log('DOM updated');
      this.$f7.dialog.close();
    });

  },
  beforeDestroy() {
    clearTimeout(this.time_out)
  },
  destroyed() {
    clearTimeout(this.time_out)
  },
  methods: {
    inputNext(ref) {
      this.$refs[ref][0].$el.getElementsByTagName('input')[0].focus();
    },
    openItemSubLayout({ reference, index }) {
      // console.log('openItemSubLayout', reference, index)

      if(this.itemsSubLayoutOpened.hasOwnProperty(reference)) {
        if(!this.itemsSubLayoutOpened[reference].includes(index)) {
          this.itemsSubLayoutOpened[reference].push(index);
        }
      } else {
        this.itemsSubLayoutOpened[reference] = [index];
      }

      ///////////////////////////////////////////////
      const subtemplateDefaultData = this.finalData[reference][index];
      this.finalData[reference][index] = subtemplateDefaultData;
      // this.inputsVisualizersKeys[reference][index] = subtemplateDefaultData;

      // // this.inputsVisualizersKeys[reference].push(
      // //   this.createSubTemplateVisualizerKeys(subtemplateDefaultData),
      // // );

      // // this.extraData[reference].push({});
      this.reEvaluateDueToSubtemplate(reference, index);
    },
    forceChanges() {
      this.$emit('form-data', { data: this.finalData, extraData: this.extraData });
    },
    quitNoEvaluate(upperRef) {
      const dataEvaluate = JSON.parse(JSON.stringify(this.finalData[upperRef]))
      for(const element of dataEvaluate) {
        for(const element1 of this.not_evaluate) {
          if(element.hasOwnProperty(element1)) {
            delete element[element1];
          }
        }
      }
      return dataEvaluate;
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm');
    },
    async initialSetUp() {
      this.finalData = {};
      this.changeGroupRestrictions(this.template);
      this.extraData = this.templateExtraData;

      const evaluatorInstance = new Evaluator();
      this.evaluator.instance = evaluatorInstance;

      this.setDefaultValues();
      this.setPreloadedData();
      this.setUpEvaluator();
      this.getServicesInvolved();


      // Si vienen datos externos, se añade al evaluador como show_externalData
      if (this.templateExternalData) {
        this.evaluator.instance.evaluate(`show_externalData = ${JSON.stringify(this.templateExternalData)}`);
      }


      if (this.servicesIds.length > 0 && this.executeServices) {
        this.servicesParams = await this.getServicesParams(this.servicesIds.toString()) || [];
        this.discardUnnecessaryParams();
        await this.initialServicesLoad();
      }
      this.evaluator.instance.updateData(this.finalData);


      this.initialEvaluation();
      if (this.refreshData || this.isRefGrupoAndNotNull || this.isFormName) {
        this.$emit('form-data', { data: this.finalData, extraData: this.extraData });
      }
    },
    changeGroupRestrictions(template) {
      if (!this.changeGroupTypeRestrictions) {
        this.parsedTemplate = template;
      } else {
        try {
          if (this.bloques && this.bloques.length) {
            const bloque = this.bloques.find((child) => child.key === this.templateData.ref);
            if (bloque && bloque.items.length) {
              // eslint-disable-next-line no-undef
              this.parsedTemplate = structuredClone(template);
              const typeSelectGroup = this.parsedTemplate.detail[0].children.find((child) => child.tag === 'GROUP' && child.ref === 'type' && child.dataType === 'select');
              if (this.templateData.type === 'LIST_TEMPLATE') {
                typeSelectGroup.options.readOnly = true;
              } else {
                const index = typeSelectGroup.items.findIndex((option) => option.id === 'LIST_TEMPLATE');
                if (index > -1) typeSelectGroup.items.splice(index, 1);
              }
            } else this.parsedTemplate = template;
          } else this.parsedTemplate = template;
        } catch (error) {
          this.parsedTemplate = template;
        }
      }
    },
    getServicesInvolved() {
      this.parsedTemplate.detail.forEach((lyout) => {
        if (lyout.type !== 'LIST_TEMPLATE') {
          lyout.children.forEach((input) => {
            if (!input) return;
            if (input.options.service_id != null && input.options.service_id > 0) {
              this.servicesIds.push(input.options.service_id);
              if (!(input.options.service_id in this.initialServiceMapping)) {
                this.initialServiceMapping[input.options.service_id] = [];
              }
              this.initialServiceMapping[input.options.service_id].push(input.ref);
            }
          });
        } else {
          lyout.children.forEach((group) => {
            if (group.children) {
              group.children.forEach((child) => {
                if (child.options.service_id != null && child.options.service_id > 0) {
                  this.servicesIds.push(child.options.service_id);
                  if (!(child.options.service_id in this.initialServiceMapping)) {
                    this.initialServiceMapping[child.options.service_id] = [];
                  }
                  this.initialServiceMapping[child.options.service_id].push(`${child.upperRef}.${child.ref}`);
                }
              });
            }
          });
        }
      });
    },
    setDefaultValues() {
      const CurrentFinalData = {};
      const CurrentFinalVisibility = {};
      const visualizerKeys = {};
      const generateExtraData = this.extraData == null;
      if (generateExtraData) this.extraData = {};
      this.parsedTemplate.detail.forEach((lyout) => {
        if (lyout.type !== 'LIST_TEMPLATE') {
          lyout.children.forEach((input) => {
            if (!input) return;
            //Enrique
            if(input.dataType === 'image') {
              this.not_evaluate.push(input.ref);
            }
            //Enrique
            if (input.options.defaultValue !== undefined) {
              CurrentFinalData[input.ref] = input.options.defaultValue;
            } else if (input.options.defaultValues !== undefined) {
              CurrentFinalData[input.ref] = input.options.defaultValues;
            } else if (input.dataType === 'boolean' || input.dataType === 'toggle' || input.dataType === 'hidden') {
              if(input.options.value) {
                CurrentFinalData[input.ref] = input.options.value;
              } else {
                CurrentFinalData[input.ref] = input.options.checked;
              }
            } else if (input.dataType === 'file'
                      || input.dataType === 'galery'
                      || input.dataType === 'firma') {
              CurrentFinalData[input.ref] = input.options.state;
            } else {
              const inputValue = '';
              CurrentFinalData[input.ref] = inputValue;
            }

            // fix: boolean fields from erp comes with defaultValue property, so it never sets the real value
            // this probably should be refactored
            if (input.dataType === 'boolean' || input.dataType === 'toggle') {
              CurrentFinalData[input.ref] = input.options.value;
              if(input.options.value) {
                CurrentFinalData[input.ref] = input.options.value;
              } else {

                if('checked' in input.options) {
                  CurrentFinalData[input.ref] = input.options.checked;
                } else{
                  // Ni trae value ni trae checked, por defecto se establece a false
                  CurrentFinalData[input.ref] = false;
                }

              }
            }


            if (input.dataType === 'file' &&  !this.filesToUpload[input.ref]) {
              let objtmp = {};
              if(this.templateData && this.templateData[input.ref]) {
                objtmp = { size: 0, files: this.templateData[input.ref] }
              } else {
                objtmp = { size: 0, files: [] }
              }
              this.filesToUpload[input.ref] = objtmp;
            }

            visualizerKeys[input.ref] = 0;
            if (generateExtraData) this.extraData[input.ref] = {};
          });
        } else {
          const key = lyout.ref;
          if (!(key in CurrentFinalData)) {
            CurrentFinalData[key] = [];
            CurrentFinalVisibility[key] = [];
            visualizerKeys[key] = [];
            if (generateExtraData) this.extraData[key] = [];
          }
        }
      });
      // console.log('CurrentFinalData', CurrentFinalData)
      this.finalData = CurrentFinalData;
      this.visibilitys = CurrentFinalVisibility;
      this.inputsVisualizersKeys = visualizerKeys;
    },
    setPreloadedData() {
      //console.log('2. setPreloadedData...');
      if (this.templateData === undefined && this.templateData == null) {
        return;
      }
      for (const key in this.templateData) {
        if (Object.hasOwnProperty.call(this.templateData, key)) {
          const value = this.templateData[key];
          if (typeof (value) !== 'object') {
            this.finalData[key] = value;
          } else {
            const currentLayout = this.parsedTemplate.detail.find((l) => l.ref === key);
            if (currentLayout === undefined) {
              this.finalData[key] = value;
            } else {
              value.forEach((subTemplate, index) => {
                const subtemplateDefaultData = this.createListTemplateDefault(currentLayout.children);
                this.finalData[key].push(subtemplateDefaultData);
                this.inputsVisualizersKeys[key].push(
                  this.createSubTemplateVisualizerKeys(subtemplateDefaultData),
                );
                this.extraData[key].push({});
                for (const subKey in subTemplate) {
                  if (Object.hasOwnProperty.call(subTemplate, subKey)) {
                    const subTemplateValue = subTemplate[subKey];
                    this.finalData[key][index][subKey] = subTemplateValue;
                  }
                }
                const allSubtemplateKeys = Object.keys(this.finalData[key][index]);
                for (let i = 0; i < allSubtemplateKeys.length; i += 1) {
                  const subKey = allSubtemplateKeys[i];
                  this.extraData[key][index][subKey] = {};
                }
              });
            }
          }
        }
      }
      //console.log('2. FIN setPreloadedData...');
    },
    createListTemplateDefault(children) {
      const returnData = {};
      children.forEach((subLyout) => {
        if (subLyout && subLyout.children) {
          subLyout.children.forEach((input) => {
            const reference = input.ref;
            let inputValue = null;
            if (input.options.defaultValue) {
              inputValue = input.options.defaultValue;
            } else if (input.options.defaultValues) {
              inputValue = input.options.defaultValues;

              // FIX: CASI-27
              const refGroup = input.refGroup;
              if(input.dataType === 'select') {
                if(input.items && input.items.length === 1) {
                  if(input.options.calcOptions.relatedFields.length>0) {
                    const relatedFieldTmp = input.options.calcOptions.relatedFields[0]
                    if(!relatedFieldTmp.includes(refGroup+'.')) {
                      inputValue = input.items[0].id;
                    }
                  } else {
                    inputValue = input.items[0].id;
                  }
                }
              }

            } else if (input.dataType === 'boolean' || input.dataType === 'toggle') {
              inputValue = input.options.value;
            } else if (input.dataType === 'file'
                          || input.dataType === 'galery'
                          || input.dataType === 'firma') {
              inputValue = input.options.state;
            } else if (input.dataType === 'number') {
              inputValue = input.options.defaultValue;
            } else {
              inputValue = '';
            }
            returnData[reference] = inputValue;
          });
        }
      });
      return returnData;
    },
    createListTemplateVisibilitys(children) {
      const returnData = {};
      try {
        children.forEach((subLyout) => {
          if (subLyout.children) {
            subLyout.children.forEach((input) => {
              const reference = input.ref;
              returnData[reference] = true;
            });
          }
        });
      } catch (e) {
        console.log(e);
      }

      return returnData;
    },
    createSubTemplateVisualizerKeys(subtemplateData) {
      const visualizerKeys = {};
      for (const key in subtemplateData) {
        if (Object.hasOwnProperty.call(subtemplateData, key)) {
          visualizerKeys[key] = 0;
        }
      }
      return visualizerKeys;
    },
    newSublayoutDataGroup({ reference, index }) {
      const currentLayout = this.parsedTemplate.detail.find(({ ref }) => ref === reference);
      // this.finalData[reference][index] = this.createListTemplateDefault(currentLayout.children);
      const subtemplateDefaultData = this.createListTemplateDefault(currentLayout.children);
      console.log('newSublayoutDataGroup.subtemplateDefaultData',subtemplateDefaultData)
      subtemplateDefaultData.id = index + 1;
      this.finalData[reference].push(subtemplateDefaultData);

      this.visibilitys[reference].push(this.createListTemplateVisibilitys(currentLayout.children));

      this.inputsVisualizersKeys[reference].push(
        this.createSubTemplateVisualizerKeys(subtemplateDefaultData),
      );

      this.extraData[reference].push({});
      this.reEvaluateDueToSubtemplate(reference, index);
    },
    deleteSublayoutDataGroup({ reference, index }) {
      // delete this.finalData[reference][index];
      const auxData = this.finalData[reference];
      const auxExtraData = this.extraData[reference];
      auxData.splice(index, 1);
      auxExtraData.splice(index, 1);
      this.reEvaluateDueToSubtemplate(reference);
      this.finalData[reference] = auxData;
      this.extraData[reference] = auxExtraData;
      this.inputsVisualizersKeys[reference].splice(index, 1);
      this.visualizerKey += 1;
    },
    async initialServicesLoad() {
      const uniqueServiceIds = new Set(this.servicesIds);

      /**
       * Currying function more info:
       * https://yeisondaza.com/currying-en-javascript-funciones-con-superpoderes
       *
       * @param {Number} attemptCount
       * @param {Number} defaultID This is used when a new attempt is needed.
       *                           SEE: setTimeout(callToService(attemptCount - 1, id), 500);
       */
      const callToService = (attemptCount, defaultID) => async (id) => {
        if (!id) {
          id = defaultID;
        }

        try {
          if (this.initialServiceMapping[id][0] in this.evaluator.calcFields) {
            return;
          }

          const items = await this.executeService(id);

          if (!items && attemptCount > 0) {
            this.time_out = setTimeout(callToService(attemptCount - 1, id), 1000);
            return;
          }

          await this.updateItemsInInputs(items, this.initialServiceMapping[id]);
        } catch (e) {
          console.error(e);
          console.error('error preparando ejecución inicial de servicio ', id);
        }
      };

      uniqueServiceIds.forEach(callToService(2));
    },
    updateItemsInInputs(items, inputs, index) {
      // if items comes to false, it means the service did not execute,
      // so we do not want to update anything
      // if (!items) return;
      if (!items || items.length===0) return;
      this.parsedTemplate.detail.forEach((lyout) => {
        if (lyout.type !== 'LIST_TEMPLATE') {
          lyout.children.forEach((input) => {
            if (inputs.indexOf(input.ref) > -1) {
              input.items = items;
              //enrique
              let currentValue = this.finalData[input.ref];
              //enrique

              // The current value is in the items list?
              //  This should be true (found.length != 0) when loading the edit response page.
              const found = items.filter(({ id }) => id == currentValue);

              const shouldClear = found.length === 0;
              if (shouldClear && !Array.isArray(currentValue)) {
                // The current selected item is not present in the new item list. Clear input value
                EventBus.$emit('clean-selector', { reference: input.ref, quantity: input.items.length });

                //enrique
                currentValue = null;
                // fin enrique
              }

              // enrique
              if(!currentValue && items.length > 0) {
                if(items.length === 1) {
                  this.updateEvaluatedValue(input.ref, 0, items[0][input.options.valueKey]);
                }
              }
              // fin enrique


              // if it had value before, but in the new options the former value is not present
              // then we reset the value
              if (
                items.filter((item) => item[input.options.valueKey] == this.finalData[input.ref]).length === 0
              ) {
                if(!Array.isArray(currentValue)) {
                  this.updateEvaluatedValue(input.ref, 0, '');
                } else {
                  this.updateEvaluatedValue(input.ref, 0, currentValue);
                }

              }
              this.inputsVisualizersKeys[input.ref] += 1;
            }
          });
        } else {
          lyout.children.forEach((group) => {
            if (group.children) {
              group.children.forEach((child) => {
                if (inputs.indexOf(`${child.upperRef}.${child.ref}`) > -1) {

                  if (typeof index !== 'number') child.items = items;
                  else {
                    if (!this.customItems[`${child.upperRef}.${child.ref}`]) {
                      this.customItems[`${child.upperRef}.${child.ref}`] = {};
                    }
                    this.customItems[`${child.upperRef}.${child.ref}`][index] = items;
                  }
                  // child.items = items;
                  const ids = items.map((item) => item.id);
                  // Enrique
                  const updateValueByIndex = (indexToUpdate) => {
                    if (ids.indexOf(this.finalData[child.upperRef][indexToUpdate][child.ref],) === -1) {
                      if (ids.length === 1) {
                        this.updateEvaluatedValue(
                          `${child.upperRef}.${child.ref}`,
                          indexToUpdate,
                          ids[0],
                        );
                      } else if(ids.length > 1) {
                        this.updateEvaluatedValue(`${child.upperRef}.${child.ref}`, indexToUpdate, this.finalData[child.upperRef][indexToUpdate][child.ref]);
                      }
                      else {
                        this.updateEvaluatedValue(`${child.upperRef}.${child.ref}`, indexToUpdate, '');
                      }
                    }
                    this.inputsVisualizersKeys[child.upperRef][indexToUpdate][child.ref] += 1;
                  };

                  if (typeof index === 'number') updateValueByIndex(index);
                  else {
                    for (let i = 0; i < this.inputsVisualizersKeys[child.upperRef].length; i += 1) {
                      updateValueByIndex(i);
                    }
                  }
                  //Fin enrique
                }
              });
            }
          });
        }
      });
    },
    getCustomItems(itemRef, childrenRef, parentIndex) {
      if (this.customItems[`${itemRef}.${childrenRef}`]) {
        return this.customItems[`${itemRef}.${childrenRef}`][parentIndex];
      }
      return undefined;
    },
    setUpEvaluator() {
      this.parsedTemplate.detail.forEach((layout) => {
        if (layout.type !== 'LIST_TEMPLATE') {
          // Es tipo grupo
          // Se comprueba si tiene condiciones de visibilidad
          if(layout.calculated && layout.visibilidad) {
              const relTmp = layout.relatedFields.split(',')
              this.evaluator.triggerFields.push(...relTmp);
              this.evaluator.calcFields[layout.ref] = {
                ...{ formula: layout.formula},
                ...{ relatedFields: relTmp},
                ...{ visibility: layout.visibilidad}
              };
          }
          /////////////////

          layout.children.forEach((input) => {
            if (!input) return;
            if (input.options.calculated) {
              this.evaluator.triggerFields.push(...input.options.calcOptions.relatedFields);
              this.evaluator.calcFields[input.ref] = {
                ...input.options.calcOptions,
                ...{ service_id: input.options.service_id },
                ...{ visibility: input.options.visibilidad}
              };
            }
          });
        } else {
          // Es tipo grupo (aunque sea LIST_TEMPLATE). SE METE EN EVALUATOR.CALCFIELDS
          // Se comprueba si tiene condiciones de visibilidad
          if(layout.calculated && layout.visibilidad) {
              const relTmp = layout.relatedFields.split(',')
              this.evaluator.triggerFields.push(...relTmp);
              this.evaluator.calcFields[layout.ref+'_LT'] = {
                ...{ formula: layout.formula},
                ...{ relatedFields: relTmp},
                ...{ visibility: layout.visibilidad}
              };
          }
          /////////////////
          layout.children.forEach((subLayout) => {
            if (subLayout.children) {
              subLayout.children.forEach((input) => {
                //Enrique
                if(input.dataType === 'image') {
                  this.not_evaluate.push(input.ref);
                  this.not_evaluate.push(`${input.upperRef}.${input.ref}`);
                }
                //Enrique
                if (input.options.calculated) {
                  this.evaluator.triggerFields.push(...input.options.calcOptions.relatedFields);
                  this.evaluator.calcFields[`${input.upperRef}.${input.ref}`] = {
                    ...input.options.calcOptions,
                    ...{ service_id: input.options.service_id },
                    ...{ visibility: input.options.visibilidad}
                  };
                }
              });
            }
          });
        }
      });
      this.evaluator.triggerFields = [...new Set(this.evaluator.triggerFields)];
    },

    /////////////////////////////////////////////////////////////////////////////////////

    hasToEvaluate(inputRef, upperRef, index) {
      try {
        const finalRef = (upperRef != null) ? `${upperRef}.${inputRef}` : inputRef;
        // console.log('hasToEvaluate', finalRef);
        if (this.evaluator.triggerFields.indexOf(finalRef) > -1) {
          if (finalRef.indexOf('.') === -1) {
            // Para que no falle la evaluacion tipo ref = hola , sería ahora ref = 'hola'
            let valueTmp = '';
            if(typeof this.finalData[inputRef] === 'string') {
              valueTmp = '\''+this.finalData[inputRef]+'\'';
            } else if (typeof this.finalData[inputRef] === 'object' && Array.isArray(this.finalData[inputRef])){
              if(Date.parse(this.finalData[inputRef][0])>0) {
                valueTmp = Date.parse(this.finalData[inputRef][0]);
              } else {
                valueTmp = this.finalData[inputRef]
              }
            }
            else {
              valueTmp = this.finalData[inputRef]
            }

            this.evaluator.instance
              .evaluate(`${inputRef} = ${(this.finalData[inputRef]) === '' ? '\'\'' : valueTmp}`);
          } else {
            // Enrique. Evitamos enviar el base64 de las imagenes al evaluador en los list template
            const dataEvaluate = this.quitNoEvaluate(upperRef)
            // this.evaluator.instance.evaluate(`${upperRef} = ${JSON.stringify(this.finalData[upperRef])}`);
            this.evaluator.instance.evaluate(`${upperRef} = ${JSON.stringify(dataEvaluate)}`);
            //Enrique
          }

          // if (this.finalData[upperRef]) {
          //   const count = this.finalData[upperRef].length;
          //   let retro = count;
          //   while (retro >= 0) {
          //     this.evaluateChange(inputRef, upperRef, retro);

          //     retro -= 1;
          //   }
          // } else {
          //   this.evaluateChange(inputRef, upperRef, index);
          // }

          this.evaluateChange(inputRef, upperRef, index);
        }
      } catch (e) {
        console.error('error evaluador', e);
      }
    },
    async evaluateChange(inputRef, upperRef, index) {
      const finalRef = (upperRef != null) ? `${upperRef}.${inputRef}` : inputRef;

      for (const calc in this.evaluator.calcFields) {
        if (Object.hasOwnProperty.call(this.evaluator.calcFields, calc)) {
          const calcOptions = this.evaluator.calcFields[calc];
          if (calcOptions.relatedFields.indexOf(finalRef) > -1) {
            let relatedInputRef; let
              relatedUpperRef;
            if (calc.indexOf('.') > -1) {
              [relatedUpperRef, relatedInputRef] = calc.split('.');
            } else {
              relatedUpperRef = null;
              relatedInputRef = calc;
            }
            this.evaluateField(relatedInputRef, relatedUpperRef, index);
          }
        }
      }
    },
    async evaluateField(inputRef, upperRef, index) {

      const finalRef = (upperRef != null) ? `${upperRef}.${inputRef}` : inputRef;

      if (!Object.hasOwnProperty.call(this.evaluator.calcFields, finalRef)) return;
      const calcOptions = this.evaluator.calcFields[finalRef];

      // we guess for now that every field with service is a select,
      // and if it is calculated by service then it should not respect the manually edited,
      // or they will stop loading after 1 change, even if you change the input they depend on
      if (!this.isManuallyEdited(finalRef, index)
        || (calcOptions.service_id != null && calcOptions.service_id > 0)) {
        // if the input has no service associated then we evaluate the local formula
        if (calcOptions.service_id == null || calcOptions.service_id === 0) {
          // const formulaResult = (finalRef.indexOf('.') > -1 && upperRef === finalRef.split('.')[0])
          //   ? await this.evaluator
          //     .instance.evaluateInSubTemplate(calcOptions.formula, upperRef, index)
          //   : await this.evaluator.instance.evaluate(calcOptions.formula);
          // this.updateEvaluatedValue(finalRef, index, formulaResult);

          if(calcOptions.formula || calcOptions.visibility) {
            let formulaResult = null;
            if(finalRef.indexOf('.') > -1 && upperRef === finalRef.split('.')[0]) {
              if(index) {
                formulaResult = this.evaluator.instance.evaluateInSubTemplate(calcOptions.formula, upperRef, index);
                this.updateEvaluatedValue(finalRef, index, formulaResult);
              } else {
                const nIndex = this.finalData[upperRef]?this.finalData[upperRef].length:0;
                for(let i = 0; i < nIndex; i++) {
                  formulaResult = this.evaluator.instance.evaluateInSubTemplate(calcOptions.formula, upperRef, i);
                  this.updateEvaluatedValue(finalRef, i, formulaResult);
                }
              }
            } else {
              // FIX: PAPP-204. Si no hay formula, evitamos estas ejecuciones
              // Esto se puede dar, que tenga calcOptions, pero no formula, ya que puede tener condiciones de visibilidad
              if(calcOptions.formula) {
                formulaResult = this.evaluator.instance.evaluate(calcOptions.formula);
                this.updateEvaluatedValue(finalRef, index, formulaResult);
              }
            }
          }

          if(calcOptions.visibility) {
            const visibilityResult = (finalRef.indexOf('.') > -1 && upperRef === finalRef.split('.')[0])
            ? this.evaluator
              .instance.evaluateInSubTemplate(calcOptions.visibility, upperRef, index)
            : this.evaluator.instance.evaluate(calcOptions.visibility);
            this.updateEvaluatedVisibility(finalRef, index, visibilityResult);
          }

          // this.updateEvaluatedValue(finalRef, index, formulaResult);
        } else { // we execute the service if it has service associated
          const items = await this.executeService(calcOptions.service_id, finalRef, index);
          this.updateItemsInInputs(items, [finalRef], index);
        }
      }
      // console.log('=============================================================')
    },

    updateEvaluatedVisibility(inputRef, index, newValue) {
      this.evaluator.instance.updateScope(this.finalData);
      if (inputRef.indexOf('.') > -1) {
        const splittedRef = inputRef.split('.');

        if(!this.visibilitys[splittedRef[0]][index]) {
          const currentLayout = this.parsedTemplate.detail.find(({ ref }) => ref === splittedRef[0])
          this.visibilitys[splittedRef[0]].push(this.createListTemplateVisibilitys(currentLayout.children))
        }

        if (newValue === null) {
          this.visibilitys[splittedRef[0]][index][splittedRef[1]] = true;
        } else {
          try{
            this.visibilitys[splittedRef[0]][index][splittedRef[1]] = typeof newValue === 'undefined' ? true : Boolean(newValue);
          } catch(err) {}
        }
        this.hasToEvaluate(splittedRef[1], splittedRef[0], index);
      } else {
        if (newValue === null) {
          this.visibilitys[inputRef] = false;
        } else {
          this.visibilitys[inputRef] = typeof newValue === 'undefined' ? true : Boolean(newValue);
        }
        this.hasToEvaluate(inputRef, null, index);
      }
    },

    updateEvaluatedValue(inputRef, index, newValue) {
      if (inputRef.indexOf('.') > -1) {
        const splittedRef = inputRef.split('.');
        if (this.finalData[splittedRef[0]]
        && this.finalData[splittedRef[0]][index]) {
          this.finalData[splittedRef[0]][index][splittedRef[1]] = newValue;
          this.evaluator.instance.updateScope(this.finalData);
          this.hasToEvaluate(splittedRef[1], splittedRef[0], index);
        }
      } else {
        this.finalData[inputRef] = newValue;
        this.evaluator.instance.updateScope(this.finalData);
        this.hasToEvaluate(inputRef, null, index);
      }
    },
    //////////////////////////////////////////////////////////////////////////////////////////////
    isManuallyEdited(finalRef, index) {
      let inputRef;
      let upperRef;
      if (finalRef.indexOf('.') > -1) {
        [upperRef, inputRef] = finalRef.split('.');
      } else {
        inputRef = finalRef;
        upperRef = null;
      }

      if (upperRef == null) {
        if (this.extraData
        && this.extraData[inputRef]
        && this.extraData[inputRef].manuallyEdited) {
          return (this.extraData[inputRef].manuallyEdited);
        }
        return false;
      }
      if (this.extraData
      && this.extraData[upperRef]
      && this.extraData[upperRef][index]
      && this.extraData[upperRef][index][inputRef]) {
        return (this.extraData[upperRef][index][inputRef].manuallyEdited);
      }
      return false;
    },
    // whenever a subtemplate is added or deleted we reevaluate the parent template
    reEvaluateDueToSubtemplate(upperRef, index=null) {
      if (this.evaluator.triggerFields.filter((f) => f.indexOf(`${upperRef}.`) > -1).length === 0) return;
      // we update the data for the scope
      // this.evaluator.instance.evaluate(`${upperRef} = ${JSON.stringify(this.finalData[upperRef])}`);
      // Enrique
      this.evaluator.instance.evaluate(`${upperRef} = ${JSON.stringify(this.quitNoEvaluate(upperRef))}`);

      for (const field in this.evaluator.calcFields) {
        if (Object.hasOwnProperty.call(this.evaluator.calcFields, field)) {
          if (field.indexOf(`${upperRef}.`) === -1) {
            const element = this.evaluator.calcFields[field];
            if (element.relatedFields.filter((f) => f.indexOf(`${upperRef}.`) > -1).length > 0) {
              this.evaluateField(field, null, 0);
            }
          } else {
            // console.log('FIELD', field)
            if (index >= 0) {
              const calcOptions = this.evaluator.calcFields[field];
              if(calcOptions.visibility){
                // console.log('Se calcula la condicion de visibilidad en el listtemplate')
                const visibilityResult = (field.indexOf('.') > -1 && upperRef === field.split('.')[0])
                ? this.evaluator
                  .instance.evaluateInSubTemplate(calcOptions.visibility, upperRef, index)
                : this.evaluator.instance.evaluate(calcOptions.visibility);
                // console.log('visibilityResult', visibilityResult)
                this.updateEvaluatedVisibility(field, index, visibilityResult);
              }
            }
          }
        }
      }
    },
    initialEvaluation() {
      this.setUpInitialEvaluatorData();
      const keys = Object.keys(this.evaluator.calcFields);
      const mainFields = keys.filter((f) => f.indexOf('.') === -1);
      const subTemplateFields = keys.filter((f) => f.indexOf('.') > -1);

      // we evaluate the main tempalte fields first
      for (let i = 0; i < mainFields.length; i += 1) {
        const field = mainFields[i];
        this.evaluateField(field, null, 0);
      }

      for (let i = 0; i < subTemplateFields.length; i += 1) {
        const subField = subTemplateFields[i];
        const [upperRef, inputRef] = subField.split('.');
        if (upperRef && this.finalData[upperRef] && this.finalData[upperRef].length > 0) {
          for (let j = 0; j < this.finalData[upperRef].length; j += 1) {
            this.evaluateField(inputRef, upperRef, j);
          }
        }
      }
      //console.log('4. FIN initialEvaluation');
    },
    setUpInitialEvaluatorData() {
      let datakeys = Object.keys(this.finalData);
      datakeys = datakeys.filter(n => !this.not_evaluate.includes(n))
      for (let i = 0; i < datakeys.length; i += 1) {
        const field = datakeys[i];
        const formula = (Array.isArray(this.finalData[field]))
          // ? `${field} = ${JSON.stringify(this.finalData[field])}`
          ? `${field} = ${JSON.stringify(this.quitNoEvaluate(field))}` //Enrique
          : `${field} = '${this.finalData[field]}'`;
        this.evaluator.instance.evaluate(formula);
      }
    },
    dataBuilder({
      reference, upperRef, data, index, fromReset = false,
    }) {

      if (index === null) {
        this.finalData[reference] = data;
      } else {
        this.finalData[upperRef][index][reference] = data;
      }
      if (!fromReset) this.setManuallyEdited(reference, upperRef, index);
      this.hasToEvaluate(reference, upperRef, index);
      // this.evaluator.instance.sum('listado.box_weight');

      const currentLayout = this.parsedTemplate.detail[0].children.find((l) => l.ref === reference);
      if (currentLayout && currentLayout.dataType === 'select') {
        // Si es de tipo select, obtenemos el item seleccionado
        // para meter estos datos en el evaluador
        const externalDataAdd = currentLayout.items.find((x) => parseInt(x.id, 10)
        === parseInt(data, 10));
        if (externalDataAdd) {
          this.evaluator.instance.evaluate(`show_${reference} = ${JSON.stringify(externalDataAdd)}`);
        }
      }
      this.$emit('form-data', { data: this.finalData, extraData: this.extraData });

    },
    setManuallyEdited(reference, upperRef, index) {
      if (upperRef !== undefined) {
        if (this.extraData[upperRef] === undefined) {
          this.extraData[upperRef] = {};
          this.extraData[upperRef][index] = {};
        }
        if (this.extraData[upperRef][index] && !(reference in this.extraData[upperRef][index])) {
          this.extraData[upperRef][index][reference] = {};
        }
        if(this.extraData[upperRef][index]) {
          this.extraData[upperRef][index][reference].manuallyEdited = true;
        }
      } else {
        if (!(reference in this.extraData)) {
          this.extraData[reference] = {};
        }
        this.extraData[reference].manuallyEdited = true;
      }
    },
    async resetManualEdit({ reference, upperRef, index }) {
      let ref = '';
      let value = null;
      if (upperRef == null) {
        this.extraData[reference].manuallyEdited = false;
        this.finalData[reference] = await this
          .resetFinalDataWithExternalData(reference, upperRef, index);
        ref = reference;
        value = this.finalData[reference];
      } else {
        this.extraData[upperRef][index][reference].manuallyEdited = false;
        this.finalData[upperRef][index][reference] = await this
          .resetFinalDataWithExternalData(reference, upperRef, index);
        ref = `${upperRef}.${reference}`;
        value = this.finalData[upperRef][index][reference];
      }
      // if reset calculated value (not service): emitResult
      if (value === 'reset-calculated') {
        const NOT_MANUALLY_EDITED = true;
        if (this.$refs && this.$refs[ref]) {
          this.$refs[ref][index || 0].emitResult(value, NOT_MANUALLY_EDITED);
        }
      }
      // TODO: optimize so we only upload one input, not all of them
      this.visualizerKey += 1;
    },
    async resetFinalDataWithExternalData(reference, upperRef, index) {
      const keyToSearch = 'ref';
      const valueToFind = reference;
      const childrenKey = 'children';
      const parents = this.template.detail;
      const inputData = findObjectInNestedArray(
        valueToFind,
        keyToSearch,
        childrenKey,
        parents,
      );
      if (inputData && inputData.options && inputData.options.formula) {
        if (inputData.options.formula.split('.')[0] === 'show_externalData') {
          const externalDataKey = inputData.options.formula.split('.').pop();
          if (externalDataKey) return this.templateExternalData[externalDataKey];
        } else {
          await this.evaluateField(reference, upperRef, index);
          return 'reset-calculated';
        }
      }
      return null;
    },
    unlinkImageEvent({
      file,
      inputId,
      upperRef,
      templateIndex,
      reference,
    }) {
      this.$emit('form-data', { data: this.finalData, extraData: this.extraData });
    },
    parsedLyoutName(type) {
      if (type === 'LAYOUT_VERTICAL' || type === 'LAYOUT_HORIZONTAL' || type === 'GRID') {
        return 'LAYOUT';
      }
      if (type === 'LIST_TEMPLATE') {
        return 'LIST_TEMPLATE';
      }
      return 'GROUP';
    },
    isVertical(itemType) {
      if (itemType === 'LAYOUT_VERTICAL') {
        return true;
      } return false;
    },
    isHorizontal(itemType) {
      if (itemType === 'LAYOUT_HORIZONTAL') {
        return true;
      } return false;
    },
    isGrid(itemType) {
      if (itemType === 'GRID') {
        return true;
      } return false;
    },
    fileEvent({
      files, reference, inputId, maxSize, allowedFormats, index, upperRef,
    }) {
      const allowedSize = maxSize * 1024 ** 2;
      const BreakException = {};
      try {
        Array.from(files).forEach((file) => {
          if (!(inputId in this.filesToUpload)) {
            this.filesToUpload[inputId] = { size: 0, files: [] };
          }
          if (file.size + this.filesToUpload[inputId].size > allowedSize) {
            this.$f7.dialog.create({
              title: 'Error',
              text: 'Se ha excedido el tamaño máximo permitido',
              buttons: [
                {
                  text: 'Aceptar',
                },
              ],
            }).open();
            throw BreakException;
          } else {
            let ext = file.name.split('.');
            ext = ext.reverse();
            [ext] = ext;
            if (!allowedFormats) {
              allowedFormats = 'jpg csv png pdf CSV PNG PDF';
            }
            if (allowedFormats.includes(ext)) {
              this.filesToUpload[inputId].size = file.size + this.filesToUpload[inputId].size;
              this.filesToUpload[inputId].files.push(file);
              if (index !== null) {
                this.finalData[upperRef][index][reference].push(file);
                if (this.finalData[upperRef][index][reference] === 'empty') {
                  this.finalData[upperRef][index][reference] = [];
                  this.finalData[upperRef][index][reference].push(file);
                } else {
                  this.finalData[upperRef][index][reference].push(file);
                }
              } else if (this.finalData[reference] === 'empty') {
                this.finalData[reference] = [];
                this.finalData[reference].push(file);
              } else {
                //
                if(this.finalData[reference]) {
                  this.finalData[reference].push(file);
                } else {
                  this.finalData[reference] = [];
                  this.finalData[reference].push(file);
                }

              }
            } else {
              this.$f7.dialog.create({
                title: 'Archivo no válido',
                text: `El archivo <b>${file.name}</b> no se ha podido subir correctamente debido a que su extensión no es valida.<br><small style="font-style: italic;">(Extensiones permitidas: ${allowedFormats})</small>`,
                buttons: [
                  {
                    text: 'Aceptar',
                  },
                ],
              }).open();
            }
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      this.$emit('form-data', { data: this.finalData, extraData: this.extraData });
    },
    unlinkFileEvent({
      sizeOperator, index, inputId, upperRef, reference, templateIndex,
    }) {
      const currentSize = this.filesToUpload[inputId].size;
      this.filesToUpload[inputId].files.splice(index, 1);
      if (upperRef !== undefined) {
        this.finalData[upperRef][templateIndex][reference].splice(index, 1);
      } else {
        this.finalData[reference].splice(index, 1);
      }
      this.filesToUpload[inputId].size = currentSize - sizeOperator;
    },
    galleryEvent({
      file,
      inputId,
      upperRef,
      templateIndex,
      reference,
    }) {
      if (!(inputId in this.images)) {
        this.images[inputId] = { files: [] };
      }
      this.images[inputId].files.push(file);
      if (templateIndex !== null) {
        if (!Array.isArray(this.finalData[upperRef][templateIndex][reference])) {
          this.finalData[upperRef][templateIndex][reference] = [];
        }
        if (this.finalData[upperRef][templateIndex][reference] === 'empty') {
          this.finalData[upperRef][templateIndex][reference] = [];
          this.finalData[upperRef][templateIndex][reference].push(file);
        } else {
          this.finalData[upperRef][templateIndex][reference].push(file);
        }
      } else if (typeof this.finalData[reference] === 'undefined' || !Array.isArray(this.finalData[reference])) {
        this.finalData[reference] = [];
        this.finalData[reference].push(file);
      } else {
        this.finalData[reference].push(file);
      }
      this.$emit('form-data', { data: this.finalData, extraData: this.extraData });
    },
    viewType(type) {
      return type;
    },
    getSubTemplateIndexes(subTemplate) {
      const indexes = this.finalData[subTemplate].map((a, i) => i);
      return indexes;
    },

    async executeService(idService, finalRef = null, index = 0) {
      if (this.executeServices) {
        let executeService = true;
        const data = { id_service: idService };
        const service = this.servicesParams.find((s) => s.id === idService);
        data.params = [];

        if (service != null) {
          service.parameters.forEach((param) => {
            const paramData = {};
            const paramValue = this.getServiceParamsByNameOrDefault(
              param.name,
              param.default_value.toString(),
              finalRef,
              index,
            );

            if (param.required && (paramValue === '' || paramValue === [])) {
              console.error(`param required for service ${idService}: `, param.name);
              executeService = false;
              return;
            }
            paramData[param.name] = paramValue;
            data.params.push(paramData);
          });
        }


        data.params = JSON.stringify(data.params);
        if (executeService) {
          return await this.executeServiceAPI(data);
        }
      }
    },

    getServiceParamsByNameOrDefault(paramName, defaultValue, finalRef = null, index = 0) {
      if (finalRef === null || finalRef.indexOf('.') === -1) {
        if (this.finalData[paramName]) {
          return this.finalData[paramName].toString();
        }

        if (this.templateExternalData === undefined || this.templateExternalData === null) {
          return defaultValue;
        }

        if (this.templateExternalData[paramName]) {
          return this.templateExternalData[paramName].toString();
        }
      } else {
        let upperRef = null;
        let itemRef = null;
        [upperRef, itemRef] = finalRef.split('.');

        if (this.finalData[upperRef] && this.finalData[upperRef][index] && this.finalData[upperRef][index][paramName]) {
          return this.finalData[upperRef][index][paramName];
        }
        if (this.finalData[paramName]) return this.finalData[paramName];
      }

      return defaultValue;
    },

    async executeServiceAPI(data) {
      const url = `${this.serviceUrl}/api/execute_service/`;
      this.addServiceRunning(data.id_service);
      return axios
        .post(url, data, { headers: { Authorization: `Bearer ${this.serviceToken}` } })
        .then((response) => response.data.rdo)
        .catch((error) => {
          this.$f7.notification.create({ text: 'No se pudieron ejecutar algunos servicios', closeTimeout: 5000, closeButton: true }).open();
          if (error.response !== undefined && error.response.status === 401) {
            this.$emit('token-expired');
          }
        }).finally(() => {
          this.removeServiceRunning(data.id_service);
        });
    },
    // for preloader management, we only show preloader when its the only service running
    // meaning there is no preloader shown yet
    addServiceRunning(id) {
      if (this.servicesRunning.length === 0) {
        this.$f7.preloader.show();
      }

      this.servicesRunning.push(id);
    },
    // for prelaoder management, we only hide preloader when all services are done
    removeServiceRunning(id) {
      this.servicesRunning.splice(this.servicesRunning.indexOf(id), 1);
      if (this.servicesRunning.length === 0) {
        this.$f7.preloader.hide();
      }
    },

    getServicesParams(ids) {
      const url = `${this.serviceUrl}/api/list_params_by_services/`;
      const payload = { ids };
      return axios
        .post(url, payload, { headers: { Authorization: `Bearer ${this.serviceToken}` } })
        .then((response) => response.data).catch((error) => {
          this.$f7.notification.create({ text: 'No se obtuvieron los parámetros para los servicios', closeTimeout: 5000, closeButton: true }).open();
          if (error.response !== undefined && error.response.status === 401) {
            this.$emit('token-expired');
          }
          console.error(error);
        });
    },
    discardUnnecessaryParams() {
      if (this.servicesParams === undefined) return;
      this.servicesParams.forEach((service) => {
        service.parameters = service.parameters.filter((p) => p.inparam);
      });
      this.servicesParams = this.servicesParams.filter((s) => s.parameters.length > 0);
    },
    isImage(ref) {
      this.parsedTemplate.detail.foreach()
    }
  }
};
</script>

<style lang="scss">
.main-newtemplate {
  .newTemplateHide {
    display: none;
  }
}
.main-formsVisualizer {
  .vertical {
    display: block;
  }
  .horizontal {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(70px, auto);
    .list .item-inner{
      padding-top: 0;
      padding-bottom: 0;
    }
    padding-bottom: var(--f7-typography-padding) !important;
  }
}
.main-formsVisualizer .list{
  .main-formscontainer{
    ul > li.accordion-item-opened:last-child{
      padding-bottom: var(--f7-typography-padding) !important;
    }
  }
}
</style>

