import db from '../services/Database';
import Api from '../services/Api';
import * as Config from '../../js/constants';

export default {
  async getToken() {
    const data = await db.token.toArray();
    if (data && data.length) {
      return data[0];
    } else {
      return null;
    }
  },
  async setToken(token) {
    await db.token.add(token);
  },
  async deleteToken() {
    await db.token.clear();
  },
  async refreshToken() {
    await db.token.clear();
    await db.token.add(token);
  },
  async login(user, password) {
    try {
      const token = await Api.login({
        username: user,
        password: password,
        appVersion: Config.APP_VERSION,
        type: Config.APP_TYPE,
      });
      await db.token.clear();
      if (token) await db.token.add(token);
      return token;
    } catch (error) {
      console.error('Login error:', error);
      await db.token.clear();
      return null;
    }
  },
  async autoLoginWeb(urlToken) {
    try {
      const token = await Api.autoLoginWeb({
        token: urlToken,
      });
      await db.token.clear();
      if (token) await db.token.add(token);
      return token;
    } catch (error) {
      console.error('Login error:', error);
      await db.token.clear();
      return null;
    }
  },
  async hasNetworkConnexion(credentials) {
    return await Api.hasNetworkConnexion(credentials);
  },
  async getResumeData() {
    try {
      const data = await Api.updateDataLogin();
      return data
    } catch (error) {
      return null
    }
  }
};