<template>
  <f7-list-item>
    <span>{{ label }}</span>
    <f7-toggle
      :checked="isChecked"
      :disabled="readonly"
      @change="emitNewInputState($event.target.checked)"
    />
  </f7-list-item>
</template>

<script>
export default {
  name: 'HiFormInputToggle',
  props: {
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  created() {
    this.isChecked = this.checked;
  },
  methods: {
    emitNewInputState(newInputState) {
      this.isChecked = newInputState;
      this.$emit('new-input-state', newInputState);
    },
  },

};
</script>

<style lang="scss" scoped>

</style>
