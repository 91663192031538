<template>
  <div class="main-take-picture">
    <f7-block class="content-select-picture">
      <small> Seleccione máximo {{ maxFiles }} archivo(s).</small>
      <div
        v-if="imageSrc.length > 0"
        class="content-select-picture__gallery margin"
      >
        <div
          class="display-flex flex-direction-row"
          style="flex-wrap: wrap;"
        >
          <div
            v-for="(element, index) in imageSrc"
            :key="index"
            class="padding-horizontal-half"
          >
            <img
              :src="element.url"
              class="lazy lazy-fade-in demo-lazy photo-scouting"
              alt="element.url"
              @click="openSheetOptions(index)"
            >
          </div>
        </div>
      </div>
      <f7-photo-browser
        ref="fullscreenPopup"
        :photos="imageSrc"
        theme="dark"
        type="popup"
        :routable-modals="false"
      />

      <!-- Take Picture -->
      <div
        class="content-select-picture__add-img padding-vertical-half margin-horizontal margin-top"
        @click="openSheet()"
      >
        <img
          alt="cameraAddIcon"
          :src="cameraAddIcon"
        >
      </div>
    </f7-block>
    <!-- Input File  selector from Web navigator hidden-->
    <input
      ref="fileInput"
      type="file"
      accept=".jpg"
      hidden
      @input="onSelectFileBrowser"
    >
    <!-- Action Sheet -->
    <f7-sheet
      class="demo-sheet"
      :opened="sheetOpened"
      @sheet:closed="sheetOpened = false"
    >
      <f7-page-content class="padding-vertical-half">
        <div class="text-align-center">
          {{ selectOptionLabel }}
        </div>
        <div
          class="display-flex flex-direction-row align-content-center justify-content-space-evenly"
        >
          <div
            v-if="enableCamera && cordovaDevice"
            class="text-align-center"
          >
            <img
              :src="cameraIcon"
              alt="cameraIcon"
              @click="cameraTakePicture(true)"
            >
            <div>{{ cameraLabel }}</div>
          </div>
          <div
            v-if="enableGallery"
            class="text-align-center"
          >
            <img
              :src="galleryIcon"
              alt="galleryIcon"
              @click="cameraTakePicture(false)"
            >
            <div>{{ galleryLabel }}</div>
          </div>
        </div>
      </f7-page-content>
    </f7-sheet>
    <f7-sheet
      class="demo-sheet"
      :opened="openedSheetOptions"
      @sheet:closed="openedSheetOptions = false"
    >
      <f7-page-content class="padding-vertical-half">
        <div class="text-align-center">
          {{ selectOptionLabel }}
        </div>
        <div
          class="display-flex flex-direction-row align-content-center justify-content-space-evenly"
        >
          <div
            class="text-align-center"
          >
            <img
              :src="fullscreenIcon"
              alt="fullscreenIcon"
              @click="fullscreenElement()"
            >
            <div>{{ fullscreenLabel }}</div>
          </div>
          <div
            class="text-align-center"
          >
            <img
              :src="recycleBeanIcon"
              alt="recycleBeanIcon"
              @click="deleteElement()"
            >
            <div>{{ deleteLabel }}</div>
          </div>
        </div>
      </f7-page-content>
    </f7-sheet>
  </div>
</template>

<script>
import { Device } from 'framework7/framework7-lite.esm.bundle';
import cameraIcon from '../../static/img/ic_camera.svg';
import cameraAddIcon from '../../static/img/ic_camera_add.svg';
import galleryIcon from '../../static/img/ic_gallery.svg';
import recycleBeanIcon from '../../static/img/ic_recycleBean.svg';
import fullscreenIcon from '../../static/img/ic_fullscreen.svg';

export default {
  name: 'HiTakePhoto',

  props: {
    // Si se pasa el enableCamera: false, se habilitará el
    // administrador de archivos en lugar de la cámara
    maxFiles: {
      type: Number,
      default: 5,
    },
    enableGallery: {
      type: Boolean,
      default: true,
    },
    enableCamera: {
      type: Boolean,
      default: true,
    },
    storedImages: {
      type: Array,
      default: () => [],
    },
    maxSize: {
      type: Number,
      default: 1024,
    },
    deleteLabel: {
      type: String,
      default: 'Delete',
    },
    fullscreenLabel: {
      type: String,
      default: 'Fullscreen',
    },
    galleryLabel: {
      type: String,
      default: 'Gallery',
    },
    selectOptionLabel: {
      type: String,
      default: 'Select option',
    },
    cameraLabel: {
      type: String,
      default: 'Camera',
    },
  },
  data() {
    return {
      imageSrc: [],
      imageBase64: '',
      sheetOpened: false,
      openedSheetOptions: false,
      selectedImage: 0,
      openedFullscreen: false,
    };
  },
  computed: {
    cameraIcon: () => cameraIcon,
    galleryIcon: () => galleryIcon,
    recycleBeanIcon: () => recycleBeanIcon,
    fullscreenIcon: () => fullscreenIcon,
    cameraAddIcon: () => cameraAddIcon,
    cordovaDevice: () => Device.cordova, // Funciona en un dispositivo o en un navegador.
  },

  async mounted() {
    if (this.storedImages && this.storedImages.length) {
      this.imageSrc = [];
      const promises = [];
      for (const image of this.storedImages) {
        // Check if URL starts with 'data:', which means it's a base64 string
        // if (!image.url.startsWith('data:')) {
        //   // Fetch the binary data from the URL and return a promise
        //   promises.push(
        //     fetch(image.url)
        //       .then(async (response) => ({ buffer: await response.arrayBuffer(), response }))
        //       .then(({ buffer, response }) => {
        //         // Encode the binary data as a base64 string
        //         const base64 = btoa(
        //           new Uint8Array(buffer)
        //             .reduce((data, byte) => data + String.fromCharCode(byte), ''),
        //         );
        //         const base64Url = `data:${response.headers.get('Content-Type')};base64,${base64}`;
        //         image.url = base64Url;
        //       }),
        //   );
        // }
        this.imageSrc.push(image);
      }

      // Wait for all fetch operations to finish before continuing
      await Promise.all(promises);
    }
  },
  methods: {
    // Al hacer click en una imagen, esta se podrá eliminar
    openSheetOptions(index) {
      this.openedSheetOptions = true;
      this.selectedImage = index;
    },
    deleteElement() {
      this.imageSrc.splice(this.selectedImage, 1);
      this.$emit('images-removed', this.selectedImage);
      this.openedSheetOptions = false;
    },
    fullscreenElement() {
      this.$refs.fullscreenPopup.open(this.selectedImage);
      this.openedSheetOptions = false;
    },
    setOptions(srcType) {
      return {
        quality: 100, // % of quality to save
        destinationType: Camera.DestinationType.DATA_URL, // DATA_URL (base 64), FILE_URI (android), NATIVE_URI (iOS)
        sourceType: srcType,
        encodingType: 0, // JPEG, PNG
        mediaType: 0, // PICTURE, VIDEO, ALLMEDIA (Picture and Video)
        allowEdit: false, // Allow to edit before save picture
        correctOrientation: true, // Corrects Android orientation quirks
        targetHeight: 1920, // Se usa para redimensionar la imagen (valor en px)
        targetWidth: 1080, // Se usa para redimensionar la imagen (valor en px)
      };
    },

    cameraTakePicture(getFromGalery) {
      this.sheetOpened = false;
      if (!this.cordovaDevice) {
        // Gallery
        this.browserGetPicture();
        return;
      }
      let srcType;
      // Options: PHOTOLIBRARY / CAMERA / SAVEDPHOTOALBUM
      if (getFromGalery) {
        srcType = 1;
      } else {
        srcType = 0;
      }
      const options = this.setOptions(srcType);
      if (this.imageSrc.length < this.maxFiles) {
        navigator.camera.getPicture((base64Img) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const img = new Image();
          img.src = `data:image/jpeg;base64,${base64Img}`;
          img.onload = () => {
            const { width } = img;
            const { height } = img;
            canvas.width = this.maxSize && (this.maxSize < width) ? this.maxSize : width;
            canvas.height = (height / width) * canvas.width;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const resizedImage = canvas.toDataURL('image/jpeg');

            const filename = `${Date.now().toString()}.jpg`;
            const image = {
              name: filename,
              url: resizedImage,
            };

            this.imageSrc.push(image);
            this.emitNewImage(image);
          };
        }, (error) => {
          console.debug(`Unable to obtain picture: ${error}`, 'app');
        }, options);
      } else {
        this.$f7.dialog.alert('Too many pictures');
      }
    },
    browserGetPicture() {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      this.$refs.fileInput.dispatchEvent(event);
      this.sheetOpened = false;
    },
    emitNewImage(newimage) {
      this.$emit('images-changed', newimage);
    },
    openSheet() {
      const isCordova = this.cordovaDevice;
      const isCamera = this.enableCamera;
      const isGallery = this.enableGallery;

      // WEB
      if (!isCordova) {
        // Galery
        this.cameraTakePicture(false);
        return;
      }

      if (isCamera && !isGallery) {
        // Camera
        this.cameraTakePicture(true);
        return;
      }

      if (!isCamera && isGallery) {
        // Gallery
        this.cameraTakePicture(false);
        return;
      }

      this.sheetOpened = true;
    },
    async onSelectFileBrowser() {
      const self = this;
      const input = this.$refs.fileInput;
      const { files } = input;

      if (files && files[0]) {
        if (this.imageSrc.length >= this.maxFiles) {
          console.error('Too many pictures');
          this.$f7.dialog.alert('Too many pictures');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        // File name or date
        const filename = (files[0] || {}).name || `${Date.now()
          .toString()}.jpg`;

        const reduce = await this.reduceImage(files[0], this.maxSize);
        const img = {
          name: filename,
          url: reduce.src,
        };
        self.imageSrc.push(img);
        self.emitNewImage(img);
      }
    },
    async reduceImage(imageFile, pxWidth) {
      const originalImage = await this.getMeta(URL.createObjectURL(imageFile));
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = pxWidth && (pxWidth < originalImage.width) ? pxWidth : originalImage.width;
      canvas.height = (originalImage.height / originalImage.width) * canvas.width;
      ctx.drawImage(originalImage, 0, 0, canvas.width, canvas.height);
      const thumbnail = new Image();
      thumbnail.src = canvas.toDataURL('image/jpeg');
      return thumbnail;
    },
    async getMeta(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject();
        img.src = url;
      });
    },
  },
};
</script>
<style lang="scss">
.photo-scouting {
  object-fit: cover;
  min-height: 200px;
  border-radius: 3px;
  width: 100%;
}

.main-take-picture {
  .content-select-picture {
    &__title {
      color: var(--hi-light-blue);
      font-size: 14px;
      margin-bottom: 5px;
      padding-left: 5px;
      display: block;
    }

    &__add-img {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--his-lightsnow);
      border: 1px solid var(--his-platinum);
      border-radius: 8px;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
.fullscreen-view{
  display: none;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: black;
}
</style>
