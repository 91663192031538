<template>
  <f7-list-input
    v-model="currentValue"
    class="hi-input-text"
    :type="internalType"
    :inline-label="inline"
    :label="formattedLabel"
    :placeholder="placeholder"
    :required="required"
    :disabled="readonly"
    :resizable="textarea"
    :error-message="currentErrorMessage"
    :error-message-force="isInputInvalid"
    :clear-button="internalShowClearButton"
    @input="emitNewInputValue($event.target.value)"
    @change="emitBlurEvent($event.target.value)"
  >
    <span
      v-if="isCalculatedValue && isManuallyEditedValue"
      slot="label"
      class="icon-reset"
    >
      <f7-button
        fill
        small
        style="display: inline; margin-left: 10px"
        @click="$emit('reset-manual-edit')"
      >
        Reset
      </f7-button>
    </span>
    <f7-segmented
      v-if="isAnyReaderRequired"
      slot="content"
      class="hi-input-text__segmented"
      raised
      tag="div"
    >
      <f7-button
        v-if="showQRReader"
        class="hi-input-text__button"
        fill
        icon-f7="qrcode"
        @click="emitOpenQRReader"
      />
      <f7-button
        v-if="showBarcodeReader"
        class="hi-input-text__button"
        fill
        icon-f7="barcode"
        @click="emitOpenBarcodeReader"
      />
    </f7-segmented>
  </f7-list-input>
</template>

<script>
export default {
  name: 'HiFormInputText',
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    isCalculatedValue: {
      type: Boolean,
      default: false,
    },
    isManuallyEditedValue: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    showClearButton: {
      type: Boolean,
      default: true,
    },
    showQRReader: {
      type: Boolean,
      default: false,
    },
    showBarcodeReader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: null,
      currentMaxLength: 0,
      currentErrorMessage: '',
      defaultMaxLength: 150,
      qrCodeMaxCharacters: 7089,
      textareaMaxLength: 1000,
    };
  },
  computed: {
    internalType() {
      if (this.textarea) {
        return 'textarea';
      }
      return this.password ? 'password' : 'text';
    },
    internalShowClearButton() {
      return this.inline || this.readonly ? false : this.showClearButton;
    },
    formattedLabel() {
      return this.required ? `${this.label}*` : this.label;
    },
    formattedMaxLengthErrorMessage() {
      return `Excede los ${this.currentMaxLength} caracteres.`;
    },
    formattedRequiredErrorMessage() {
      return `El campo ${this.label} es requerido.`;
    },
    isInputInvalid() {
      // Not required + valid input value
      if (!this.required && this.isNotRequiredInputValueValid) {
        return false;
      }
      // Not required + invalid input value
      if (!this.required && !this.isNotRequiredInputValueValid) {
        this.defineMaxLenghtErrorMessage();
        return true;
      }
      // Required + valid input value
      if (this.required && this.isRequiredInputValueValid) {
        return false;
      }
      // Required + invalid input value
      if (this.required && !this.isRequiredInputValueValid) {
        if (this.isInputValueDefined) {
          this.defineMaxLenghtErrorMessage();
          return true;
        }
        this.defineRequiredErrorMessage();
        return true;
      }
      return false;
    },
    isPropValueDefined() {
      return this.value ? this.value.length > 0 : false;
    },
    isInputValueDefined() {
      return this.currentValue ? this.currentValue.length > 0 : false;
    },
    doesInputValueNotExceedMaxLength() {
      return this.currentValue ? this.currentValue.length <= this.currentMaxLength : true;
    },
    isAnyReaderRequired() {
      return this.showQRReader || this.showBarcodeReader;
    },
    isRequiredInputValueValid() {
      return !!(this.isInputValueDefined && this.doesInputValueNotExceedMaxLength);
    },
    isNotRequiredInputValueValid() {
      return this.doesInputValueNotExceedMaxLength;
    },
    maxLengthErrorMessage() {
      return `Excede los ${this.currentMaxLength} caracteres.`;
    },
    requiredErrorMessage() {
      return `El campo ${this.label} es requerido.`;
    },
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    },
  },
  created() {
    if (this.isPropValueDefined) {
      this.currentValue = this.value;
    }
    this.defineMaxLenght();
  },
  methods: {
    defineMaxLenght() {
      this.currentMaxLength = this.defaultMaxLength;
      if (this.textarea) {
        this.currentMaxLength = this.textareaMaxLength;
      }
      if (this.isAnyReaderRequired) {
        this.currentMaxLength = this.qrCodeMaxCharacters;
      }
    },
    defineMaxLenghtErrorMessage() {
      this.currentErrorMessage = this.formattedMaxLengthErrorMessage;
    },
    defineRequiredErrorMessage() {
      this.currentErrorMessage = this.formattedRequiredErrorMessage;
    },
    emitNewInputValue(newInputValue) {
      this.currentValue = newInputValue;
      this.$emit('new-input-value', newInputValue);
    },
    emitBlurEvent(finalInputValue) {
      this.$emit('blur', finalInputValue);
    },
    emitOpenBarcodeReader() {
      this.$emit('open-barcode-reader');
    },
    emitOpenQRReader() {
      this.$emit('open-qr-reader');
    },
  },
};
</script>

<style lang="scss" scoped>
.hi-input-text {
  padding-top: calc(var(--f7-typography-padding) / 2);

  &__segmented {
    padding-top: calc(var(--f7-typography-padding) + 0.5rem);
    padding-right: 1rem;
    margin-left: -0.7rem;
  }

  &__button {
    min-width: 3.8rem;
  }
}
</style>
