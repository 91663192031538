<template>
  <f7-page name="detailTemplate">
    <!-- Top Navbar -->
    <navbar
      slot="fixed"
      nav-title="Detalle Formulario"
      back-btn-enable
    />
    <div
      v-if="!getBorradorTemplate"
      class="top-bar padding"
    >
      <div class="top-bar__left">
        <f7-link @click="makeCopy">
          Clonar
        </f7-link>
      </div>
      <div class="top-bar__right">
        <div>
          <f7-button
            v-if="canEdit"
            fill
            @click="saveTemplate"
          >
            Actualizar
          </f7-button>
        </div>
      </div>
    </div>
    <div v-else>
      <f7-block
        strong
        outline-ios
        class="top-bar padding"
      >
        <div class="top-bar__left">
          <f7-button
            v-if="canCreate"
            fill
            color="green"
            @click="saveBorradorTemplate"
          >
            Guardar borrador
          </f7-button>
        </div>
        <div class="top-bar__right">
          <f7-button
            v-if="canCreate"
            fill
            round
            @click="saveTemplate"
          >
            Publicar
          </f7-button>
        </div>
      </f7-block>
    </div>

    <div>
      <hi-forms-builder
        v-if="template"
        :forms-types="confDatatypes"
        :services="servicios"
        :entities="entities"
        :template-in="JSON.parse(template.data)"
        :change-group-type-restrictions="true"
        @form-data-builder="getBuilder"
        @form-forms="getForms"
        @form-result-forms="getResultsForms"
      />
    </div>
  </f7-page>
</template>

<script>
import Navbar from '../components/Navbar.vue';
// import HiFormsBuilder from 'vue-component-library/components/dynamicforms/HiFormsBuilder.vue';
import HiFormsBuilder from '../components/dynamicforms/HiFormsBuilder.vue';
import Api from '../js/services/Api';
import { mapState, mapGetters } from 'vuex';
import TemplateRepository from '../js/repositories/TemplateRepository';
import AllServicesRepository from '../js/repositories/AllServicesRepository';
import Evaluator from '../js/utilities/evaluator';

export default {
  name: 'DetailTemplate',
  components: { Navbar, HiFormsBuilder },
  props: {
    isFromUrlToken: { type: String, default: '' }
  },
  data() {
    return {
      template: null,
      canEdit: false,
      canCreate: false,
      canDelete: false,
      templateResponse: null,
      servicios: [],
      entities: [],
      forms: [],
      resultsForms: [],
      evaluator: {
        triggerFields: [],
        calcFields: {},
        mathParser: null,
        instance: null,
      }
    };
  },
  computed: {
    ...mapState([
      'currentTemplate',
      'confDatatypes',
      'token',
      'currentRol',
    ]),
    ...mapGetters(['getBorradorTemplate'])
  },
  async created() {
    this.template = await TemplateRepository.getById(this.currentTemplate);

    const serviciosTemp = await AllServicesRepository.getByCategory('INPUT');
    serviciosTemp.forEach(element => {
        const item = {
          id: element.id,
          value: element.name
        }
        this.servicios.push(item);
    });

    const entitiesTemp = await AllServicesRepository.getByCategory('FORM');
    entitiesTemp.forEach(element => {
        const item = {
          id: element.id,
          value: element.name
        }
        this.entities.push(item);
    });


    const evaluatorInstance = new Evaluator();
    this.evaluator.instance = evaluatorInstance;

  },
  mounted() {
    this.canEdit = this.token.data.edit;
    this.canCreate = this.token.data.create;
    this.canDelete = this.token.data.delete;
  },
  methods: {
    getForms({ data}) {
      this.forms = data;
    },
    getResultsForms({ data }) {
      this.resultsForms = data;
    },
    getBuilder({ data }) {
      this.templateResponse = data;
    },
    async makeCopy() {

      this.$f7.dialog.confirm('¿Seguro que desea copiar la plantilla?', async () => {

        this.$f7.toast.create({
          text:'Realizando copia de la plantilla...',
        }).open();

        let templateCopy = await TemplateRepository.getById(this.currentTemplate);
        const data = {
            code_external: '-1',
            name: '[Copia de] ' + templateCopy.name,
            description: templateCopy.description,
            company: templateCopy.company,
            data: templateCopy.data,
            rol: templateCopy.rol,
            types: templateCopy.types,
            type: 0,
            entity: templateCopy.entity
        }
        const response = await Api.saveTemplate(data);
        this.$f7.toast.close();
        if (response.id && response.id > 0) {
          this.$f7.toast.create({
            text:'Copia realizada correctamente',
            closeTimeout: 4000,
          }).open();
          await TemplateRepository.add(response);
          this.$f7.views.main.router.navigate('/home/');
        } else {
          alert('Error');
        }

      });
    },
    checkSave() {
      let message = '';
      // Control. No se pueden crear grupos si no tiene conceptos. Despues falla en la sincronizacion.
      const groups = this.resultsForms.filter((e) => e.ref && e.ref.includes('grupo'));
      let groupsCompleted = true;
      groups.forEach((element) => {
        const childrens = this.resultsForms.filter((e) => e.refGroup && e.refGroup === element.ref);
        if(childrens.length === 0) {
          groupsCompleted = false;
        }
      });
      if(!groupsCompleted) {
        return 'Hay grupos sin conceptos. Complételo o elimínelo';
      }

      // Comprobación de campos obligatorios no establecidos
      this.forms.forEach((element, index) => {
        const requireds = element.detail[0].children.filter((e) => e.options.required === true && e.dataType === 'text')
        requireds.forEach((e1) => {
          if(this.resultsForms[index]['' + e1.ref + ''] === '') {
            if(this.resultsForms[index].ref  !== undefined) {
              message = message + 'El campo "' + e1.ref + '" no tiene valor en #' + this.resultsForms[index].ref + '<br>'
            } else {
              message = message + 'El campo "' + e1.ref + '" no tiene valor en #form<br>'
            }
          }
        });
      });

      // Comprobacion de campos marcados como calculados que no tienen formula ni condiciones de visibilidad
      const requiredCalculated = this.resultsForms.filter((e) => e.calculated === true  && e.formula === '' && e.visibilidad === '');
      requiredCalculated.forEach((e) => {
        message = message + 'El concepto "' + e.ref + '" está marcado como calculado pero sin fórmula y sin condiciones de visibilidad ' + '<br>';
      });

      const requiredCalculated1 = this.resultsForms.filter((e) => e.calculated === true && (e.formula !== '' || e.visibilidad !== ''));
      requiredCalculated1.forEach(async(e, index) => {

        // Ejecutamos las formulas con los valores por defecto
        let datas = {};
        let subtemplate = false;
        let upperRef = '';
        let itmobj = {}
        const relatedFieldsTmp = e.relatedFields.split(',')
        for(let i=0; i<relatedFieldsTmp.length; i++ ) {
          if(relatedFieldsTmp[i] && !relatedFieldsTmp[i].includes('show_externalData')) {
            const reftmp = relatedFieldsTmp[i]
            let itm = null;
            if(reftmp.includes('.')) {
              subtemplate = true;
              const gritem = reftmp.split('.');
              upperRef = gritem[0]
              itm = this.resultsForms.find((e) => e.ref === gritem[1] && e.upperRef === gritem[0])
              if(!itm) {
                message = message + 'El concepto "' + reftmp + '" no existe en el formulario ' + '<br>';
              } else {
                if(typeof itm.defaultValue === 'undefined') {
                  datas[gritem[0]][gritem[1]] = false
                } else if(itm.defaultValue === null) {
                  datas[gritem[0]][gritem[1]] = 0;
                }
                else {
                  if(!datas[gritem[0]]  || datas[gritem[0]].length === 0) {
                    datas[gritem[0]] = [];
                  }
                  itmobj[gritem[1]] = itm.defaultValue
                }
              }
            } else {
              itm = this.resultsForms.find((e) => e.ref === reftmp)
              if(!itm) {
                message = message + 'El concepto "' + reftmp + '" no existe en el formulario ' + '<br>';
              } else {
                if(typeof itm.defaultValue === 'undefined') {
                  datas[''+reftmp+''] = false
                } else if(itm.defaultValue === null) {
                  datas[''+reftmp+''] = 0
                }
                else {
                  datas[''+reftmp+''] = itm.defaultValue
                }
              }
            }
          }
        }
        datas[upperRef].push( itmobj )
        this.evaluator.calcFields[e.ref] = {
          ...e.calcOptions
        };
        this.evaluator.instance.updateScope(datas);
        let result = '';
        if(subtemplate) {
          this.evaluator.instance.updateData(datas);
          if(e.formula) {
            result = this.evaluator.instance.evaluateInSubTemplate(e.formula,upperRef, 0)
          } else if(e.visibilidad) {
            result = this.evaluator.instance.evaluateInSubTemplate(e.visibilidad, upperRef, 0)
          }
        } else {
          if(e.formula) {
            result = this.evaluator.instance.evaluate(e.formula)
          } else if(e.visibilidad) {
            result = this.evaluator.instance.evaluate(e.visibilidad)
          }
        }
        if(result === null) {
          message = message + 'El concepto "' + e.ref + '" está marcado como calculado pero la fórmula o la condición de visibilidad no es correcta ' + '<br>';
        }

      });
      return message;
    },
    async saveBorradorTemplate() {
      const message = this.checkSave();
      if(this.templateResponse && !message) {
        // cuando hay templateResponse es porque se ha editado el formulario
        this.removeIncorrectListTemplateChildren();
        // let data = {
        //   id: this.currentTemplate,
        //   code_external: '-1',
        //   name: this.templateResponse.name,
        //   description: this.templateResponse.description,
        //   company: this.token.data.company,
        //   data: JSON.stringify(this.templateResponse),
        //   rol: this.currentRol,
        //   types: ['001'],
        //   type: 0
        // };
        let data = TemplateRepository.getById(this.template.id);
        data.data = JSON.stringify(this.templateResponse);
        if (this.templateResponse.entity) {
          data.entity = parseInt(this.templateResponse.entity);
        }
        await TemplateRepository.updateById(this.template.id, data);
        localStorage.setItem('borrador_'+this.template.id, this.template.id);
        this.$f7.toast.create({
          text:'Borrador guardado correctamente',
          closeTimeout: 2000,
        }).open();
      } else {
        if(message) {
          this.$f7.dialog.alert(message)
        } else {
          //Se ha abierto el formulario pero no se ha editado
          if (this.isFromUrlToken === 'true') {
            this.$f7.views.main.router.navigate('/auto-login/coming-back');
          } else {
            this.$f7.views.main.router.navigate('/home/');
          }
        }
      }
    },
    async saveTemplate() {
      this.$f7.dialog.preloader('Validando y guardando el formulario...');
      const message = this.checkSave();
      if(this.templateResponse && !message) {
        // cuando hay templateResponse es porque se ha editado el formulario
        this.removeIncorrectListTemplateChildren();
        let data = {};
        if(this.currentTemplate < 0) {
          data = {
            code_external: '-1',
            name: this.templateResponse.name,
            description: this.templateResponse.description,
            company: this.token.data.company,
            data: JSON.stringify(this.templateResponse),
            rol: this.currentRol,
            types: ['001'],
            type: 0
          };
        }else {
          data = {
            id: this.currentTemplate,
            code_external: '-1',
            name: this.templateResponse.name,
            description: this.templateResponse.description,
            company: this.token.data.company,
            data: JSON.stringify(this.templateResponse),
            rol: this.currentRol,
            types: ['001'],
            type: 0
          };
        }
        if (this.templateResponse.entity) {
          data.entity = parseInt(this.templateResponse.entity);
        }
        const response = await Api.saveTemplate(data)
        if(this.currentTemplate < 0) {
          await TemplateRepository.add(response);
          // Se elimina el borrador
          await TemplateRepository.deleteById(this.currentTemplate);
          localStorage.removeItem('borrador_'+this.currentTemplate);
          this.$f7.dialog.close();
          if (this.isFromUrlToken === 'true') {
            this.$f7.views.main.router.navigate('/auto-login/coming-back');
          } else {
            this.$f7.views.main.router.navigate('/home/');
          }
        } else {
          if (response.id && response.id > 0) {
            await TemplateRepository.updateById(response.id, response);
            localStorage.removeItem('borrador_'+this.currentTemplate);
            this.$f7.dialog.close();
            if (this.isFromUrlToken === 'true') {
              this.$f7.views.main.router.navigate('/auto-login/coming-back');
            } else {
              this.$f7.views.main.router.navigate('/home/');
            }
          } else {
            this.$f7.dialog.close();
            this.$f7.dialog.alert('El formulario no se ha podido guardar en el servidor: Error: ' + response.response.status + ' - ' + JSON.stringify(response.response.data));
          }
        }
      } else {
        if(message) {
          this.$f7.dialog.close();
          this.$f7.dialog.alert(message)
        } else {
          this.$f7.dialog.close();
          //Se ha abierto el formulario pero no se ha editado
          if (this.isFromUrlToken === 'true') {
            this.$f7.views.main.router.navigate('/auto-login/coming-back');
          } else {
            this.$f7.views.main.router.navigate('/home/');
          }
        }
      }
    },
    removeIncorrectListTemplateChildren() {
      for (let i = 0; i < this.templateResponse?.detail?.length; i += 1) {
        const detail = this.templateResponse?.detail[i];
        const isAListTemplate = detail?.type === 'LIST_TEMPLATE';
        // Remove all items with a dataType !== groupLayout
        if (isAListTemplate) {
          let lastListTemplateChild = detail?.children[detail?.children?.length - 1]
          if (!lastListTemplateChild) return;
          const correctChildren = detail?.children?.filter((groupLayout) => {
            return groupLayout.dataType === 'groupLayout'
          })
          if (correctChildren?.length) {
            this.templateResponse.detail[i].children = correctChildren;
            const newDetail = this.templateResponse.detail[i]
            const children = newDetail?.children[newDetail?.children?.length -1]
            if (!children) return;
            const badElementIndex = children?.children?.findIndex((ele) => ele?.ref === lastListTemplateChild?.ref)
            if (badElementIndex >= 0) {
              lastListTemplateChild.upperRef = this.templateResponse.detail[i].children[this.templateResponse.detail[i].children.length - 1].children[badElementIndex].refGroup
              this.templateResponse.detail[i].children[this.templateResponse.detail[i].children.length - 1].children[badElementIndex] = lastListTemplateChild;
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D8DFEF;
  // width: 100%;
  // height: 60px;
  // z-index: 601;
  // background-color: #FFFFFF;
}
.main-hiformsbuilder{
  .group-buttons__container{
    img{
      width: 32px;
      height: 32px;
      &:last-child{
        margin-top: 5px;
      }
    }
  }
  .content-details, .main-hiformsbuilder{
    height: calc(100vh - 116px);
  }
}
.ios .item-input-error-message, .ios .item-input-info, .ios .input-error-message, .ios .input-info{
  margin-top: 0;
}
/*.content-formsbuilder{
  overflow: hidden;
  height: calc(100vh - 116px);
  margin-top: 60px;
  .content-builder{
    height: calc(100vh - 116px);
    min-height: unset;
    overflow: hidden;
    >div>.block>.row>.col{
      padding: 15px 0;
      max-height: unset!important;
      height: calc(100vh - 116px);
      overflow-y: scroll;
    }
  }
}*/
.main-detailtemplate{
  .top-bar-new{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D8DFEF;
  }
}
</style>

