<template>
  <div class="main-checkbox-row">
    <f7-list>
      <f7-list-item
        class="content-checkbox"
      >
        <f7-checkbox
          :checked="false"
          class="no-ripple"
          @change="changeState(isCheck)"
        >
          <div class="item-title margin-left-half">
            {{ title }}
          </div>
        </f7-checkbox>
        <img
          v-if="afterIcon === 'dotsMenu'"
          :src="dotsMenu"
          alt="dots"
          @click="afterFunction(afterParam)"
        >
        <img
          v-if="afterIcon === 'greenAdd'"
          :src="greenAdd"
          alt="add"
          @click="afterFunction(afterParam)"
        >
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import icAfterMenu from '../../static/img/ic_afterMenu.svg';
import icGreenAdd from '../../static/img/ic_addGreen.svg';

export default {
  name: 'HiCheckboxRow',
  props: {
    afterFunction: { type: Function, default: () => {} },
    afterParam: { type: null, default: null },
    title: { type: String, default: 'title' },
    afterIcon: { type: String, default: '' },
  },
  data() {
    return {
      isCheck: false,
    };
  },
  computed: {
    dotsMenu() {
      return icAfterMenu;
    },
    greenAdd() {
      return icGreenAdd;
    },
  },
  methods: {
    changeState(currentState) {
      this.isCheck = !currentState;
      this.$emit('check-event', { state: this.isCheck });
    },
  },
};
</script>

<style lang="scss">
.main-checkbox-row {
    .content-checkbox {
      background-color: var(--his-lightsnow);
      border-bottom: 2px solid var(--f7-theme-color-gray-tint);
      &:after {
        z-index: 1;
        position: absolute;
        left: 16px;
        content: '';
        width: 0;
        height: 0;
        border-top: solid 10px var(--his-lightsnow);
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        filter: drop-shadow(0px 2px 0px var(--f7-theme-color-gray-tint));
      }
    }
    .item-content {
      padding-left: 0;
    }
    .checkbox {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      width: 80vw;
    }
    .icon-checkbox,
    .checkbox i {
      background: white;
    }
    .item-title {
        text-transform: capitalize;
        color: var(--his-darkgray);
        font-weight: 600;
        font-size: 14px;
    }
}
.storybook-sorted-accordion, .storybook-action-sorted-accordion {
  .content-checkbox {
    .item-inner {
      display: flex!important;
      flex-direction: row!important;
      justify-content: space-between!important;
      padding: 0 8px 0 0!important;
    }
  }
}
</style>
