<template>
  <div>
    <div
      style="padding:20px;background-color: var(--his-secundary) !important;border-bottom: 1px solid #fff !important;"
      @click="onClick"
    >
      <strong>{{ title }}</strong>
      <slot name="after" />
    </div>

    <div v-show="active">
      <slot>
        {{ content }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemDiv',
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    content: {
      required: false,
      type: Object,
      default: null
    },
    index: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
  },
  beforeDestroy() {

  },
  destroyed() {
  },
  methods: {
    onClick() {
      this.$emit('item:select', this.index)
    },
  }
};
</script>