<template>
  <div class="storybook-sorted-accordion">
    <f7-list
      accordion-list
      :class="customClass"
      :style="customStyles"
    >
      <f7-list-item
        v-for="(option, i) in groupedListOptions"
        :key="i"
        class="sorted-accordion"
        :class="{ 'left-chevron': chevronSide === 'left' }"
        accordion-item
        :title="option.title"
        :accordion-item-opened="isOpened(i)"
        :badge="showBadge ? option.list.length : 0"
      >
        <div
          slot="inner-start"
        >
          <slot
            name="after"
            v-bind="option"
          />
        </div>
        <f7-accordion-content>
          <f7-list media-list>
            <div v-if="showCheckbox">
              <hi-checkbox-row
                :title="checkboxRowTitle + '(' + option.list.length + ')'"
                :after-function="
                  (event) => checkboxAfterCallback(event, option.title)
                "
                @check-event="(state) => checkboxEvent(state, option.title)"
              />
            </div>
            <div v-if="'subtitle' in $scopedSlots">
              <slot
                v-bind="option"
                name="subtitle"
              />
            </div>
            <div
              v-for="(suboption, index) in option.list"
              :key="index"
              accordion-list
              class="content-item-row"
            >
              <slot v-bind="suboption">
                <f7-list>
                  <f7-list-item link="#">
                    {{ suboption.title }}
                  </f7-list-item>
                </f7-list>
              </slot>
            </div>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import HiCheckboxRow from './HiCheckboxRow.vue';

export default {
  name: 'HiSortedAccordion',
  components: { HiCheckboxRow },
  props: {
    title: { type: [String], default: null },
    groupKey: { type: [String, Number], default: null },
    listOptions: { type: Array, default: () => [] },
    openedIndex: { type: Number, default: null },
    primary: { type: String, default: 'var(--his-blue)' },
    secondary: { type: String, default: 'var(--his-darkgray)' },
    fontColor: { type: String, default: 'var(--his-silver)' },
    customClass: { type: String, default: '' },
    badgeTextColor: { type: String, default: 'white' },
    showBadge: { type: Boolean, default: true },
    showCheckbox: { type: Boolean, default: false },
    checkboxRowTitle: { type: String, default: 'titulo' },
    chevronSide: { type: String, default: 'right' },
    closed: { type: Boolean, default: false },
  },
  data() {
    return {
      groupedListOptions: [],
    };
  },
  computed: {
    customStyles() {
      return {
        '--custom-primary': this.primary,
        '--custom-secondary': this.secondary,
        '--custom-font-color': this.fontColor,
        '--custom-badge-text-color': this.badgeTextColor,
      };
    },
  },
  watch: {
    groupKey() {
      this.groupByKey();
    },
    listOptions() {
      this.groupByKey();
    },
  },
  mounted() {
    this.groupByKey();
  },
  methods: {
    isOpened(i) {
      return this.closed ? false : i === this.openedIndex || this.groupedListOptions.length === 1;
    },
    checkboxEvent({ state }, key) {
      this.$emit('check-event', { state, groupKey: this.groupKey, key });
    },
    groupByKey() {
      if (!this.groupKey) {
        this.setDefaultFinalArray();
        return;
      }
      this.setGroupedByKeyArray();
    },

    setGroupedByKeyArray() {
      const finalArray = this.listOptions.reduce((accum, curr) => {
        const currentKey = curr[this.groupKey];
        if (!(currentKey in accum)) {
          accum[currentKey] = this.getListElement(currentKey);
        }

        accum[currentKey].list.push(curr);
        return accum;
      }, {});
      this.groupedListOptions = Object.values(finalArray);
    },

    setDefaultFinalArray() {
      const builtElement = this.getListElement(this.title, this.listOptions);
      this.groupedListOptions = [builtElement];
    },

    getListElement(title, list = []) {
      return { title, list };
    },
    checkboxAfterCallback(event, title) {
      const emitObj = {
        event,
        title,
        groupKey: this.groupKey,
      };
      this.$emit('checkbox-after-callback', emitObj);
    },
  },
};
</script>
<style lang="scss">
.storybook-sorted-accordion {
  .accordion-list {
    --f7-list-item-title-text-color: var(--custom-font-color);
    --f7-badge-bg-color: var(--custom-secondary);
    --f7-badge-text-color: var(--custom-badge-text-color);
    .sorted-accordion {
      > .item-link {
        .item-title {
          padding-left: 10px;
          width: 100%;
        }
        background-color: var(--custom-primary);
      }
      .item-inner {
        flex-direction: row-reverse;
        justify-content: flex-end;
        &::before {
          color: var(--custom-font-color);
        }
      }
      .item-after {
        margin-left: 0;
        padding-left: 0;
      }
      .accordion-item-content {
        .content-item-row {
          .item-inner {
            border-bottom: 1px solid var(--f7-theme-color-gray-tint);
          }
        }
      }
    }
  }
  .left-chevron{
    .item-link{
      .item-content{
        .item-inner{
          .item-title{
            padding-left: 25px;
          }
          .item-after{
            margin-left: 25px;
            margin-right: -10px;
          }
          &::before{
            left: 0;
            right: 0;
            margin-top: -3px!important;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>
