<template>
  <f7-page
    class="main-login"
    login-screen
  >
    <f7-block>
      <!-- Login -->
      <div class="text-align-center">
        <img
          width="220"
          height="auto"
          :src="logoHispatec"
          :alt="appName"
        >
      </div>
      <f7-row class="content-login">
        <f7-col width="100">
          <div class="label-text">
            Acceso
          </div>
        </f7-col>
        <f7-col width="100">
          <f7-list
            id="list-form"
            form
          >
            <f7-list-input
              outline
              label="Usuario"
              item-label
              type="text"
              placeholder="Usuario"
              clear-button
              name="username"
              max="255"
              autocomplete="on"
              required
              :value="username"
              @input="username = $event.target.value"
            />
            <!-- Password -->
            <f7-list-input
              v-if="showPass"
              outline
              label="Contraseña"
              class="margin-top-half"
              item-label
              type="text"
              placeholder="Contraseña"
              clear-button
              name="password"
              max="255"
              autocomplete="current-password"
              required
              :value="password"
              @input="password = $event.target.value"
              @keypress.native.enter="signIn"
            />
            <f7-list-input
              v-else
              outline
              label="Contraseña"
              class="margin-top-half"
              item-label
              type="password"
              placeholder="Contraseña"
              clear-button
              name="password"
              max="255"
              autocomplete="current-password"
              required
              :value="password"
              @input="password = $event.target.value"
              @keypress.native.enter="signIn"
            >
              <template
                #content-end
              >
                <f7-button
                  :icon-f7="iconShow"
                  icon-color="gray"
                  @click="changeShowPassword"
                />
              </template>
            </f7-list-input>
          </f7-list>
        </f7-col>
        <f7-col width="100">
          <f7-list>
            <f7-list-item
              checkbox
              title="Recuérdame"
              name="restore-checkbox"
              :checked="wantToRemember"
              @change="setWantToRemember($event.target.checked)"
            />
          </f7-list>
        </f7-col>
        <f7-col width="100">
          <f7-list>
            <f7-button
              col
              button
              button-large
              button-raised
              fill
              :disabled="btnDisable"
              @click="signIn"
            >
              Entrar
            </f7-button>
          </f7-list>
        </f7-col>
      </f7-row>

      <f7-row class="content-copyright">
        <f7-col width="100">
          <div class="label-text text-align-center content-copyright__label">
            © Copyright
          </div>
          <div class="label-text text-align-center content-copyright__label">
            Hispatec
          </div>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>

import * as Config from '../js/constants';
import { mapActions, mapMutations, mapState } from 'vuex';
import EventBus from '../js/EventBus';
import logoHispatec from '../static/img/logo-hispatec.svg';
import { userAccess } from '../js/lib/shared'

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      appVersion: Config.APP_VERSION,
      appName: Config.APPLICATION_NAME,
      appLogo: Config.APPLICATION_LOGO,
      showPass: false,
      iconShow: 'eye'
    };
  },
  computed: {
    btnDisable() {
      return this.username && !this.wantToRemember && !this.password;
    },
    logoHispatec() {
      return logoHispatec;
    },
    ...mapState(['wantToRemember', 'token']),
  },
  mounted() {
    if (this.wantToRemember) {
      this.username =  localStorage.getItem('username');
      this.password = localStorage.getItem('password');
    } else {
      this.username = '';
      this.password = '';
    }
  },
  methods: {
    async signIn() {
      this.$f7.preloader.show();
      try {
        await userAccess(this.$f7, this.username, this.password);
      } catch (reason) {
        this.$f7.dialog.alert(reason);
      } finally {
        this.setCurrentWorkingDate(new Date());
        this.$f7.preloader.hide();
      }
    },
    changeShowPassword() {
      this.showPass = !this.showPass;
      if(this.showPass) this.iconShow = 'eye_slash';
      else this.iconShow = 'eye';
    },
    ...mapMutations(['setWantToRemember','setLoginUser']),
    ...mapMutations('EntityCacheSearch', ['SET_LIMIT_PREVIEW']),
    ...mapActions(['setCurrentWorkingDate', 'login']),
  },
};
</script>
