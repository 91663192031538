<template>
  <div
    class="main-stepper"
    :class="{
      'main-stepper__small': small
    }"
    :style="customStyles"
  >
    <!-- esto se comenta porque el titulo aparece 2 veces -->
    <!-- <div
      v-if="title !== ''"
      class="item-title item-label margin-top-half"
    >
      {{ title }}
    </div> -->
    <div
      class="content-stepper"
    >
      <div
        class="content-stepper__controls controlDecrease"
        :class="{
          fill: fill,
          outline: !fill,
          roundLeft: round
        }"
        @click="onDecrease"
      >
        <span class="material-icons">remove</span>
      </div>
      <input
        :key="inputKey"
        class="input"
        :class="{
          fill: fill,
          outline: !fill,
        }"
        type="number"
        :value="value"
        @change="onChange($event.target.value)"
      >
      <div
        class="content-stepper__controls controlIncrease"
        :class="{
          fill: fill,
          outline: !fill,
          roundRight: round
        }"
        @click="onIncrease"
      >
        <span class="material-icons">add</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HiStepper',
  props: {
    title: { type: String, default: '' },
    defaultValue: { type: Number, default: 0 },
    defaultStep: { type: Number, default: 1 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 99999 },
    fill: { type: Boolean, default: true },
    round: { type: Boolean, default: true },
    bgColor: { type: String, default: 'var(--his-primary)' },
    fontColor: { type: String, default: '#fff' },
    inputWidth: { type: String, default: '100px' },
    small: { type: Boolean, default: false },
  },
  data() {
    return {
      value: this.defaultValue,
      inputKey: 1,
    };
  },
  computed: {
    customStyles() {
      return {
        '--custom-bg-color': this.bgColor,
        '--custom-font-color': this.fontColor,
        '--custom-input-width': this.inputWidth,
      };
    },
  },

  updated() {
    this.value = this.defaultValue;
  },
  methods: {
    onChange(newValue) {
      if (Number(newValue) <= this.max && Number(newValue) >= this.min) {
        this.value = Number(newValue);
      } else if (Number(newValue) > this.max) {
        this.value = this.max;
      } else if (Number(newValue) < this.min) {
        this.value = this.min;
      }
      this.inputKey += 1;
      this.$emit('final-value', { value: this.value });
    },
    onIncrease() {
      if (this.value + this.defaultStep <= this.max) {
        this.value += this.defaultStep;
      } else {
        this.value = this.max;
      }
      this.inputKey += 1;
      this.$emit('final-value', { value: this.value });
    },
    onDecrease() {
      if (this.value - this.defaultStep >= this.min) {
        this.value -= this.defaultStep;
      } else {
        this.value = this.min;
      }
      this.inputKey += 1;
      this.$emit('final-value', { value: this.value });
    },
  },
};
</script>

<style lang="scss">
.main-stepper{
  padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));
  &__small {
    // Aqui va el css del stepper small
    zoom: 0.75;
  }
  .content-stepper {
    padding-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));
    margin: 3px auto 10px;
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    display: flex;
    flex-wrap: nowrap;
    .input {
      border-top: 2px solid var(--custom-bg-color)!important;
      border-bottom: 2px solid var(--custom-bg-color)!important;
      border-radius: 0;
      padding: 0px 15px;
      width: 100%;
      font-size: var(--f7-stepper-value-font-size);
      font-weight: var(--f7-stepper-value-font-weight);
      color: var(--f7-stepper-value-text-color,var(--f7-theme-color));
      text-align: center;
    }
    .input-focused {
      padding: 0 15px!important;
    }
    &__controls {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(var(--custom-input-width) / 2);
      min-width: 60px;
      max-width: 60px;
      border-top: 1px solid var(--custom-bg-color);
      border-bottom: 1px solid var(--custom-bg-color);
      &:hover{
        cursor: pointer;
      }
    }
    .controls::selection {
      background: none;
    }
    .controls::-moz-selection {
      background: none;
    }
  }
}
.roundRight {
  border-top-right-radius: var(--f7-stepper-border-radius);
  border-bottom-right-radius: var(--f7-stepper-border-radius);
}
.roundLeft {
  border-top-left-radius: var(--f7-stepper-border-radius);
  border-bottom-left-radius: var(--f7-stepper-border-radius);
}
.fill {
  background-color: var(--custom-bg-color);
  color: var(--custom-font-color);
  border-top: 4px solid var(--custom-bg-color)!important;
  border-bottom: 4px solid var(--custom-bg-color)!important;
}
.outline {
    border: 1px solid var(--custom-bg-color);
    background-color: var(--custom-font-color);
    color: var(--custom-bg-color);
}
</style>
