import db from '../services/Database';

export default {
  async add(templateData) {
    return await db.templates.add(templateData);
  },
  async getAll() {
    return await db.templates.toArray();
  },
  async updateById(id, updateObj) {
    return await db.templates.update(id, updateObj);
  },
  async getByService(ids) {
    return await db.templates.where('entityId').equals(parseInt(ids, 10)).toArray();
  },
  async getById(ids) {
    return await db.templates.where('id').equals(parseInt(ids, 10)).first();
  },
  async getByNotService() {
    return await db.templates.where('entityId').equals(0).toArray();
  },
  async getByNotServiceRoles(roles) {
    return await db.templates.where('entityId').equals(0).and((t) => roles.includes(t.rol.toString())).toArray();
  },
  async getByRol(rol) {
    return await db.templates.where('rol').equals(parseInt(rol, 10)).toArray();
  },
  async getByRoles(ids) {
    return await db.templates.where('rol').anyOf(ids).toArray();
  },
  async deleteById(id) {
    return await db.templates.where('id').equals(id).delete();
  }
};
