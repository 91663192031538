<template>
  <div class="input-signature">
    <span class="input-signature__label">
      {{ formattedLabel }}
    </span>
    <template v-if="isSignatureInSignMode">
      <sign-canvas
        :ref="reference"
        v-model="currentSignature"
        class="input-signature__canvas"
        :options="signCanvasOptions"
      />
      <f7-row>
        <f7-col>
          <f7-button
            :round="areButtonsRounded"
            small
            outline
            color="gray"
            @click="clearSignature"
          >
            Limpiar
          </f7-button>
        </f7-col>
        <f7-col>
          <f7-button
            :round="areButtonsRounded"
            color="green"
            fill
            small
            @click="saveSignature"
          >
            Firmar
          </f7-button>
        </f7-col>
      </f7-row>
    </template>
    <template v-else>
      <img
        class="input-signature__signature"
        :src="currentSignature"
        alt="signature"
      >
      <f7-row>
        <f7-col>
          <f7-button
            :round="areButtonsRounded"
            raised
            class="button button-fill input-signature__button"
            @click="editSignature"
          >
            Editar
          </f7-button>
        </f7-col>
      </f7-row>
    </template>
  </div>
</template>

<script>
import SignCanvas from 'sign-canvas';

const SIGN_CANVAS_OPTIONS = {
  isDpr: false,
  lastWriteSpeed: 1,
  lastWriteWidth: 2,
  lineCap: 'round',
  lineJoin: 'bevel',
  canvasWidth: 350,
  canvasHeight: 200,
  isShowBorder: false,
  bgColor: '#F7F8FA',
  borderWidth: 1,
  borderColor: '#D4DCE1',
  writeWidth: 4,
  maxWriteWidth: 50,
  minWriteWidth: 1,
  writeColor: '#000000',
  isSign: true,
  imgType: 'png',
};
export default {
  name: 'HiFormInputSignature',
  components: {
    SignCanvas,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    reference: {
      type: String,
      required: true,
    },
    areButtonsRounded: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    signature: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      signCanvasOptions: SIGN_CANVAS_OPTIONS,
      currentSignature: null,
      isSignatureInSignMode: true,
    };
  },
  computed: {
    formattedLabel() {
      return this.required ? `${this.label}*` : this.label;
    },
  },
  watch: {
    signature() {
      this.setSignature();
    },
    currentSignature(val) {
      document.activeElement.blur();
    },
  },
  created() {
    this.setSignature();
  },
  methods: {
    setSignature() {
      if (this.signature) {
        this.currentSignature = this.signature;
        this.isSignatureInSignMode = false;
      }
    },
    saveSignature() {
      const newSignature = this.$refs[this.reference].saveAsImg();
      this.currentSignature = newSignature;
      this.isSignatureInSignMode = false;
      this.$emit('new-signature', newSignature);
    },
    clearSignature() {
      this.$refs[this.reference].canvasClear();
      this.currentSignature = null;
      this.$emit('signature-cleared');
    },
    editSignature() {
      this.isSignatureInSignMode = true;
      this.currentSignature = null;
      this.$emit('signature-cleared');
    },
  },
};
</script>

<style lang="scss" scoped>
// Variables:
$inputSignatureLeftPadding: calc(
  var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left)
);

$inputSignatureRightPadding: calc(
  var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right)
);

// Classes:
.input-signature {
  padding-left: $inputSignatureLeftPadding;
  padding-right: $inputSignatureRightPadding;
  padding-top: calc(var(--f7-typography-padding) / 2);
  &__label {
    font-size: var(--f7-label-font-size);
    font-weight: var(--f7-label-font-weight);
    color: var(--f7-label-text-color);
    line-height: var(--f7-label-line-height);
  }
  &__canvas {
    width: 100% !important;
    margin: 0 auto;
    border: 1px solid var(--his-silver);
    border-radius: 8px;
    margin-bottom: 15px;
  }
  &__signature {
    width: 100% !important;
    height: 200px;
    margin: 0 auto;
    border: 1px solid var(--his-silver);
    border-radius: 8px;
    margin-bottom: 15px;
  }
}
</style>
