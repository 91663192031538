import ResponseRepository from '../../repositories/ResponseRepository';
import RefreshData from '../../services/RefreshData';

export default {
  namespaced: true,
  state: {
    pending: 0,
    responsesAreUploading: false,
    boolRefresh: false,
    dates: null
  },
  actions: {
    async Responses_Insert({ commit }, response) {
      await ResponseRepository.add(response);

      const pending = await ResponseRepository.getPending();
      commit('SET_PENDING_COUNT', pending.length);
    },
    async Responses_Update({ commit }, {id, response}) {
      await ResponseRepository.edit(id, response);

      const pending = await ResponseRepository.getPending();
      commit('SET_PENDING_COUNT', pending.length);
    },

    async Responses_UploadPending({ commit }) {
      commit('SET_UPLOADING', true);
      try {
        await RefreshData.uploadPendingResponses();

        const pending = await ResponseRepository.getPending();
        commit('SET_PENDING_COUNT', pending.length);
      } catch (e) {
        console.error(e);
      }
      commit('SET_UPLOADING', false);
    },
  },
  mutations: {
    SET_PENDING_COUNT(state, payload) {
      state.pending = payload;
    },
    SET_UPLOADING(state, payload) {
      state.responsesAreUploading = payload;
    },
    SET_BOOL_REFRESH(state, payload) {
      state.boolRefresh = payload;
    },
    SET_DATES(state, payload) {
      state.dates = payload
    }
  },
  getters: {
    getDatesSelected: (state) => state.dates,
  }
}
