<template>
  <f7-popup
    :opened="showPreloader"
    :close-by-backdrop-click="false"
    :close-on-escape="false"
  >
    <f7-page class="padding">
      <h3>Descarga de datos</h3>

      <div
        v-for="task in loaderTasks"
        :key="task.id"
        class="padding-half"
      >
        <strong>{{ task.name }}...</strong>
        <f7-icon
          v-if="task.status == 'ok'"
          f7="checkmark_alt"
          color="green"
        />
        <f7-icon
          v-else-if="task.status == 'ko'"
          f7="multiply"
          color="red"
        />
        <f7-preloader
          v-else
          :size="18"
          color="orange"
        />
      </div>

      <f7-toolbar
        v-if="pendingTaskCount === 0"
        bottom
        class="bg-color-white"
      >
        <f7-link @click="retryAllFailures">
          Reintentar
        </f7-link>
        <f7-link @click="cancelAndClear">
          Continuar
        </f7-link>
      </f7-toolbar>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import EventBus from '../js/EventBus';

export default {
  name: 'TaskLoader',
  computed: {
    ...mapState('TaskLoader', [
      'showPreloader',
      'loaderTasks'
    ]),
    ...mapGetters('TaskLoader', ['pendingTaskCount']),
  },
  created() {
    EventBus.$on('taskLoader.push', this.pushNewTask);
  },

  beforeDestroy() {
    EventBus.$off('taskLoader.push');
  },
  destroyed() {
    EventBus.$off('taskLoader.push');
  },
  methods: {
    ...mapActions('TaskLoader', [
      'pushNewTask',
      'retryAllFailures',
      'cancelAndClear'
    ]),
  },
}
</script>

<style>

</style>