import EventBus from '../../EventBus';
import Task from './Entities/Task';

export default {
  namespaced: true,
  state: {
    showPreloader: false,
    loaderTasks: [],
  },
  getters: {
    pendingTaskCount(state) {
      return state.loaderTasks.filter((task) => !task.status).length;
    },
  },
  actions: {
    // -----------------------------------------------------------------
    async pushNewTask({ commit, state, dispatch }, taskMeta) {
      const { name, asyncTask, onTaskEnd } = taskMeta;
      const onTaskEndActions = [() => dispatch('checkIfAllTasksAreFinished')];

      if (onTaskEnd) {
        onTaskEndActions.push(onTaskEnd);
      }

      const task = new Task(name, asyncTask, onTaskEndActions);

      if (name === 'Obteniendo socios') {
        await task.start();

        if (task.status === 'ok') {
          dispatch('Observations/fetchFarmers', {}, { root: true })
        }

      } else {
        task.start();
      }

      const pending = state.loaderTasks.filter((t) => t.status !== 'ok');
      pending.push(task);

      commit('UPDATE_TASKS', pending);

      await dispatch('checkIfAllTasksAreFinished');
    },

    // -----------------------------------------------------------------
    retryAllFailures({ commit, state }) {
      state.loaderTasks.forEach((task) => {
        // This is to prevent a restart on a task that is still running.
        if (task.status === 'ko') { task.restart(); }
      });

      commit('UPDATE_TASKS', state.loaderTasks);
    },

    // -----------------------------------------------------------------
    cancelAndClear({ commit }) {
      commit('UPDATE_TASKS', []);
      commit('UPDATE_SHOW_PRELOADER', false);
    },

    // -----------------------------------------------------------------
    checkIfAllTasksAreFinished({ commit, state }) {
      const pending = state.loaderTasks.filter((t) => t.status !== 'ok');
      commit('UPDATE_SHOW_PRELOADER', pending.length !== 0);

      if (pending.length == 0) {
        EventBus.$emit('taskLoader.finished');
      }
    },
  },
  mutations: {
    UPDATE_TASKS(state, payload) {
      state.loaderTasks = payload;
    },

    UPDATE_SHOW_PRELOADER(state, payload) {
      state.showPreloader = payload;
    },
  }
}
