<template>
  <f7-navbar
    slot="fixed"
    class="at-navbar"
    :sliding="false"
  >
    <f7-nav-left back-click>
      <f7-link
        v-if="backBtnEnable"
        icon="icon-custom-back"
        @click="goBack"
      />
      <f7-link
        v-if="menuBtnEnable"
        icon="icon-menu"
        panel-open="left"
      />
      <div class="title color-white">
        {{ navTitle }}
      </div>
    </f7-nav-left>

    <f7-nav-right>
      <f7-link
        v-if="refreshBtnEnable && !responsesAreUploading"
        @click="refresh"
      >
        <f7-icon icon="icon-reload">
          <f7-badge
            v-if="pending"
            color="red"
          >
            {{ pending }}
          </f7-badge>
        </f7-icon>
      </f7-link>
      <div v-if="responsesAreUploading">
        <f7-preloader
          :size="30"
          color="white"
        />
      </div>
      <f7-link
        v-if="notificationsBtnEnable"
        icon="icon-notifications"
        @click="notificationsClicked"
      />
      <f7-link
        v-if="editBtnEnable"
        ref="/editalert/"
        icon="icon-edit"
        @click="navigateToEdit"
      />
      <f7-link
        v-if="addBtnEnable"
        href="/newForm/"
        @click="addBtnCallback"
      >
        <f7-icon icon="icon-add" />
      </f7-link>
      <f7-link
        v-if="imgBtnEnable"
        @click="imgBtnCallback"
      >
        <f7-icon icon="icon-gallery" />
      </f7-link>
      <f7-link
        v-if="docBtnEnable"
        @click="docBtnCallback"
      >
        <f7-icon icon="icon-img" />
      </f7-link>
      <f7-link
        v-if="saveBtnEnable"
        @click="saveBtnCallback"
      >
        <f7-icon icon="icon-save" />
      </f7-link>
      <f7-link
        v-if="saveTopBtnEnable"
        @click="saveTopBtnCallback"
      >
        <f7-icon icon="icon-save" />
      </f7-link>
      <f7-link
        v-if="addBtnTemplateEnable"
        @click="addBtnCallback"
      >
        <f7-icon icon="icon-add" />
      </f7-link>
      <f7-link
        v-if="deleteBtnEnable"
        href="#"
        @click="deleteBtnCallback"
      >
        <f7-icon icon="icon-delete" />
      </f7-link>
      <a
        v-if="downloadBtnEnable"
        class="link external"
        :href="false"
        @click="downloadBtnCallback"
      >
        <f7-icon icon="icon-arrow_down_to_line" />
      </a>
    </f7-nav-right>
    <div v-if="searchBarEnable">
      <f7-subnavbar :inner="false">
        <f7-searchbar
          search-container=".virtual-list"
          search-item="li"
          search-in=".item-title"
          placeholder="Buscar"
        />
      </f7-subnavbar>
    </div>
  </f7-navbar>
</template>



<script>
import { mapState, mapMutations } from 'vuex';
import EventBus from '../js/EventBus';
import RefreshData from '../js/services/RefreshData';

export default {
  name: 'Navbar',
  props: {
    searchBarEnable: { type: Boolean, default: false },
    deleteBtnEnable: { type: Boolean, default: false },
    addBtnEnable: { type: Boolean, default: false },
    addBtnTemplateEnable: { type: Boolean, default: false },
    imgBtnEnable: { type: Boolean, default: false },
    docBtnEnable: { type: Boolean, default: false },
    downloadBtnEnable: { type: Boolean, default: false },
    backBtnEnable: { type: Boolean, default: false },
    menuBtnEnable: { type: Boolean, default: false },
    refreshBtnEnable: { type: Boolean, default: false},
    notificationsBtnEnable: { type: Boolean, default: false},
    editBtnEnable: { type: Boolean, default: false},
    saveBtnEnable: { type: Boolean, default: false },
    saveTopBtnEnable: { type: Boolean, default: false },
    navTitle: { type: String, default: '' },
    backCallback: { type: Function, default: undefined },
    addBtnCallback: {
      type: Function,
      default: () => {
      },
    },
    imgBtnCallback: {
      type: Function,
      default: () => {
      },
    },
    docBtnCallback: {
      type: Function,
      default: () => {
      },
    },
    saveBtnCallback: {
      type: Function,
      default: () => {
      },
    },
    saveTopBtnCallback: {
      type: Function,
      default: () => {
      },
    }
  },
  data() {
    return {
      close : {'alertResponse': 0},
    };
  },

  computed: {
    syncCounterDisplay() {
      return 0;
    },
    ...mapState('Helped', ['selectedHelpedElement']),
    ...mapState('Responses', [
      'pending',
      'responsesAreUploading'
    ]),
  },

  methods: {
    ...mapMutations('Responses', ['SET_BOOL_REFRESH']),
    goBack() {
      if (this.backCallback) {
        this.backCallback();
      } else {
        this.$f7router.back();
      }
    },
    async refresh() {
      this.SET_BOOL_REFRESH(true)
      EventBus.$emit('full.reload');
    },
    downloadBtnCallback() {
      const url = 'https://drive.google.com/uc?id=1Wr9mn_EmO8y6fWNTgKLPDuNJgmDNkhcu&export=download';
      if(!navigator) return;
      navigator.app.loadUrl(url, {openExternal : true})
    },
    navigateToEdit() {
      this.$f7router.navigate('/editalert/');
    },
    notificationsClicked() {
      this.$emit('navbar:norifications')
    },
    async deleteBtnCallback () {
      this.close.alertResponse = this.selectedHelpedElement.id;
      try {
        await RefreshData.refresh();
        this.$f7router.navigate('/mainpage/');
      } catch (e) {
        console.log(e)
      }
    }
  },
};
</script>

<style lang="scss">
  .at-navbar{
    .navbar-inner{
      padding-left: 20px;
    }
    .icon-menu {
      margin-top: 3px;

    }
    .icon {
      width: 38px;
      height: 38px;
    }

    .right a.link {
      padding: 0 0 0 15px!important;
      min-width: 0px;
    }

    .icon-custom-back {
      background-image: url('../static/img/ic_back.svg');
      width: 24px;
      height: 24px;
    }

    .icon-reload {
      background-image: url('../static/img/ic_refresh.svg');
    }

    .icon-scan-nfc {
      background-image: url('../static/img/ic_nfc.svg');
    }
    .icon-img {
      background-image: url('../static/img/ic_resume_productivity_menu.svg');
    }
    .icon-gallery {
      background-image: url('../static/img/ic_gallery.svg');
    }


    .icon-add {
      background-image: url('../static/img/ic_new_orange.svg');
    }
    .icon-notifications {
      background-image: url('../static/img/ic_notifications.svg');
    }
    .icon-edit {
      background-image: url('../static/img/ic_edit.svg');
    }
    .icon-delete {
      background-image: url('../static/img/ic_delete.svg');
    }
    .icon-arrow_down_to_line {
      background-image: url('../static/img/ic_download.svg');
    }
    .icon-save {
      background-image: url('../static/img/ic_save.svg');
    }
    .icon-menu {
      background-image: url('../static/img/ic_menu.svg');
      width: 24px;
      height: 24px;
    }
    .icon-back{
      background-image: url('../static/img/ic_back.svg');
      width: 24px;
      height: 24px;
      &::after{
        display: none;
      }
    }
    .refresh-preloader {
      padding: 0 16px;
      min-width: 32px;
    }
  }
  .smart-select-page{
    .navbar{
      a.link{
        padding-left: 0!important;
        min-width: 0;
      }
    }
  }
</style>
