<template>
  <div
    id="main-info-row"
    :class="['main-info-row', selectedTheme]"
    :style="customStyles"
  >
    <f7-list>
      <f7-list-item
        class="content-checkbox"
      >
        <f7-badge
          v-if="titleCount && showTitleCount"
          class="margin-right-half"
        >
          {{ titleCount }}
        </f7-badge>
        <div class="item-title margin-left-half">
          {{ title }}
        </div>
        <div class="item-after">
          <slot name="after">
            {{ after }}
          </slot>
        </div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    titleCount: { type: Number, default: null },
    showTitleCount: { type: Boolean, default: true },
    after: { type: [String, Number], default: '' },
    theme: { type: [String, Object], default: '' },
  },

  computed: {
    selectedTheme() {
      if (typeof this.theme === 'object') return '';
      return `main-info-row__${this.theme}`;
    },
    customStyles() {
      return {
        '--custom-background': this.theme.background,
        '--custom-color': this.theme.color,
      };
    },
  },

  mounted() {
    if (typeof this.theme === 'object') {
      const element = document.getElementById('main-info-row');
      element.classList.add('theme');
    }
  },
};
</script>

<style lang="scss" scoped>
.theme {
  background-color: var(--custom-background) !important;
  color: var(--custom-color) !important;
}
.main-info-row {
  --f7-checkbox-active-color: var(--his-green);
  --bg-color: var(--his-silver);
  // TODO según el background tienen que cambiar el color de la fuente
  color: var(--his-darkgray);
  align-items: center;
  background-color: var(--bg-color);
  border-bottom: 1px solid var(--his-silver);
  overflow: hidden;
  .list{
    .item-inner{
      border-bottom: none!important;
    }
    .item-title{
      color: var(--his-darkgray);
    }
  }

  .check-all-title {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: var(--his-blue);
  }

  &__blue {
    --bg-color: var(--his-blue);
    .list{
      .item-title{
        color: #FFFFFF;
      }
    }
  }
  &__deepgray {
    --bg-color: var(--his-deepgray);
    .list{
      .item-title{
        color: #FFFFFF;
      }
    }
  }
  &__yellow {
      --bg-color: var(--his-yellow);
  }
  &__light {
      --bg-color: var(--his-lightsnow);
  }
  &__gray{
    --bg-color: #A5A9B8;
    .badge{
      --f7-badge-bg-color: var(--his-blue);
    }
    .list{
      .item-title{
        color: #FFFFFF;
      }
    }
  }

  &__ignore-padding {
    padding: unset;
  }

  &:after {
    z-index: 2;
    position: absolute;
    left: 16px;
    content: '';
    width: 0;
    height: 0;
    border-top: solid 10px var(--bg-color);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    filter: drop-shadow(0px 1px 0px var(--his-silver));
  }
}
</style>
