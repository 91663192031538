<!-- https://github.com/olefirenko/vue-barcode-reader -->
<template>
  <div>
    <p class="error">
      {{ error }}
    </p>

    <p class="decode-result">
      Último Valor Leído: <b>{{ result }}</b>
    </p>

    <stream-barcode-reader
      v-if="openCamera"
      ref="scanner"
      @decode="onDecode"
      @loaded="onLoaded"
    />
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default {
  name: 'ReadBarcode',
  components: { StreamBarcodeReader },
  props: {
    openCamera: { type: Boolean, default: false },
  },
  data() {
    return {
      result: '',
      error: '',
      openc: this.openCamera,
      finishOpen: false
    };
  },
  methods: {
    onDecode(result) {
      console.log('-->onDecoode', result);
      this.result = result;
      this.$emit('barcode-result', { value: this.result });
      console.log('Tras emitir resultado');
      console.log('this.$refs.scanner',this.$refs.scanner);
      console.log('this.$refs.scanner.codeReader', this.$refs.scanner.codeReader);
      console.log('this.$refs.scanner.codeReader.stream', this.$refs.scanner.codeReader.stream);
      this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) { console.log('track', track);track.stop() })
    },
    onLoaded() {
      console.log('La cámara ha cargado');
      console.log('Se llama a finish-open-camera')
      this.$emit('finish-open-camera', { value: true});
    },
  },
};
</script>
