var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-panel',{attrs:{"left":"","cover":""}},[_c('f7-view',[_c('f7-page',[_c('div',{staticClass:"main-storybook-sidebar"},[_c('f7-block',{staticClass:"content-left-panel-header"},[_c('div',{staticClass:"content-left-panel-header__img"},[_c('img',{staticClass:"img-imagenPrincipalTrabajador",attrs:{"src":_vm.iconUser,"alt":"user"}})]),_vm._v(" "),_c('div',{staticClass:"content-left-panel-header__title"},[_c('span',[_vm._v(_vm._s(_vm.parsedTemplate.headerTopTitle))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.parsedTemplate.headerTopSubtitle))])])]),_vm._v(" "),_c('f7-list',{staticClass:"main-select-list",attrs:{"no-hairlines":""}},[(_vm.optionsSmartSelect)?_c('f7-list-item',{attrs:{"title":"Smart Select Title","smart-select":"","smart-select-params":{
              searchbar: true,
              searchbarPlaceholder: 'Buscar'
            }}},[_c('select',[_c('option',{attrs:{"value":"Batman","selected":""}},[_vm._v("\n                Batman\n              ")]),_vm._v(" "),_c('option',{attrs:{"value":"Superman"}},[_vm._v("\n                Superman\n              ")])])]):_vm._e()],1),_vm._v(" "),_c('f7-list',{staticClass:"main-list",attrs:{"no-hairlines":""}},[(_vm.favorites)?_c('f7-list-group',[_c('f7-list-item',{key:_vm.favoriteKey,staticClass:"group-title",attrs:{"title":"FAVORITOS"}}),_vm._v(" "),_vm._l((_vm.favoritesSections),function(element,index){return _c('f7-list-item',{key:index,attrs:{"title":element.title}},[_c('img',{attrs:{"slot":"media","src":_vm.sideMenuDefault,"width":"32","height":"32","alt":"media"},slot:"media"}),_vm._v(" "),_c('img',{attrs:{"src":_vm.starFill,"alt":"star"},on:{"click":function($event){return _vm.favoriteProcessor(_vm.item.id, _vm.item.favorite)}}})])})],2):_vm._e(),_vm._v(" "),(_vm.optionsSearchbar)?_c('f7-searchbar',{attrs:{"search-container":".main-list","search-in":".item-title","placeholder":"Buscador de opciones","clear-button":true,"disable-button-text":"Cancel"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.parsedTemplate.items),function(element,index){return _c('div',{key:index},[_c('f7-list',{staticClass:"list-container"},[(element.type === 'item')?_c('f7-list-item',{attrs:{"title":element.options.title},on:{"click":function($event){return _vm.navigateTo(element.options.path)}}},[(element.options.showIcon)?_c('img',{attrs:{"slot":"media","src":_vm.sideMenuDefault,"width":"32","height":"32","alt":"default"},slot:"media"}):_vm._e(),_vm._v(" "),(_vm.favorites)?_c('div',{staticClass:"favorite-list-container"},[(!element.options.favorite)?_c('img',{attrs:{"src":_vm.star,"alt":"star"},on:{"click":function($event){return _vm.favoriteProcessor(
                      element.options.id, null, element.options.favorite
                    )}}}):_vm._e(),_vm._v(" "),(element.options.favorite)?_c('img',{attrs:{"src":_vm.starFill,"alt":"starFill"},on:{"click":function($event){return _vm.favoriteProcessor(
                      element.options.id, null, element.options.favorite
                    )}}}):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),(element.type === 'acordionList')?_c('hi-sorted-accordion',{attrs:{"show-badge":false,"title":element.options.title,"list-options":element.options.items},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('f7-list',[_c('f7-list-item',{attrs:{"title":item.title},on:{"click":function($event){return _vm.navigateTo(item.path)}}},[(item.showIcon)?_c('img',{attrs:{"slot":"media","src":_vm.sideMenuDefault,"alt":"default","width":"32","height":"32"},slot:"media"}):_vm._e(),_vm._v(" "),(_vm.favorites)?_c('div',{staticClass:"favorite-list-container"},[(!item.favorite)?_c('img',{attrs:{"src":_vm.star,"alt":"star"},on:{"click":function($event){return _vm.favoriteProcessor(element.options.id, item.id, item.favorite)}}}):_vm._e(),_vm._v(" "),(item.favorite)?_c('img',{attrs:{"src":_vm.starFill,"alt":"starFill"},on:{"click":function($event){return _vm.favoriteProcessor(element.options.id, item.id, item.favorite)}}}):_vm._e()]):_vm._e()])],1)]}}],null,true)}):_vm._e(),_vm._v(" "),(element.type === 'groupList')?_c('f7-list-group',[_c('f7-list-item',{staticClass:"group-title",attrs:{"title":element.options.title}}),_vm._v(" "),_vm._l((element.options.items),function(itemGroup,index2){return _c('f7-list-item',{key:index2,attrs:{"title":itemGroup.title},on:{"click":function($event){return _vm.navigateTo(itemGroup.path)}}},[(itemGroup.showIcon)?_c('img',{attrs:{"slot":"media","src":_vm.sideMenuDefault,"alt":"default","width":"32","height":"32"},slot:"media"}):_vm._e(),_vm._v(" "),(_vm.favorites)?_c('div',{staticClass:"favorite-list-container"},[(!itemGroup.favorite)?_c('img',{attrs:{"src":_vm.star,"alt":"star"},on:{"click":function($event){return _vm.favoriteProcessor(
                        element.options.id, itemGroup.id, itemGroup.favorite
                      )}}}):_vm._e(),_vm._v(" "),(itemGroup.favorite)?_c('img',{attrs:{"src":_vm.starFill,"alt":"starFill"},on:{"click":function($event){return _vm.favoriteProcessor(
                        element.options.id, itemGroup.id, itemGroup.favorite
                      )}}}):_vm._e()]):_vm._e()])})],2):_vm._e()],1)],1)})],2)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }