<template>
  <f7-page name="settings">
    <navbar
      slot="fixed"
      nav-title="Ajustes"
      menu-btn-enable
    />

    <div class="padding">
      <f7-block-title>
        Última sincronización
      </f7-block-title>
      <f7-block inset>
        {{ lastUpdateStr }}
      </f7-block>

      <f7-block-title class="padding-top">
        Versión APP
      </f7-block-title>
      <f7-block inset>
        {{ appVersion }}
      </f7-block>
    </div>


    <f7-block
      strong
      bottom-md
    >
      <f7-button
        fill
        class="button-large margin-bottom"
        @click="syncAllData"
      >
        Sincronizar
      </f7-button>

      <f7-button
        fill
        class="button-large margin-vertical"
        @click="deleteAllData"
      >
        Limpiar todo
      </f7-button>

      <f7-button
        fill
        class="button-large margin-top"
        @click="goChangePassword"
      >
        Cambiar contraseña
      </f7-button>
    </f7-block>


    <f7-block
      strong
      bottom-md
    >
      Número de respuestas totales en BD: {{ responsesTotals }}
    </f7-block>

    <f7-block
      strong
      bottom-md
    >
      Memoria uso: <br>
      jsHeapSizeLimit: {{ jsHeapSizeLimit }} <br>
      totalJSHeapSize: {{ totalJSHeapSize }} <br>
      usedJSHeapSize: {{ usedJSHeapSize }} <br>
    </f7-block>

    <div
      v-if="responsesPending.length > 0"
    >
      <f7-block
        strong
        outline-ios
      >
        Respuestas pendientes de sincronizar
        <f7-row>
          <f7-col>
            <f7-button
              fill
              @click="saveFileExport"
            >
              Obtener Backup
            </f7-button>
          </f7-col>
          <f7-col>
            <f7-button
              fill
              @click="sendEmailExport"
            >
              Enviar Backup
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>


      <f7-list
        media-list
        style="margin-top:20px"
      >
        <f7-list-item
          v-for="(element, index) in responsesPending"
          :id="element.id"
          :key="index"
          :title="printItemPending(element)"
        />
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import Navbar from '../components/Navbar.vue';

import EventBus from '../js/EventBus';
import RefreshData from '../js/services/RefreshData';
import Api from '../js/services/Api';

import * as Config from '../js/constants';
import StringUtils from '../js/utilities/StringUtils';
import { mapMutations, mapState, mapActions } from 'vuex';

import store from '../js/store/index';

import ResponseRepository from '../js/repositories/ResponseRepository';
import { f7Block } from 'framework7-vue';

export default {
  name: 'Settings',
  components: { Navbar, f7Block },
  data() {
    return {
      lastUpdated: localStorage.getItem('lastUpdate'),
      appVersion:  Config.APP_VERSION,
      responsesPending: [],
      jsonExport: null,
      responsesTotals: 0,
      jsHeapSizeLimit: 0,
      totalJSHeapSize: 0,
      usedJSHeapSize: 0
    };
  },
  computed: {
    lastUpdateStr() {
      let date = new Date(Number(this.lastUpdated));
      return StringUtils.dateToFullString(date);
    },
  },
  async created() {
    this.responsesPending = await ResponseRepository.getPendingToUpload();
    const tmp  = await ResponseRepository.getAll();
    this.responsesTotals = tmp.length;

    // Memory
    this.jsHeapSizeLimit= (performance.memory.jsHeapSizeLimit / 1024 / 1024).toFixed(2);
    this.totalJSHeapSize =  (performance.memory.totalJSHeapSize / 1024 / 1024).toFixed(2);
    this.usedJSHeapSize= (performance.memory.usedJSHeapSize / 1024 / 1024).toFixed(2);
  },
  methods: {
    ...mapMutations(['SET_CHANGE_PASSWORD']),
    ...mapActions(['setLogoutUser']),
    exit() {
      this.setLogoutUser();
      this.$f7.views.main.router.navigate('/login/');
      location.reload();
    },
    async deleteAllData() {
      this.$f7.dialog.confirm(
        '¿Seguro que desea eliminar los datos almacenados?',
        this.exitYes,
        null,
      );

    },
    async exitYes() {
      const res = await RefreshData.deleteAllTasks();
      if(res == 'ok') this.exit();
    },
    async syncAllData() {
      EventBus.$emit('full.reload');
    },
    goChangePassword() {
      this.SET_CHANGE_PASSWORD(true)
      this.$f7.views.main.router.navigate('/firstLogin/');
    },
    printItemPending(item) {
      return item.id + ' | ' + item.idServer + ' | ' + item.response_uid + ' | ' + item.sync + ' | ' + item.template + ' | ' + item.created_at;
    },
    async sendEmailExport() {
      this.$f7.preloader.show();
      const data = this.responsesPending;
      for (const element of data) {
        const data_send = {
          backup: element,
        }
        await Api.sendEmail(data_send);
      }
      this.$f7.preloader.hide();
    },
    saveFileExport() {
      this.$f7.preloader.show();
      let filename = 'backup.txt';
      const data = JSON.stringify(this.responsesPending);
      const mimeType = 'text/plain';
      const saveFile = false;
      const blob = new Blob([data], { type: mimeType });

      if (window.cordova && window.cordova.platformId !== 'browser') {
        document.addEventListener('deviceready', () => {
          let storageLocation = '';
          switch (window.device.platform) {
            case 'Android': storageLocation = window.cordova.file.externalDataDirectory; break;
            case 'iOS': storageLocation = window.cordova.file.documentsDirectory; break;
            default: break;
          }

          let folderPath = storageLocation;
          if (saveFile === true) {
            folderPath = `${window.cordova.file.externalRootDirectory}Download` || 'file:///storage/emulated/0/Download';
          }

          window.resolveLocalFileSystemURL(
            folderPath,
            (dir) => {
              dir.getFile(filename, { create: true }, (file) => {
                file.createWriter((fileWriter) => {
                  fileWriter.onwriteend = () => {
                    const url = file.toURL();
                    window.cordova.plugins.fileOpener2.open(url, mimeType, {
                      error: function error(err) { console.error('download 1: error:', err); },
                      success: function success() {
                      }
                    });
                  };
                  fileWriter.onerror = (err) => {
                    console.error('download 2: error:', err);
                  };
                  fileWriter.write(blob);
                }, (err) => { console.error('download 3: error:', err); });
              },
              (err) => { console.error('download 4: error:', err); });
            },
            (err) => { console.error('download 5: error:', err); }
          );
        });
      } else {
        const url = URL.createObjectURL(blob);
        window.open(url);
      }
      this.$f7.preloader.hide();
    }
  },
};
</script>


