export default class Task {
  /**
   *
   * @param {String} name
   * @param {Function} asyncTask
   * @param {Function[]} onTaskEndActions
   */
  constructor(name, asyncTask, onTaskEndActions) {
    this.name = name;
    this.asyncTask = asyncTask;
    this.onTaskEndActions = onTaskEndActions;

    // Values: ok, ko
    this.status = '';
  }

  async start() {
    try {
      const posibleResult = await this.asyncTask();
      this.status = 'ok';

      for (const action of this.onTaskEndActions) {
        action(posibleResult);
      }
    } catch (e) {
      console.error('MultiLoaderTask Failure');
      console.error(e);
      this.status = 'ko';
    }
  }

  restart() {
    this.status = '';
    this.start();
  }
}