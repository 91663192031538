<template>
  <div>
    <f7-list-item
      checkbox
      :title="formattedLabel"
      :disabled="readonly"
      :checked="isChecked"
      @change="emitNewInputState($event.target.checked)"
    />
    <div
      v-if="shouldShowErrorMessage"
      class="mandatoryCheck"
    >
      {{ ERROR_MESSAGE }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HiFormInputCheckbox',
  props: {
    label: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    checked: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      isChecked: null,
      ERROR_MESSAGE: 'Marque esta casilla si desea continuar',
    };
  },
  computed: {
    formattedLabel() {
      return this.required ? `${this.label}*` : this.label;
    },
    shouldShowErrorMessage() {
      return this.required && !this.isChecked;
    },
  },
  watch: {
    checked(newValue) {
      this.isChecked = newValue;
    },
  },
  created() {
    this.isChecked = this.checked;
  },
  methods: {
    emitNewInputState(newInputState) {
      this.isChecked = newInputState;
      this.$emit('new-input-state', newInputState);
    },
  },
};
</script>

<style lang="scss" scoped>
.mandatoryCheck{
    color:red;
    padding-left:16px
}
</style>
