import db from '../services/Database';

export default class EntityCacheRepository {
  static async resetWith(data) {
    await db.entityCache.clear();
    await db.entityCache.bulkPut(data);
  }

  /**
   *
   * @param {Object[]} data
   * @param {String} code
   */
  static async addWithCode(data, code) {
    data.forEach((item) => item.__code__ = code);

    await db.entityCache.bulkPut(data);
  }

  static async getByCode(code) {
    return await db.entityCache
      .where('__code__')
      .equals(code)
      .toArray();
  }
};