<template>
  <div
    v-show="visibility"
    :style="customStyles"
    class="main-template-list"
  >
    <f7-block class="header-bar">
      <p class="header-text">
        {{ title }} ({{ subTemplatesCount }})
      </p>
      <img
        :src="iconAdd"
        alt="add"
        @click="addElement"
      >
    </f7-block>

    <div
      v-if="showList"
    >
      <item-div
        v-for="(index, subTemplateIndex) in iterator"
        :key="subTemplateIndex"
        :ref="`listItem${getIndex(subTemplateIndex)}`"
        :index="getIndex(subTemplateIndex)"
        :title="'#' + getIndex(subTemplateIndex)"
        :active="subTemplatesOpenState[index]"
        @item:select="onItemSelect(index)"
      >
        <div
          slot="after"
          style="float:right"
        >
          <img
            :src="iconRecycle"
            alt="delete"
            @click="deleteElement(index)"
          >
        </div>
        <slot
          v-if="indexOpened[reference] && indexOpened[reference].includes(index) && subTemplatesOpenState[index]"
          v-bind="{index}"
        />
      </item-div>
    </div>
    <div
      v-else
      class="padding-half text-align-center"
    >
      <f7-preloader />
    </div>
  </div>
</template>

<script>
import icAdd from '../../static/img/ic_add_fill.svg';
import icRecycleBean from '../../static/img/ic_recycleBean.svg';
import ItemDiv from './ItemDiv.vue';

export default {
  name: 'HiTemplateListVisualizerNew',
  components: {
    ItemDiv
  },
  props: {
    title: { type: String, default: '' },
    primary: { type: String, default: 'var(--his-primary)' },
    secondary: { type: String, default: 'var(--his-secondary)' },
    fontColorPrimary: { type: String, default: 'var(--his-primary)' },
    fontColorSecondary: { type: String, default: 'var(--his-font-color-secundary)' },
    reference: { type: String, default: null },
    indexes: { type: Array, default: () => [] },
    subTemplatesCount: { type: Number, default: 0 },
    visibility: { type: Boolean, default: true },
  },
  data() {
    return {
      numSubTemplates: this.subTemplatesCount,
      subTemplatesOpenState: [],
      showList: true,
      indexOpened: {},
      time_out: null,
      //nuevo
      index: 0,
    };
  },

  computed: {
    customStyles() {
      return {
        '--custom-primary': this.primary,
        '--custom-secondary': this.secondary,
        '--custom-font-color-primary': this.fontColorPrimary,
        '--custom-font-color-secondary': this.fontColorSecondary,
      };
    },
    iconAdd() {
      return icAdd;
    },
    iconRecycle() {
      return icRecycleBean;
    },
    iterator() {
      const iterator = [];
      for (let i = 0; i < this.numSubTemplates; i += 1) {
        iterator.push(i);
      }
      return iterator;
    }
  },
  beforeDestroy() {
    // console.log('HiTemplateListVisualizer - beforeDestroy')
    clearTimeout(this.time_out);
    delete this.subTemplatesOpenState;
    delete this.indexOpened;
    delete this.time_out;
  },
  created() {
    this.subTemplatesOpenState = Array.from(Array(this.numSubTemplates).keys()).map(() => false);
  },
  methods: {
    addElement() {
      this.showList = false;
      this.subTemplatesOpenState = Array.from(Array(this.numSubTemplates).keys()).map(() => false);
      this.$emit('new-sublayout-data-group', { reference: this.reference, index: this.numSubTemplates });


      // Enrique
      // this.openIndex(this.numSubTemplates);
      if(this.indexOpened.hasOwnProperty(this.reference)) {
        if(!this.indexOpened[this.reference].includes(this.numSubTemplates)) {
          this.indexOpened[this.reference].push(this.numSubTemplates);
        }
      } else {
        this.indexOpened[this.reference] = [this.numSubTemplates];
      }
      // this.$emit('open-sublayout-data-group', { reference: this.reference, index:this.numSubTemplates });
      // Fin enrique

      this.numSubTemplates += 1;

      this.time_out = setTimeout(() => {
        this.subTemplatesOpenState.push(true);
        this.showList = true;
      }, 500);
    },
    deleteElement(index) {
      this.numSubTemplates -= 1;
      /* const index = this.subTemplateIndexes.indexOf(element);
      this.subTemplateIndexes.splice(index, 1); */
      this.$emit('delete-sublayout-data-group', { reference: this.reference, index });
    },
    getIndex(key) {
      const numericKey = Number(key);
      return numericKey + 1;
    },
    openIndex(index) {
      this.showList = false;
      if(this.subTemplatesOpenState.length === 0) {
        this.subTemplatesOpenState = Array.from(Array(this.numSubTemplates).keys()).map(() => false);
      }
      if(this.indexOpened.hasOwnProperty(this.reference)) {
        if(!this.indexOpened[this.reference].includes(index)) {
          this.indexOpened[this.reference].push(index);
        }
      } else {
        this.indexOpened[this.reference] = [index];
      }
      this.$emit('open-sublayout-data-group', { reference: this.reference, index: index });

      const openTemp = this.subTemplatesOpenState[index];
      if(openTemp === false) {
        this.subTemplatesOpenState[index] = true;
      } else {
        this.subTemplatesOpenState[index] = false;
      }

      this.subTemplatesOpenState.forEach((item, i) => {
        if (i !== index) {
          this.subTemplatesOpenState[i] = false;
        }
      })

      this.time_out = setTimeout(() => {
        this.showList = true;
      }, 50);
      this.time_out = null;
      if(this.time_out === null) {
        clearTimeout(this.time_out);
      }
    },
    //NUEVO
    onItemSelect(index) {
      this.openIndex(index)
    }
  },
};
</script>

<style lang="scss">
.main-template-list {
    margin: 0;
    padding: 0;
    .header-bar {
      margin: 0;
      padding: 8px 16px;
      background-color: var(--custom-primary);
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      border-top: 1px solid #ffffff;
      .header-text {
        margin: 0;
        color: #FFFFFF;
        font-weight: var(--f7-list-item-title-font-weight);
      }
    }
    .accordion-list {
      margin: 0;
    }
    .accordion-item{
      >.item-link{
        background-color: var(--custom-secondary);
        color: var(--custom-font-color-primary);
        .item-title{
          color: var(--custom-font-color-primary);
        }
        .item-content{
          min-height: 0;
          .item-inner{
            min-height: 0;
            padding-top: calc(var(--f7-typography-padding) / 2);
            padding-bottom: calc(var(--f7-typography-padding) / 2);
            .item-title-row{
              display: flex;
              align-items: center;
              .item-after{
                height: 33px;
                margin-right: 10px;
              }
            }
          }
        }

      }
      &.list-element {
      >.item-link{
        border-bottom: 1px solid #FFFFFF!important;
        background-color: var(--his-secundary)!important;
        .item-title{
          color: var(--custom-font-color-primary);
        }
      }
    }
    &.group-layout{
      >.item-link{
        .item-content{
          min-height: var(--f7-list-item-min-height);
        }
        .item-inner{
          padding-top: var(--f7-list-item-padding-vertical);
          padding-bottom: var(--f7-list-item-padding-vertical);
        }
      }
    }
  }
}
</style>
