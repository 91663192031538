import Vue from 'vue';

/**
 * Current active listeners.
 *
 * taskLoader
 *  - push
 * responses
 *  - insert
 *  - update
 *
 */

export default new Vue();

// class EventBus extends Vue{
//   super(){
//     console.log('EventBus super()')
//     this.listeners = {};
//   }
//   // constructor() {
//   //    this.listeners = {};
//   //  }

//   clear() {
//     this.listeners = {};
//   }

//   /**
//    *
//    * @param {String} name
//    * @param {Function} func
//    */
//   on(name, func) {
//     if (this.listeners[name]) {
//       // throw new Error(`EventBus.on: attempt to override an event: ${name}`);
//       console.log(`EventBus.on: attempt to override an event: ${name}`);
//     }

//     this.listeners[name] = func;
//   }

//   /**
//    *
//    * @param {String} name
//    */
//   off(name) {
//     if (!this.listeners[name]) {
//       throw new Error(`EventBus.of: this event name is already undefined: ${name}`);
//     }

//     delete this.listeners[name];
//   }

//   /**
//    *
//    * @param {String} name
//    * @param {Object} param
//    */
//   emit(name, param) {
//     if (!this.listeners[name]) {
//       throw new Error(`EventBus.emit: this event name is not defined: ${name}`);
//     }

//     this.listeners[name](param);
//   }
// }

// export default new EventBus();

