<template>
  <div class="main-formscontainer">
    <div v-if="layout === 'LAYOUT'">
      <div
        v-if="groupTitle"
        class="layout-title"
      >
        {{ groupTitle }}
      </div>
      <slot
        name="LAYOUT"
      />
    </div>
    <f7-list
      v-if="layout === 'GROUP'"
      v-show="visibility"
      accordion-list
      opened
      class="group-container"
    >
      <f7-list-item
        accordion-item
        :accordion-item-opened="!close"
        :title="groupTitle"
        class="group-layout"
      >
        <f7-accordion-content>
          <slot name="GROUP" />
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
    <div v-if="layout === 'LIST_TEMPLATE'">
      <slot
        name="LIST_TEMPLATE"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'HiFormsContainer',
  props: {
    layout: { type: String, default: 'LAYOUT_VERTICAL' },
    viewType: { type: String, default: 'vertical' },
    primary: { type: String, default: 'var(--his-primary)' },
    secondary: { type: String, default: 'var(--his-secondary)' },
    fontColorPrimary: { type: String, default: 'var(--his-primary)' },
    fontColorSecondary: { type: String, default: 'var(--his-font-color-secundary)' },
    groupTitle: { type: String, default: '' },
    visibility: { type: Boolean, default: true },
    close: { type: Boolean, default: false },
  },
  computed: {
    customStyles() {
      return {
        '--custom-primary': this.primary,
        '--custom-secondary': this.secondary,
        '--custom-font-color-primary': this.fontColorPrimary,
        '--custom-font-color-secondary': this.fontColorSecondary,
      };
    },
  },
};
</script>

<style lang="scss">
.main-formscontainer{
  .group-container {
    border-top: 1px solid #ffffff;
    margin: 0;
    .accordion-item{
      >.item-link{
        background: var(--custom-primary);
        .item-content{
          .item-inner{
            .item-title{
              color: var(--f7-accordion-color);
            }
            &::before{
              color: var(--f7-accordion-color);
            }
          }
        }
      }
      &.accordion-item-opened{
        &::after{
          content: "";
          position: absolute;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 12px solid var(--custom-primary);
          left: 15px;
          top: 44px;
          z-index: 1;
        }
        &:last-child{
          padding-bottom: 15px;
        }
      }
      /*.accordion-item-content{
        .list{
          padding-bottom: 15px;
        }
      }*/
    }
  }
}
</style>
