<template>
  <f7-page name="exit" />
</template>

<script>
import store from '../js/store/index';
import { mapActions } from 'vuex';
import RefreshData from '../js/services/RefreshData';

export default {
  name: 'Exit',
  async created() {
    const borradores = Object.keys(localStorage).filter(x => x.startsWith('borrador_'));
    console.log('borradores', borradores);
    if(borradores && borradores.length>0) {
      this.$f7.dialog.confirm(
        'Tiene plantillas en estado borrador. Seguro que quieres salir perdiendo la información?',
        this.exitYes,
        this.exitNo,
      );
    } else {
      this.$f7.dialog.confirm(
        'Seguro que quieres salir',
        this.exitYes,
        this.exitNo,
      );
    }
  },
  methods: {
    ...mapActions(['setLogoutUser']),
    async exitYes() {
      const res = await RefreshData.deleteAllTasks();
      if(res == 'ok') this.exit();
    },
    exit() {
      this.setLogoutUser();
      this.$f7.views.main.router.navigate('/login/');
      location.reload();
    },
    exitNo() {
      this.$f7router.back();
    },
  },
}
</script>

