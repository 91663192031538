import EntityCacheRepository from '../../repositories/EntityCacheRepository';
import Api from '../../services/Api';

export default {
  namespaced: true,
  state: {
    isLoading: true,
    loadingStatusLabel: '',
    hasError: false,
    loadingErrorLabel: '',
    upperEntityCode: '',
    searchText: '',
    entitySearchResults: [],
    entityFullList: [],
    limitPreview: 20,
    page: 1,
  },
  actions: {
    async loadEntitiesByCodeFilter({commit, state, rootGetters}, {code, id}) {

      commit('SET_IS_LOADING', true);
      commit('SET_HAS_ERROR', false);
      commit('SET_LOADING_STATUS_LABEL', '');
      commit('SET_UPPER_ENTITY_CODE', code);
      commit('SET_SEARCH_TEXT', '');
      commit('SET_SEARCH_RESULTS', []);

      commit('SET_LOADING_STATUS_LABEL', 'Cargando datos de caché...');

      let results = [];
      const FORCE_ENTITY_SEARCH = true;

      if (FORCE_ENTITY_SEARCH || results.length === 0) {
        commit('SET_LOADING_STATUS_LABEL', 'Descargando datos del servidor...');
        // This 'code' has no entities in cache.
        //   Trying to get them from API...
        const data = {
          code_service: code,
          params: JSON.stringify([{
            filter: id
          }]),
        };
        try {
          const response = await Api.executeService(data) || [];
          if(response.hasOwnProperty('valor')) {
            console.log(response['mensaje']);
            commit('SET_HAS_ERROR', true);
            commit('SET_LOADING_STATUS_LABEL', 'El servicio no está configurado');
          } else {
            results = response;
          }
          // await EntityCacheRepository.addWithCode(results, code);
        } catch(e) {
          const error = results;
          results = [];
          commit('SET_HAS_ERROR', true);
          commit('SET_LOADING_STATUS_LABEL', 'Hubo un error descargando los datos.');
          commit('SET_ERROR_LABEL', e.toString());
          console.log('ERRORRRRRRRR', error);
        }
      }

      commit('SET_FULL_LIST', results);
      commit('SET_IS_LOADING', false);
    },
    async loadEntitiesByCode({commit, state, rootGetters}, {code, id}) {
      const FORCE_ENTITY_SEARCH = rootGetters.getForceEntitySearch

      if (!FORCE_ENTITY_SEARCH) {
        if (state.upperEntityCode == code && state.entityFullList.length !== 0) {
          return;
        }
      }

      commit('SET_IS_LOADING', true);
      commit('SET_HAS_ERROR', false);
      commit('SET_LOADING_STATUS_LABEL', '');
      commit('SET_UPPER_ENTITY_CODE', code);
      commit('SET_SEARCH_TEXT', '');
      commit('SET_SEARCH_RESULTS', []);

      commit('SET_LOADING_STATUS_LABEL', 'Cargando datos de caché...');

      let results = await EntityCacheRepository.getByCode(code);

      if (FORCE_ENTITY_SEARCH || results.length === 0) {
        commit('SET_LOADING_STATUS_LABEL', 'Descargando datos del servidor...');
        // This 'code' has no entities in cache.
        //   Trying to get them from API...
        const data = {
          id_service: id,
          params: [],
        };
        try {
          results = await Api.executeService(data) || [];
          await EntityCacheRepository.addWithCode(results, code);
        } catch(e) {
          commit('SET_HAS_ERROR', true);
          commit('SET_LOADING_STATUS_LABEL', 'Hubo un error descargando los datos.');
          commit('SET_ERROR_LABEL', e.toString());
          results = await EntityCacheRepository.getByCode(code);
        }
      }

      commit('SET_FULL_LIST', results);
      commit('SET_IS_LOADING', false);
    },
    async searchEntities({commit, state}, searchText) {
      const query = searchText.toLowerCase();
      commit('SET_SEARCH_TEXT', query);

      let multipleSearch = query.split(' ');
      let multipleResults = []

      let arrWords = []
      multipleSearch.forEach(word => {
        if(word.length != 0) {
          arrWords.push(word)
        }
      })

      state.entityFullList.map(item => {
        if (arrWords.every(i => item.name && item.name.toLowerCase().includes(i))) {
          multipleResults.push(item)
        }
      })

      multipleResults = multipleResults.flat();

      commit('SET_SEARCH_RESULTS', multipleResults);
    },
  },
  getters: {
    entitySearchResultsLimited(state) {
      let totalResults = state.entityFullList;
      if (state.searchText) {
        totalResults = state.entitySearchResults;
      }

      return totalResults.slice(0, state.limitPreview * state.page);
    }
  },
  mutations: {
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_LOADING_STATUS_LABEL(state, payload) {
      state.loadingStatusLabel = payload;
    },
    SET_HAS_ERROR(state, payload) {
      state.hasError = payload;
    },
    SET_ERROR_LABEL(state, payload) {
      state.loadingErrorLabel = payload;
    },
    SET_UPPER_ENTITY_CODE(state, payload) {
      state.upperEntityCode = payload;
    },
    SET_SEARCH_TEXT(state, payload) {
      state.searchText = payload;
    },
    SET_SEARCH_RESULTS(state, payload) {
      state.entitySearchResults = payload;
    },
    SET_FULL_LIST(state, payload) {
      state.entityFullList = payload;
    },
    SET_LIMIT_PREVIEW(state, payload) {
      state.limitPreview = payload;
    },
    SET_PAGE(state, payload) {
      state.page = payload;
    }
  },
};
