import db from '../services/Database';

export default {
  getAll() {
    return db.versionsTemplates.toArray();
  },
  updateById(id, updateObj) {
    return db.versionsTemplates.update(id, updateObj);
  },
  getByTemplateAndVersion(template, version) {
    return db.versionsTemplates.where('version').equals(version).and((t) => t.template === template).toArray();
  }
};
