var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-stepper",class:{
    'main-stepper__small': _vm.small
  },style:(_vm.customStyles)},[_c('div',{staticClass:"content-stepper"},[_c('div',{staticClass:"content-stepper__controls controlDecrease",class:{
        fill: _vm.fill,
        outline: !_vm.fill,
        roundLeft: _vm.round
      },on:{"click":_vm.onDecrease}},[_c('span',{staticClass:"material-icons"},[_vm._v("remove")])]),_vm._v(" "),_c('input',{key:_vm.inputKey,staticClass:"input",class:{
        fill: _vm.fill,
        outline: !_vm.fill,
      },attrs:{"type":"number"},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.onChange($event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"content-stepper__controls controlIncrease",class:{
        fill: _vm.fill,
        outline: !_vm.fill,
        roundRight: _vm.round
      },on:{"click":_vm.onIncrease}},[_c('span',{staticClass:"material-icons"},[_vm._v("add")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }