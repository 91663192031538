import db from '../services/Database';

export default {
  async getAll() {
    return await db.allservices.toArray();
  },
  async getById(id) {
    return await db.allservices.where('id').equals(parseInt(id, 10)).toArray();
  },
  async getByCategory(category) {
    return await db.allservices.where('category').equals(category).toArray();
  },
};