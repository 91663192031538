import LoginPage from '../pages/login.vue';
import FirstLoginPage from '../pages/firstLogin.vue';
import HomePage from '../pages/home.vue';
import DetailTemplatePage from '../pages/detailTemplate.vue';
import NewTemplatePage from '../pages/newTemplate.vue';
import CompleteTemplatePage from '../pages/completeTemplate.vue';
import NewFormPage from '../pages/newForm.vue';
import DetailResponsePage from '../pages/detailResponse.vue';
import ListTemplatePage from '../pages/listTemplate.vue';
import ListTemplateFromAutoLogin from '../pages/listTemplateFromAutoLogin.vue'
import ExitPage from '../pages/exit.vue';
import SettingsPage from '../pages/settings.vue';
import NotFoundPage from '../pages/404.vue';
import store from '../js/store/index';

var routes = [
  {
    path: '/',
    // eslint-disable-next-line no-unused-vars
    async(routeTo, routeFrom, resolve, reject) {
      if(routeTo.query.hasOwnProperty('urlToken')) {
        resolve(
          { component: ListTemplateFromAutoLogin },
          { props: { urlToken: routeTo.query.urlToken } }
        );
      } else {
        resolve({ component: LoginPage });
      }
    }
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/auto-login/:urlToken',
    component: ListTemplateFromAutoLogin,
  },
  {
    path: '/firstLogin/',
    component: FirstLoginPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/home/:idRol/',
    component: HomePage,
  },
  {
    path: '/detailtemplate/:isFromUrlToken?',
    component: DetailTemplatePage,
  },
  {
    path: '/detailresponse/',
    component: DetailResponsePage,
  },
  {
    path: '/completetemplate/',
    component: CompleteTemplatePage,
  },
  {
    path: '/newform/',
    component: NewFormPage,
  },
  {
    path: '/newtemplate/:isFromUrlToken?',
    component: NewTemplatePage,
  },
  {
    path: '/listTemplate/',
    component: ListTemplatePage,
  },
  {
    path: '/exit/',
    component: ExitPage,
  },
  {
    path: '/listTemplate/:idRol/',
    component: ListTemplatePage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

function loggedUser(path, component) {
  let user = store.getters.getUser
  if (user) {
    return HomePage
  } else {
    return component
  }
}

function signUpUser(path, component) {
  let sign = store.getters.getSignUp
  if (sign) {
    return SignUpSent
  } else {
    return component
  }
}
export default routes;
