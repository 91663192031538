<template>
  <f7-panel
    left
    cover
  >
    <f7-view>
      <f7-page>
        <div class="main-storybook-sidebar">
          <f7-block
            class="content-left-panel-header"
          >
            <div class="content-left-panel-header__img">
              <img
                :src="iconUser"
                alt="user"
                class="img-imagenPrincipalTrabajador"
              >
            </div>
            <div class="content-left-panel-header__title">
              <span>{{ parsedTemplate.headerTopTitle }}</span>
              <span>{{ parsedTemplate.headerTopSubtitle }}</span>
            </div>
          </f7-block>
          <f7-list
            class="main-select-list"
            no-hairlines
          >
            <f7-list-item
              v-if="optionsSmartSelect"
              title="Smart Select Title"
              smart-select
              :smart-select-params="{
                searchbar: true,
                searchbarPlaceholder: 'Buscar'
              }"
            >
              <select>
                <option
                  value="Batman"
                  selected
                >
                  Batman
                </option>
                <option value="Superman">
                  Superman
                </option>
              </select>
            </f7-list-item>
          </f7-list>

          <!-- List with icons -->

          <f7-list
            class="main-list"
            no-hairlines
          >
            <!-- Favorites -->
            <f7-list-group
              v-if="favorites"
            >
              <f7-list-item
                :key="favoriteKey"
                title="FAVORITOS"
                class="group-title"
              />
              <f7-list-item
                v-for="(element, index) in favoritesSections"
                :key="index"
                :title="element.title"
              >
                <img
                  slot="media"
                  :src="sideMenuDefault"
                  width="32"
                  height="32"
                  alt="media"
                >
                <img
                  :src="starFill"
                  alt="star"
                  @click="favoriteProcessor(item.id, item.favorite)"
                >
              </f7-list-item>
            </f7-list-group>
            <!-- End Favorites -->

            <!-- Search Bar -->
            <f7-searchbar
              v-if="optionsSearchbar"
              search-container=".main-list"
              search-in=".item-title"
              placeholder="Buscador de opciones"
              :clear-button="true"
              disable-button-text="Cancel"
            />

            <div
              v-for="(element, index) in parsedTemplate.items"
              :key="index"
            >
              <f7-list
                class="list-container"
              >
                <f7-list-item
                  v-if="element.type === 'item'"
                  :title="element.options.title"
                  @click="navigateTo(element.options.path)"
                >
                  <img
                    v-if="element.options.showIcon"
                    slot="media"
                    :src="sideMenuDefault"
                    width="32"
                    height="32"
                    alt="default"
                  >
                  <div
                    v-if="favorites"
                    class="favorite-list-container"
                  >
                    <img
                      v-if="!element.options.favorite"
                      :src="star"
                      alt="star"
                      @click="favoriteProcessor(
                        element.options.id, null, element.options.favorite
                      )"
                    >
                    <img
                      v-if="element.options.favorite"
                      :src="starFill"
                      alt="starFill"
                      @click="favoriteProcessor(
                        element.options.id, null, element.options.favorite
                      )"
                    >
                  </div>
                </f7-list-item>
                <hi-sorted-accordion
                  v-if="element.type === 'acordionList'"
                  :show-badge="false"
                  :title="element.options.title"
                  :list-options="element.options.items"
                >
                  <template slot-scope="item">
                    <f7-list>
                      <f7-list-item
                        :title="item.title"
                        @click="navigateTo(item.path)"
                      >
                        <img
                          v-if="item.showIcon"
                          slot="media"
                          :src="sideMenuDefault"
                          alt="default"
                          width="32"
                          height="32"
                        >
                        <div
                          v-if="favorites"
                          class="favorite-list-container"
                        >
                          <img
                            v-if="!item.favorite"
                            :src="star"
                            alt="star"
                            @click="favoriteProcessor(element.options.id, item.id, item.favorite)"
                          >
                          <img
                            v-if="item.favorite"
                            :src="starFill"
                            alt="starFill"
                            @click="favoriteProcessor(element.options.id, item.id, item.favorite)"
                          >
                        </div>
                      </f7-list-item>
                    </f7-list>
                  </template>
                </hi-sorted-accordion>
                <f7-list-group
                  v-if="element.type === 'groupList'"
                >
                  <f7-list-item
                    :title="element.options.title"
                    class="group-title"
                  />
                  <f7-list-item
                    v-for="(itemGroup, index2) in element.options.items"
                    :key="index2"
                    :title="itemGroup.title"
                    @click="navigateTo(itemGroup.path)"
                  >
                    <img
                      v-if="itemGroup.showIcon"
                      slot="media"
                      :src="sideMenuDefault"
                      alt="default"
                      width="32"
                      height="32"
                    >
                    <div
                      v-if="favorites"
                      class="favorite-list-container"
                    >
                      <img
                        v-if="!itemGroup.favorite"
                        :src="star"
                        alt="star"
                        @click="favoriteProcessor(
                          element.options.id, itemGroup.id, itemGroup.favorite
                        )"
                      >
                      <img
                        v-if="itemGroup.favorite"
                        :src="starFill"
                        alt="starFill"
                        @click="favoriteProcessor(
                          element.options.id, itemGroup.id, itemGroup.favorite
                        )"
                      >
                    </div>
                  </f7-list-item>
                </f7-list-group>
              </f7-list>
            </div>
          </f7-list>
        </div>
      </f7-page>
    </f7-view>
  </f7-panel>
</template>

<script>
import icUser from '../../static/img/ic_user.svg';
import icSidemenuDefault from '../../static/img/ic_sideMenuDefault.svg';
import icStar from '../../static/img/ic_star.svg';
import icStarFill from '../../static/img/ic_starFill.svg';
import icSettings from '../../static/img/ic_settings.svg';
import HiSortedAccordion from './HiSortedAccordion.vue';

export default {
  name: 'HiSideMenu',
  components: { HiSortedAccordion },
  props: {
    headerTopTitle: { type: String, default: 'headerTopTitle' },
    headerTopSubtitle: { type: String, default: 'headerTopSubtitle' },
    favorites: { type: Boolean, default: false },
    showIcon: { type: Boolean, default: false },
    optionsSearchbar: { type: Boolean, default: false },
    optionsSmartSelect: { type: Boolean, default: false },
    template: { type: Object, default: () => {} },
  },
  data() {
    return {
      favoritesSections: [],
      favoriteKey: 1,
      parsedTemplate: this.template,
    };
  },
  computed: {
    iconUser() {
      return icUser;
    },
    sideMenuDefault() {
      return icSidemenuDefault;
    },
    star() {
      return icStar;
    },
    starFill() {
      return icStarFill;
    },
    settings() {
      return icSettings;
    },
  },
  mounted() {
    this.contructFavoriteGroup();
  },
  methods: {
    contructFavoriteGroup() {
      this.favoritesSections = [];
      this.parsedTemplate.items.forEach((element) => {
        if (element.type === 'item') {
          if (element.options.favorite) {
            this.favoritesSections.push(element.options);
          }
        }
        if (element.type === 'groupList' || element.type === 'acordionList') {
          element.options.items.forEach((item) => {
            if (item.favorite) {
              this.favoritesSections.push(item);
            }
          });
        }
      });
      this.favoriteKey += 1;
    },
    favoriteProcessor(elementId, itemId, currentState) {
      /*
      console.log(elementId, 'elementId');
      console.log(itemId, 'itemId');
      console.log(currentState, 'State');
      */
      if (itemId === null) {
        // console.log('elemento');
        const index = this.parsedTemplate.items
          .findIndex((element) => element.options.id === elementId);
        this.parsedTemplate.items[index].options.favorite = !currentState;
        // console.log(this.parsedTemplate);
      } else {
        // console.log('grupo o lista');
      }
      // const index = this.list.findIndex(({ id }) => id === itemId);
      // this.list[index].favorite = !currentState;
      this.contructFavoriteGroup();
    },
    navigateTo(path) {
      this.$f7.views.main.router.navigate(path); // ,{reloadAll: true, history: false, ignoreCache: true}
      this.$f7.panel.close();
    },
  },
};
</script>

<style lang="scss">
html.with-panel-left-cover .panel-backdrop, html.with-panel-right-cover .panel-backdrop {
    background: rgba(0, 0, 0, 0.6);
}
html.with-panel-left-cover .panel-backdrop-in, html.with-panel-right-cover .panel-backdrop-in {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}

.main-storybook-sidebar{
  .main-select-list {
    margin: 0;
  }
  .list-container {
    margin: 0;
    padding: 0;
    > ul{
      border-top: 1px solid var(--f7-theme-color-gray-tint);
    }
  }
  .main-list {
    margin: 0;
    .group-title {
      color: gray;
    }
    .optionsSearchbar {
      border: 1px solid rgb(175, 175, 175);
      font-size: small;
      --f7-searchbar-input-font-size: 14px;
      --f7-searchbar-input-padding-horizontal: 28px;
    }
  }
  .content-left-panel-header{
    display: flex;
    align-items: center;
    background-size: 100%;
    margin: 0;
    padding-top: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--his-lightsnow);
    &__img{
      img{
        width: 50px;
        height: 50px;
      }
    }
    &__title{
      padding-left: 15px;
      flex-direction: column;
      display: flex;
      span{
        &:first-child{
          color: var(--ae-blue);
          font-size: 17px;
          font-weight: 500;
        }
        &:nth-child(2){
          color: var(--ae-silver);
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }

  .content-list{
    .media-item{
      > .item-link{
        .item-content{
          .item-media{
            display: flex;
            img{
              width: 32px;
            }
          }
          .item-inner{
            display: flex;
            align-items: center;
            margin-left: 8px;
            .item-title{
              font-size: 16px;
              font-weight: 500;
              color: #003D3E;
            }
          }
        }
      }
    }
  }

  /** Search Bar */
  .searchbar{
    .searchbar-inner{
      background-color: var(--his-lightsnow);
      .searchbar-input-wrap{
        input{
          background-color: #FFFFFF;
          border-radius: 6px;
          font-size: 12px;
        }
      }
    }
  }

  /** SORTED ACCORDION */
  .sorted-accordion{
    > .item-link{
      background-color: var(--his-lightsnow)!important;
      border-top: 0.75px solid var(--his-platinum);
      border-bottom: 0.75px solid var(--his-platinum);
      .item-content{
        .item-inner{
          &::before{
            color: #434E6F;
          }
          .item-title{
            font-size: 16px;
            font-weight: 600;
            color: #2F4859;
            padding-left: 0;
          }
        }
      }
    }
    .accordion-item-content{
      .item-content{
        padding-left: 16px!important;
        .item-inner{
          display: flex;
          align-items: center;
          .item-title{
            color: #003D3E;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .content-item-row:last-child{
        .item-inner{
          border-bottom: 0;
        }
      }
    }
  }

  /** LIST GROUP */
  .list-group{
    .group-title{
      .item-content{
        background-color: var(--his-lightsnow)!important;
        border-top: 0.75px solid var(--his-silver);
        border-bottom: 0.75px solid var(--his-platinum);
        .item-inner{
          .item-title{
            font-size: 16px;
            font-weight: 600;
            color: #2F4859;
            padding-left: 0;
          }
        }
      }
    }
    li{
      &:not(group-title){
        .item-content{
          padding-left: 16px!important;
          border-bottom: 0.75px solid var(--his-platinum);
        }
      }
    }
  }
}
</style>
