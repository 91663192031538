export default class StringUtils {

  /**
   * Adds leading zeros to the format of a given number
   *
   * @param {*} num
   * @param {*} count
   * @returns {String} The number with 'count' leading zeros
   */
  static zeroPad(num, count) {
    return String(num).padStart(count, '0');
  }

  /**
   * Converts a Date into 'YYYY-MM-DD' format.
   *
   * @param {Date} date
   * @return {String}
   */
  static dateToDateString(date) {
    let y = date.getFullYear();
    let m = StringUtils.zeroPad(date.getMonth() + 1, 2);
    let d = StringUtils.zeroPad(date.getDate(), 2);

    return `${y}-${m}-${d}`;
  }

  /**
   *
   * @param {Date} date
   * @returns {String}
   */
  static dateToHourString(date) {
    let h = StringUtils.zeroPad(date.getHours(), 2);
    let m = StringUtils.zeroPad(date.getMinutes(), 2);

    return `${h}:${m}`;
  }

  /**
   *
   * @param {Date} date
   * @param {String} dateTimeSeparator
   *
   * @return {String}
   */
  static dateToFullString(date,dateTimeSeparator = ' ') {
    let dateStr = StringUtils.dateToDateString(date);
    let hourStr = StringUtils.dateToHourString(date);

    return `${dateStr}${dateTimeSeparator}${hourStr}`;
  }
}