<template>
  <f7-list-input
    :ref="reference"
    class="hi-input-date"
    type="datepicker"
    :label="formattedLabel"
    :disabled="readonly"
    :value="currentValue"
    :error-message="`El campo ${formattedLabel} es requerido`"
    :error-message-force="isRequiredDateSelected"
    :calendar-params="calendarParams"
    :clear-button="true"
    @change="emitNewInputValue"
  />
</template>

<script>
// TODO: To be defined in a properties file and imported.
// const NO_LIMIT_MAX_RANGED_DATES = 0; // TODO: Future improvement
// const DEFAULT_MIN_RANGED_DATES = 1; // TODO: Future improvement
export default {
  name: 'HiFormInputDate',
  props: {
    label: {
      type: String,
      required: true,
    },
    reference: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => [
        'date',
        'datetime',
      ].includes(value),
    },
    value: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: String,
      required: true,
      default: 'yyyy/mm/dd',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    ranged: {
      type: Boolean,
      default: false,
    },
    now: {
      type: Boolean,
      default: false,
    },
    // minRangedDates: { // TODO: Future improvement
    //   type: Number,
    //   default: DEFAULT_MIN_RANGED_DATES,
    // },
    // maxRangedDates: { // TODO: Future improvement
    //   type: Number,
    //   default: NO_LIMIT_MAX_RANGED_DATES,
    // },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    formattedLabel() {
      let formattedLabel = this.label;
      if (this.required) {
        formattedLabel = `${formattedLabel}*`;
      }
      return formattedLabel;
    },
    isTypeDate() {
      return this.type === 'date';
    },
    isTypeDatetime() {
      return this.type === 'datetime';
    },
    internalCloseOnSelect() {
      return !this.multiple;
    },
    calendarParams() {
      const calendarParams = {
        openIn: 'customModal',
        closeOnSelect: this.internalCloseOnSelect,
        dateFormat: this.isTypeDatetime ? 'yyyy-mm-dd hh::mm' : 'yyyy-mm-dd',
        timePicker: this.isTypeDatetime,
        routableModals: false
        // value: this.currentValue,
      };
      if (this.multiple) {
        calendarParams.multiple = true;
        return calendarParams;
      }

      if (this.ranged) {
        calendarParams.rangePicker = true;
        // calendarParams.rangePickerMinDays = this.minRangedDates; // TODO: Future improvement
        // calendarParams.rangePickerMaxDays = this.maxRangedDates; // TODO: Future improvement
        return calendarParams;
      }
      return calendarParams;
    },
    isRequiredDateSelected() {
      if (!this.required) return false;
      if (this.currentValue && this.currentValue.length) return false;
      return true;
    },
  },
  methods: {
    checkIfDatesAreTheSame(newValue, oldValue) {
      if (oldValue === null || newValue === null) return false;
      if (newValue.length > 0 && oldValue.length > 0) {
        const d1 = newValue[0];
        const d2 = oldValue[0];
        return (d1.getTime() === d2.getTime());
      }
      return false;
    },
    formatInputValueForEmit() {
      if (this.currentValue && this.currentValue.length > 0) {
        return this.currentValue.map(
          (date) => (this.isTypeDate
            ? this.getISODateOf(date)
            : this.getISODatetimeOf(date)
          ),
        );
      }
      return [];
    },
    emitNewInputValue(newInputValueEvent) {
      if (newInputValueEvent.target.value !== '' && this.$refs[this.reference].f7Calendar) {
        const newCalendarValue = this.$refs[this.reference].f7Calendar.getValue();
        this.currentValue = newCalendarValue;
        this.$emit('new-input-value', this.formatInputValueForEmit(this.currentValue));
        return;
      }
      if (newInputValueEvent.target.value !== '' && this.value && this.value.length > 0) {
        this.$emit('new-input-value', this.formatInputValueForEmit(this.currentValue));
        return;
      }
      this.currentValue = [];
      this.$emit('new-input-value', this.currentValue);
    },
    isDateObject(date) {
      return Object.prototype.toString.call(date) === '[object Date]';
    },
    getISODateOf(date) {
      if (this.isDateObject(date)) {
        const dateWithoutTimezoneOffset = this.getDateWithoutTimezoneOffset(date);
        const year = dateWithoutTimezoneOffset.getFullYear();
        const month = String(dateWithoutTimezoneOffset.getMonth() + 1).padStart(2, '0');
        const day = String(dateWithoutTimezoneOffset.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }
      return '';
    },
    getISODatetimeOf(datetime) {
      if (this.isDateObject(datetime)) {
        const dateWithoutTimezoneOffset = this.getDateWithoutTimezoneOffset(datetime);
        const formattedTimeDate = dateWithoutTimezoneOffset.toISOString();
        const part1 = formattedTimeDate.substring(0, 20);
        const part2 = '000Z';
        const formattedTimeDateUntilSeconds = part1.concat(part2);
        return formattedTimeDateUntilSeconds;
      }
      return '';
    },
    getDateWithoutTimezoneOffset(date) {
      return new Date(date.getTime() - this.calculateTimezoneOffsetOf(date));
    },
    calculateTimezoneOffsetOf(date) {
      return date.getTimezoneOffset();
    },
  },
};
</script>

<style lang="scss" scoped>
$formInputDatePaddingTop: calc(var(--f7-typography-padding) / 2);

.hi-input-date {
  padding-top: $formInputDatePaddingTop;
}

</style>
