/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { create, all } from 'mathjs';

class Evaluator {
  constructor(config) {
    this.config = config || {
      epsilon: 1e-12,
      matrix: 'Matrix',
      number: 'number',
      precision: 64,
      predictable: false,
      randomSeed: null,
    };
    this.math = create(all, config);
    this.parser = this.math.parser();
    this.scope = {};
    this.subTemplatesScope = [];

    this.formulas = {
      sum: (elements, scope) => {
        let result = 0;
        // si es una cadena, vamos a buscar en el data
        if (typeof (elements) === 'string') {
          const split = elements.split('.');
          if (split.length === 2) {
            if (Array.isArray(scope[split[0]])) {
              scope[split[0]].forEach((dataElement) => {
                result += (dataElement[split[1]] !== undefined
                  && !Number.isNaN(dataElement[split[1]]))
                  ? parseFloat(dataElement[split[1]]) : 0;
              });
            } else {
              for (const key in scope[split[0]]) {
                if (Object.hasOwnProperty.call(scope[split[0]], key)) {
                  const element = scope[split[0]][key];
                  result += (element[split[1]] !== undefined && !Number.isNaN(element[split[1]]))
                    ? parseFloat(element[split[1]]) : 0;
                }
              }
            }
          }
        } else if (Array.isArray(elements)) {
          result = elements.reduce((accumulator, currentValue) => accumulator + currentValue);
        }
        if (Number.isNaN(result)) result = 0;
        return result;
      },

      avg: (elements, scope) => {
        let total = 0;
        let totalElements = 0;
        // si es una cadena, vamos a buscar en el data
        if (typeof (elements) === 'string') {
          const split = elements.split('.');
          if (split.length === 2) {
            if (Array.isArray(scope[split[0]])) {
              scope[split[0]].forEach((dataElement) => {
                totalElements += 1;
                total += (dataElement[split[1]] !== undefined
                  && !Number.isNaN(dataElement[split[1]]))
                  ? parseFloat(dataElement[split[1]]) : 0;
              });
            } else {
              for (const key in scope[split[0]]) {
                if (Object.hasOwnProperty.call(scope[split[0]], key)) {
                  const element = scope[split[0]][key];
                  totalElements += 1;
                  total += (element[split[1]] !== undefined && !Number.isNaN(element[split[1]]))
                    ? parseFloat(element[split[1]]) : 0;
                }
              }
            }
          }
        } else if (Array.isArray(elements)) {
          total = elements.reduce((accumulator, currentValue) => accumulator + currentValue);
          totalElements = elements.length;
        }
        const avg = (totalElements > 0) ? total / totalElements : 0;
        return avg;
      },

      compare: (elements, scope, type) => {
        /**
         * compare(show_externalData.data.user ,'SEBAS1','=')
         */
        let r = null;
        if (typeof elements === 'string') {
          if(elements === 'true' || elements === 'false') {
            elements = (elements === 'true')
            r = this.math.compare(elements, scope);
          }
          else{
            try {
              r = this.math.compareText(elements, scope);
            } catch (error) {
              r = this.math.compare(elements, scope);
            }

          }
        } else if(typeof elements === 'object' && Array.isArray(elements)) {
          if(Date.parse(elements[0])>0) {
            r = this.math.compare(Date.parse(elements[0]), scope);
          } else {
            r = this.math.compare(elements, scope);
          }
        } else {
          r = this.math.compare(elements, scope);
        }

        if (type === '=') {
          if (r === 0) return true;
        } else if (type === '>') {
          if (r === 1) return true;
        } else if (type === '<') {
          if (r === -1) return true;
        } else if (type === '!=') {
          if (r === 1 || r === -1) return true;
        }
        return false;
      },

      contains: (elements, scope, property) => {
        /**
         * contains(show_externalData.data.roles,'ADMINISTRADOR','name')
         */
        if (Object.hasOwnProperty.call(elements, '_data')) {
          // eslint-disable-next-line no-underscore-dangle
          const array = elements._data;
          if (property) {
            // Array de objectos (debe llevar property)
            if (array.filter((e) => e[property] === scope).length > 0) {
              return true;
            }
          } else if (array.filter((e) => e === scope).length > 0) {
            // Array simple (property vacio)
            return true;
          }
        } else if (typeof elements === 'object') {
          const array = Object.values(elements);
          if (array.filter((e) => e === scope).length > 0) {
            return true;
          }
        } else if (String(elements) === String(scope)) {
          return true;
        }
        return false;
      },
    };

    this.setUpFormula();
  }

  setUpFormula() {
    this.scope.sum = this.formulas.sum;
    this.scope.avg = this.formulas.avg;
    this.scope.compare = this.formulas.compare;
    this.scope.contains = this.formulas.contains;
  }

  updateData(data, index) {
    this.data = data;
    this.scope.data = data;
    this.scope.index = index;
  }

  updateScope(data) {
    // Object.entries(data).forEach(([key, value]) => {
    //   this.scope[key] = value;
    // });
    for (const [key, value] of Object.entries(data)) {
      this.scope[key] = value;
    }
  }

  evaluate(formula) {
    if (typeof formula !== 'undefined') {
      try {
        // Control formula multiple
        /**
         * compare(show_externalData.data.user,'SEBAS','=')&
         * contains(show_externalData.data.roles,'CALIDAD','name')
         */
        const parsedFormula = formula.replace(/&quot;/g, '"');
        // console.log('=================================================');
        // console.log('parsedFormula ', parsedFormula);

        if (parsedFormula.includes('&&') || parsedFormula.includes('||')) {
          let resultTmp = null;
          let parsedFormulaTmp = parsedFormula;
          const parts = parsedFormula.split(/[&&||]+/);

          parts.forEach((exp) => {
            resultTmp = this.math.evaluate(exp, this.scope);
            parsedFormulaTmp = parsedFormulaTmp.replace(exp, `true==${resultTmp}`);
          });

          parsedFormulaTmp = parsedFormulaTmp.replace('&&', '&');
          parsedFormulaTmp = parsedFormulaTmp.replace('||', '|');

          if (this.math.evaluate(parsedFormulaTmp) === 1) {
            return true;
          }
          return false;
        }
        // Funcionamiento normal
        const result = this.math.evaluate(parsedFormula, this.scope);
        if (typeof result === 'object' && result._data && result._data.length === 1) {
          return result._data[0]
        } else {
          return result
        }
        // console.log('>>> evaluate', parsedFormula, result)
        // return result;
      } catch (error) {
        // console.error('ERROR', error)
        return null;
      }
    }
    return null;
  }

  evaluateInSubTemplate(formula, upperRef, index) {
    if (index >= this.scope.data[upperRef].length) return;
    if(formula) {
      let parsedFormula = formula.replace(/&quot;/g, '"');
      parsedFormula = parsedFormula.replace(new RegExp(`${upperRef}.`, 'g'), `${upperRef}[${index + 1}].`);
      try {
        return this.math.evaluate(parsedFormula, this.scope);
      } catch (error) {
        console.log('ERROR EN FORMULA ', formula)
        //console.error(error);
        return null;
      }
    }
  }
}

export default Evaluator;
