import db from '../services/Database';

export default {
  async getAll() {
    return await db.responseDeletes.toArray();
  },
  async updateById(id, updateObj) {
    return await db.responseDeletes.where('id').equals(id).modify({ sent: 1, idServer: updateObj.idServer });
  },
  async add(data) {
    return await db.responseDeletes.add(data);
  },
  async getPending() {
    return await db.responseDeletes.where('sent').equals(0).toArray();
  },
  async deleteById(id) {
    return await db.responseDeletes.where('id').equals(id).delete();
  }
};
