<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex';
import RefreshData from '../../js/services/RefreshData';

import EventBus from '../../js/EventBus';

export default {
  name: 'EventBusListener',

  mounted () {
    console.log('EventbusListener.... mounted')
    EventBus.$on('responses.insert', this.onNewResponseAdded);
    EventBus.$on('responses.update', this.onNewResponseUpdated);
    EventBus.$on('responses.pending.upload', this.onUploadPendingResponses);

    EventBus.$on('full.reload', this.onFullReload);
    EventBus.$on('error.http', this.checkTokenHttp);
  },
  beforeDestroy() {
    console.log('eventBusListener destroy')
    EventBus.$off('responses.insert');
    EventBus.$off('responses.update');
    EventBus.$off('responses.pending.upload');

    EventBus.$off('full.reload');
  },

  methods: {
    ...mapActions('Responses', [
      'Responses_Insert',
      'Responses_Update',
      'Responses_UploadPending'
    ]),
    async checkTokenHttp(response) {
      if (response.status === 401) {
        this.$f7.popup.close();
        this.$f7.dialog.confirm('Las sesión ha caducado. Pulsa ok para volver a logar. Pulsa cancelar para continuar en modo offline', () => {
          this.$f7.views.main.router.navigate('/login/', {reloadAll: true, history: false});
        });
      }
    },
    async onNewResponseAdded(response) {
      await this.Responses_Insert(response);
    },
    async onNewResponseUpdated({id, response}) {
      await this.Responses_Update({id, response});
    },
    async onUploadPendingResponses() {
      await this.Responses_UploadPending();
    },
    async onFullReload() {
      await this.onUploadPendingResponses();
      await RefreshData.refresh();
    },
  },

}
</script>

<style>

</style>